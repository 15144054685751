import ChangePassword from 'components/authentication/ChangePassword';
import { useNavigate } from 'react-router';
import { useAuthState, useHttpApi } from 'state';

const ChangePasswordPage = () => {
  //   const { user } = useAuthState();

  return (
    <div
      id="kt_body"
      className="app-blank bgi-size-cover bgi-attachment-fixed bgi-position-center bgi-no-repeat"
    >
      <div className="d-flex flex-column flex-root" id="kt_app_root">
        {/* <div className="d-flex flex-column flex-column-fluid flex-lg-row"> */}
        <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
          <ChangePassword />
          {/* {user?.entity && !user?.entity?.is_approved && (
              <h1 className="text-center">
                Your request for Entity creation has been received. <br />
                You will receive a notification mail once the request is processed!
              </h1>
            )} */}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ChangePasswordPage;
