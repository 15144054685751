import FundingHistoryTable from 'components/fundingTables/FundingHistoryTable';
import FundingRequestsTable from 'components/fundingTables/FundingRequestsTable';
import React, { useEffect } from 'react';
import { useAppState, useAuthState } from 'state';

const TraderHistory = () => {
  const { user } = useAuthState();
  const { fetchAllTradersForEntity } = useAppState();

  useEffect(() => {
    if (user) {
      fetchAllTradersForEntity(user?.entity.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="kt_app_content" className="app-content flex-column-fluid pt-5 mt-5">
      <div id="kt_app_content_container" className="app-container container-fluid">
        <FundingHistoryTable
          role={user?.user?.role?.title === 'PRIMARY_TRADER' ? 'trader' : 'liquidity_proivder'}
        />
      </div>
    </div>
  );
};

export default TraderHistory;
