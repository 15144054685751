import AddMatchingRule from 'components/liquidityProvider/AddMatchingRule';
import React from 'react';

const LiquidityMatchingRules = () => {
  return (
    <div>
      <AddMatchingRule />
    </div>
  );
};

export default LiquidityMatchingRules;
