import { statusLabel } from 'helpers/common';
import { enqueueSnackbar } from 'notistack';
import resourcePersistence from 'persistence/resourcePersistence';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppState, useHttpApi } from 'state';
import { useTradeState } from 'state/useTradeState';
import { FundingRequestList } from 'types/tradeTypes';

const FundingRequestsTable = ({ role }: any) => {
  const { fundingRequests, fetchFundingRequests, setFundingRequestID } = useTradeState();
  const { deleteFundingRequest } = useHttpApi();
  const { pathname } = useLocation();
  const { throwErrorMessage } = useAppState();
  useEffect(() => {
    fetchFundingRequests();
  }, []);

  const { allTradersForEntity } = useAppState();

  return (
    <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
      {/* <!--begin::Col--> */}
      <div className="col-xxl-12">
        {/* <!--begin::Card widget 18--> */}
        <div className="card card-flush h-xl-100">
          {/* <!--begin::Header--> */}
          <div className="card-header pt-5">
            {/* <!--begin::Title--> */}
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-dark">Funding Request</span>
            </h3>
            {/* <!--end::Title--> */}
            {role === 'trader' && (
              <div className="card-toolbar">
                {/* {allTradersForEntity?.length === 0 ? (
                  <button type="button" className="btn btn-sm btn-danger">
                    No importers available for new funding requests.
                  </button>
                ) : (
                  <Link to="create-funding-request">
                    <button type="button" className="btn btn-sm btn-primary">
                      + New
                    </button>
                  </Link>
                )} */}
                <Link to="create-funding-request">
                  <button type="button" className="btn btn-sm btn-primary">
                    + New
                  </button>
                </Link>
              </div>
            )}
          </div>
          {/* <!--end::Header--> */}
          {/* <!--begin::Body--> */}
          <div className="card-body pt-6">
            {/* <!--begin::Table container--> */}
            <div className="table-responsive">
              {/* <!--begin::Table--> */}
              <table className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer">
                {/* <!--begin::Table head--> */}
                <thead>
                  <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                    <th className="min-w-150px text-start">Created By</th>
                    <th className="min-w-100px">Invoice No.</th>
                    <th className="min-w-100px">Commodity</th>
                    <th className="min-w-100px">ROI (%PA)</th>
                    <th className="min-w-100px">Value</th>
                    <th className="min-w-100px">Tenure (Days)</th>
                    <th className="min-w-100px">Status</th>
                    <th className="min-w-100px">Action</th>
                  </tr>
                </thead>
                {/* <!--end::Table head--> */}
                {/* <!--begin::Table body--> */}
                <tbody>
                  {fundingRequests?.map((fundingRequest: FundingRequestList, index: number) => {
                    console.log(fundingRequest);
                    return (
                      <tr key={fundingRequest.id}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span className="text-gray-800 fw-bold mb-1 fs-6">
                                {fundingRequest.trade?.exporter?.name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>{fundingRequest?.invoice_number}</td>
                        <td>
                          {fundingRequest.trade.commodity ? fundingRequest.trade.commodity : 'NA'}
                        </td>
                        <td>{fundingRequest.rate_of_interest}%</td>
                        <td className="pe-12">
                          {`${fundingRequest.amount_to_be_funded} ${fundingRequest.funding_currency.code}`}
                        </td>
                        <td>{fundingRequest.tenure}</td>
                        <td>
                          <span className={`py-3 px-4 fs-7`}>
                            {fundingRequest.status.description}
                          </span>
                        </td>
                        <td
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center'
                          }}
                        >
                          <Link
                            to="view-funding"
                            onClick={() => {
                              setFundingRequestID(fundingRequest.id);
                              resourcePersistence.set('fundingRequestID', fundingRequest.id);
                            }}
                          >
                            <button className="btn btn-primary m-2">View</button>
                            {/* <i className="ki-duotone ki-black-right fs-2 text-gray-500"></i> */}
                          </Link>

                          {pathname === '/trader' &&
                            !fundingRequest?.has_liquidity_provider_approved && (
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={async () => {
                                  try {
                                    const res = await deleteFundingRequest(fundingRequest.id);
                                    console.log(res);
                                    enqueueSnackbar('Funding Request Deleted Successfully', {
                                      variant: 'success'
                                    });
                                  } catch (err) {
                                    throwErrorMessage(err);
                                  }
                                  fetchFundingRequests();
                                }}
                              >
                                Delete
                              </button>
                            )}
                          {/* <i className="ki-duotone ki-black-right fs-2 text-gray-500"></i> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                {/* <!--end::Table body--> */}
              </table>
            </div>
            {/* <!--end::Table--> */}
          </div>
          {/* <!--end::Body--> */}
        </div>
        {/* <!--end::Card widget 18--> */}
      </div>
      {/* <!--end::Col--> */}
    </div>
  );
};

export default FundingRequestsTable;
