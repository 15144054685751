/**
 * Renders a page displaying a table of all users with their details.
 * @returns {React.Element} - The rendered component.
 */

import AllUsersTable from 'components/admin/AllUsersTable';
import { useEffect } from 'react';
import { useAppState } from 'state';

const AllUsers = () => {
  const { fetchAllApprovedEntitiesUsers, allApprovedEntitiesUsers } = useAppState();
  useEffect(() => {
    fetchAllApprovedEntitiesUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div id="kt_app_content" className="app-content flex-column-fluid pt-5 mt-5">
      <div id="kt_app_content_container" className="app-container container-fluid">
        <AllUsersTable data={allApprovedEntitiesUsers} />
      </div>
    </div>
  );
};

export default AllUsers;
