/**
 * Renders a page displaying a table of pending user requests with their details and action to Aprrove or Reject
 * @param {object} data - The data array containing pending user request information.
 * @returns {React.Element} - The rendered component.
 */

import { enqueueSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { useAppState, useHttpApi } from 'state';
import { EntityUserProps } from 'types/entity';

interface PendingUserRequestsProps {
  data: EntityUserProps[];
}

const PendingUserRequests = ({ data }: PendingUserRequestsProps) => {
  const { approveEntityUser, rejectEntityUser } = useHttpApi();
  const {
    fetchAllPendingEntitiesUsers,
    fetchAllApprovedEntitiesUsers,
    setCurrentEntityUserId,
    throwErrorMessage
  } = useAppState();

  return (
    <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
      <div className="col-xxl-12">
        <div className="card card-flush h-xl-100">
          <div className="card-header pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-dark">Pending User Requests</span>
            </h3>
          </div>

          <div className="card-body pt-6">
            <div className="table-responsive">
              <table className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer">
                <thead>
                  <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                    <th className="min-w-250px text-start">Company Name</th>
                    <th className="min-w-250px">Role Requested</th>
                    <th className="min-w-250px text-start">User Name</th>
                    <th className="min-w-250px">Email ID</th>
                    <th className="min-w-100px text-end">ACTION</th>
                  </tr>
                </thead>

                <tbody>
                  {data.map((item: EntityUserProps, index: number) => {
                    return (
                      <tr key={item.id ? item.id : index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span className="text-gray-800 fw-bold mb-1 fs-6">
                                {item?.entity?.name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>{item.entity?.entity_type.description}</td>
                        <td>{item.user?.name}</td>
                        <td>{item.user?.email}</td>

                        <td className="text-end">
                          <button
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px dropdown-toggle"
                          >
                            <i className="bi bi-list fs-2 "></i>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-lg-end">
                            <li>
                              <Link
                                className="btn btn-light"
                                style={{ width: '100%', textAlign: 'left' }}
                                to="/admin/view-user-profile"
                                onClick={() => setCurrentEntityUserId(item?.id)}
                              >
                                View User's Profile
                              </Link>

                              <button
                                type="button"
                                className="btn btn-light"
                                style={{ width: '100%', textAlign: 'left' }}
                                onClick={async () => {
                                  try {
                                    const res = await approveEntityUser(item.id);
                                    if (res) {
                                      fetchAllPendingEntitiesUsers();
                                      fetchAllApprovedEntitiesUsers();
                                      enqueueSnackbar('Entity Request Accepted', {
                                        variant: 'success'
                                      });
                                    }
                                  } catch (error) {
                                    throwErrorMessage(error);
                                  }
                                }}
                              >
                                Accept
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className="btn btn-light"
                                style={{ width: '100%', textAlign: 'left' }}
                                onClick={async () => {
                                  try {
                                    const res = await rejectEntityUser(item.id);
                                    if (res) {
                                      fetchAllPendingEntitiesUsers();
                                      enqueueSnackbar('Entity Request Rejected', {
                                        variant: 'success'
                                      });
                                    }
                                  } catch (error) {
                                    throwErrorMessage(error);
                                  }
                                }}
                              >
                                Reject
                              </button>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingUserRequests;
