import { toChecksumAddress } from 'helpers/web3';
import { useAuthState, useAuthStateShared, useWeb3State } from 'state';

type Props = {
  children: JSX.Element;
  not?: boolean;
};
const UserWalletRequire = ({ children, not }: Props) => {
  const { account, active } = useWeb3State();
  const { user } = useAuthStateShared();
  const role = user?.user?.role?.title;
  const xinfinAddress = user !== null ? user?.user?.wallet?.address : null;
  console.log('UserWalletRequire', xinfinAddress, account, active);
  const condition =
    // eslint-disable-next-line no-nested-ternary
    role !== 'ADMIN'
      ? xinfinAddress === null
        ? false
        : !active || !account || toChecksumAddress(account) === xinfinAddress
          ? toChecksumAddress(xinfinAddress)
          : false
      : true;
  const show = not ? !condition : condition;

  return show ? children : null;
};

export default UserWalletRequire;
