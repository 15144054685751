type Props = {
  children: JSX.Element;
  show: boolean;
};

const Overlay = ({ children, show }: Props) =>
  show ? (
    <div
      style={{
        zIndex: 2
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: '#333',
          opacity: 0.7
        }}
      />
      <div
        style={{
          position: 'relative',
          width: '50%',
          display: 'flex',
          justifyContent: 'center',
          margin: 'auto',
          height: '100%'
        }}
      >
        {children}
      </div>
    </div>
  ) : null;

export default Overlay;
