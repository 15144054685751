import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useAppState, useAuthState, useCoreTableState, useHttpApi, useWeb3State } from 'state';
import { EntityProps } from 'types/entity';
import { Commodity, Country, Currency } from 'types/globalTypes';
import { CurrenciesProps } from 'types/coreTablesTypes';
import FocusError from 'components/FocusError';
import StepHeader from 'components/swap/StepHeader';
import { useEffect, useRef } from 'react';
import { WrappedDocument } from '@govtechsg/open-attestation/dist/types/2.0/types';
import { getData } from '@govtechsg/open-attestation';
import { rawDocument } from 'types/global';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useState } from 'react';

const FundingRequestForm = () => {
  const { user } = useAuthState();
  const { enqueueSnackbar } = useSnackbar();
  const { countries, currencies, commodities } = useCoreTableState();
  const { getTitleEscrow, throwErrorMessage } = useAppState();
  const { signer } = useWeb3State();
  const { createTradeFundingRequest, checkBlExits, checkInvoiceExists } = useHttpApi();
  const { allTradersForEntity, tradeName, fetchTradeName, fetchAllTradersForEntity } =
    useAppState();
  const navigate = useNavigate();
  const bill_of_lading_ref = useRef<HTMLInputElement>(null);
  const invoice_ref = useRef<HTMLInputElement>(null);
  const certificate_of_origin_ref = useRef<HTMLInputElement>(null);
  const trade_supporting_document_ref = useRef<HTMLInputElement>(null);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState('promNote');
  const [invoicefileINVNumber, setInvoicefileINVNumber] = useState<string>('');
  const [coofileINVNumber, setCoofileINVNumber] = useState<string>('');
  const [tsdfileINVNumber, setTsdfileINVNumber] = useState<string>('');
  const [BLUploading, setBLUploading] = useState<boolean>(false);
  const [invoiceUploading, setInvoiceUploading] = useState<boolean>(false);
  const [cooUploading, setCooUploading] = useState<boolean>(false);
  const [tsdUploading, setTsdUploading] = useState<boolean>(false);

  const getISOCodeByCountryName = (countryName: string) => {
    const matchedCountry = countries.find((country: Country) => country.name === countryName);
    return matchedCountry?.iso_code || '';
  };

  const getImporterID = (entityName: string) => {
    const matchedId = allTradersForEntity.find((entity: EntityProps) => entity.name === entityName);
    return matchedId?.id || '';
  };

  const getCommodityCodeByCommodityName = (commodityName: string) => {
    const matchedCommodity = commodities.find(
      (commodity: Commodity) => commodity.name === commodityName
    );
    return matchedCommodity?.hs_code || '';
  };

  const getCurrencyByName = (currencycode: string) => {
    const matchedId = currencies.find((currency: Currency) => currency.code === currencycode);
    return matchedId?.code || '';
  };

  const FundingRequestSchema = Yup.object().shape({
    exporter: Yup.string(),
    importer: Yup.string(),
    beneficiary: Yup.string().required('Beneficiary is required'),
    rate_of_interest: Yup.number()
      .min(0, 'Negative values are not allowed.')
      .max(100, 'Maximum interest rate should be 100')
      .required('Rate of Interest is required'),
    amount_to_be_funded: Yup.number()
      .required('Amount to be funded is required')
      .positive('Amount to be funded should be greater than 0'),
    funding_currency: Yup.string().required('Funding Currency is required'),
    bill_of_lading: Yup.mixed().required('Title Document is required'),
    settlement_leg: Yup.string().required('Select a settlement leg'),
    declaration: Yup.string().required('Declaration is required'),

    invoice_number: Yup.string().required('Invoice Number is required'),
    ...(selectedOption !== 'promNote' && {
      amount: Yup.number().min(1, 'Amount should be greater than 0').required('Amount is required'),
      from_country: Yup.string().required('Country From is required'),
      to_country: Yup.string()
        .required('Country To is required')
        .notOneOf([Yup.ref('from_country')], 'Country of export and import cannot be same'),
      currency: Yup.string().required('Currency is required'),
      commodity: Yup.string().required('Commodity is required'),
      description_of_goods: Yup.string()
        .trim('The description cannot include leading and trailing spaces')
        .required('Description of Goods is required'),
      date_of_shipment: Yup.date().required('Date of shipment is required'),
      payment_terms: Yup.number()
        .min(1, 'Payment Terms should be greater than 0')
        .max(9999, 'The value you entered is not allowed')
        .required('Payment Terms is required'),
      tenure: Yup.number()
        .min(1, 'Tenure should not be less than 1')
        .max(Yup.ref('payment_terms'), 'Tenure should be less than or equal the payment terms')
        .required('Tenure is required')
        .positive('Tenure should be greater than 0')
    })
  });

  const defaultTradeValues = {
    trade_name: tradeName,
    exporter: user?.entity?.id,
    importer: '',
    commodity: '',
    beneficiary: '',
    currency: '',
    description_of_goods: '',
    from_country: '',
    to_country: '',
    amount: '',
    payment_terms: '',
    date_of_shipment: '',
    tenure: '',
    rate_of_interest: '',
    amount_to_be_funded: '',
    title_escrow_contract: '',
    funding_currency: getCurrencyByName('USD'),
    invoice_number: '',
    bill_of_lading: null,

    invoice: null,
    certificate_of_origin: null,
    trade_supporting_document: null,
    bl_exporter: '',
    settlement_leg: '',
    declaration: 'I agree by the terms and conditions for the said transaction.'
  };

  const formik = useFormik({
    initialValues: defaultTradeValues,
    validationSchema: FundingRequestSchema,
    validate: (values) => {
      const errors: any = {};
      // Rate of Interest should allow only 2 decimal places after the dot
      if (values.rate_of_interest.toString().split('.')[1]?.length > 2) {
        errors.rate_of_interest = 'Only 2 decimal places are allowed';
      }
      return errors;
    },
    onSubmit: async (data, { resetForm }) => {
      console.log('???>');
      setSubmitting(true);
      const formData = new FormData();

      if (
        bill_of_lading_ref.current &&
        bill_of_lading_ref.current.files &&
        bill_of_lading_ref.current.files.length > 0
      ) {
        formData.append('bill_of_lading', bill_of_lading_ref.current.files[0]);
      }
      if (
        invoice_ref.current &&
        invoice_ref.current.files &&
        invoice_ref.current.files.length > 0
      ) {
        formData.append('invoice', invoice_ref.current.files[0]);
        formData.append('invoice_number', data.invoice_number);
      }
      if (
        certificate_of_origin_ref.current &&
        certificate_of_origin_ref.current.files &&
        certificate_of_origin_ref.current.files.length > 0
      ) {
        formData.append('certificate_of_origin', certificate_of_origin_ref.current.files[0]);
      }
      if (
        trade_supporting_document_ref.current &&
        trade_supporting_document_ref.current.files &&
        trade_supporting_document_ref.current.files.length > 0
      ) {
        formData.append(
          'trade_supporting_document',
          trade_supporting_document_ref.current.files[0]
        );
      }
      Object.entries(data).forEach(([key, value]) => {
        if (
          key !== 'bill_of_lading' &&
          key !== 'invoice' &&
          key !== 'certificate_of_origin' &&
          key !== 'trade_supporting_document' &&
          value
        ) {
          formData.append(key, value.toString());
        }
      });

      if (tradeName) {
        formData.append('importer', 'importer');
        formData.append('trade_name', tradeName);
      }

      try {
        const res = await createTradeFundingRequest(formData);
        setSubmitting(false);
        enqueueSnackbar('Created Funding Request.', { variant: 'success' });
        console.log(res);
        resetForm();
        navigate('/trader');
      } catch (error) {
        console.log(error);
        setSubmitting(false);
        throwErrorMessage(error);
      }
    }
  });

  const handleBLFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setBLUploading(true);
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async (event: ProgressEvent<FileReader>) => {
        if (!event.target?.result) return;

        try {
          const fileContent = event.target.result as string;
          const parsedDocument: WrappedDocument = JSON.parse(fileContent);
          const rawDocument_: rawDocument = getData(parsedDocument);
          console.log('🚀 ~ reader.onload= ~ rawDocument_:', rawDocument_);
          if (!signer) throw new Error('Wallet connect required');

          const [titleEscrowAddress_, isSurrenderedOrBurnt, isOwner, isHolder] =
            await getTitleEscrow({
              rawDocument: rawDocument_,
              wrappedDocument: parsedDocument,
              signer: signer
            });

          if (!titleEscrowAddress_)
            throw new Error('Title Document Invalid: Title Escrow Address not found');
          if (isSurrenderedOrBurnt)
            throw new Error('Funding request failed: Title Document surrendered.');
          if (!isOwner)
            throw new Error(
              'Funding request failed: Connected Wallet is not Title Document owner.'
            );
          if (!isHolder)
            throw new Error(
              'Funding request failed: Connected Wallet is not Title Document holder.'
            );
          const res = await checkBlExits(titleEscrowAddress_);
          console.log(rawDocument_);
          if (res.response === 'BL already exists.') {
            enqueueSnackbar(
              // res.response,
              'Title Document already exists. Please upload a different document.',
              { variant: 'error' }
            );
            if (bill_of_lading_ref.current) bill_of_lading_ref.current.value = '';
            setBLUploading(false);
          } else {
            console.log('check title Escrow', titleEscrowAddress_);
            // formik.setValues({
            //   ...formik.values,

            //   // description_of_goods: rawDocument_?.packages[0].description,
            //   // payment_terms: rawDocument_?.paymentTerms,
            //   date_of_shipment: rawDocument_?.shippedOnBoardDate,
            //   from_country: getISOCodeByCountryName(rawDocument_?.countryOfOrigin),
            //   to_country: getISOCodeByCountryName(rawDocument_?.countryOfDestination),
            //   // commodity: getCommodityCodeByCommodityName(rawDocument_?.properShippingName),
            //   importer: rawDocument_?.importer.name,
            //   bl_exporter: rawDocument_?.exporter.name,
            //   title_escrow_contract: titleEscrowAddress_
            //   // tenure: rawDocument_?.paymentTerms
            // });
            formik.setFieldValue('title_escrow_contract', titleEscrowAddress_);
            formik.setFieldValue('bill_of_lading', e.target.files);
            setBLUploading(false);
          }
          setBLUploading(false);
        } catch (err) {
          throwErrorMessage(err);
          if (bill_of_lading_ref.current) bill_of_lading_ref.current.value = '';
          setBLUploading(false);
        }
      };

      reader.readAsText(file);
    }
  };

  const handleInvoiceFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setInvoiceUploading(true);
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async (event: ProgressEvent<FileReader>) => {
        if (event.target?.result) {
          const fileContent = event.target.result as string;
          try {
            const parsedDocument: WrappedDocument = JSON.parse(fileContent);
            const rawDocument_: rawDocument = getData(parsedDocument);
            console.log('🚀 ~ reader.onload= ~ rawDocument_:', rawDocument_);
            if (!rawDocument_) {
              throw new Error('Invoice Document is not Valid');
            }

            /**
             * !Removed All API checks to support other Document Types under TradeTrust Framework
             */

            // const invoiceNumber = rawDocument_.invoiceNumber;

            // const res = await checkInvoiceExists(encodeURIComponent(invoiceNumber));
            // const res = { response: '' }; // TODO: remove this line and uncomment the above line to enable the API checks

            // if (res.response === 'Invoice already exists.') {
            //   enqueueSnackbar(res.response, { variant: 'error' });
            //   if (invoice_ref.current) invoice_ref.current.value = '';
            //   setInvoiceUploading(false);
            // } else {
            //   setInvoicefileINVNumber(invoiceNumber);
            //   if (coofileINVNumber !== '' && coofileINVNumber !== invoiceNumber) {
            //     console.log(coofileINVNumber);
            //     if (coofileINVNumber) setCoofileINVNumber('');
            //     if (certificate_of_origin_ref.current) certificate_of_origin_ref.current.value = '';
            //     enqueueSnackbar('Incorrect COO: Invoice Number does match with Invoice', {
            //       variant: 'info'
            //     });
            //     setInvoiceUploading(false);
            //   }
            // formik.setValues({
            //   ...formik.values,
            //   currency: getCurrencyByName(rawDocument_?.currency),
            //   amount: (
            //     rawDocument_.commodity.reduce(
            //       (acc: number, commodity: any) => acc + commodity.unitPrice * commodity.qty,
            //       0
            //     ) +
            //     rawDocument_?.miscCharges -
            //     rawDocument_?.creditAmount
            //   ).toString(),
            //   // TODO: TO BE REMOVED POST PILOT
            //   // amount_to_be_funded: (
            //   //   rawDocument_.commodity.reduce(
            //   //     (acc: number, commodity: any) => acc + commodity.unitPrice * commodity.qty,
            //   //     0
            //   //   ) +
            //   //   rawDocument_?.miscCharges -
            //   //   rawDocument_?.creditAmount
            //   // ).toString(),
            //   // funding_currency: getCurrencyByName(rawDocument_?.currency),
            //   invoice_number: invoiceNumber,
            //   description_of_goods: rawDocument_.commodity[0].description,
            //   commodity: rawDocument_.commodity[0].hsCode,
            //   payment_terms: rawDocument_?.paymentTerms
            // });
            //   formik.setFieldValue('invoice', e.target.files);
            //   setInvoiceUploading(false);
            // }
            formik.setFieldValue('invoice', e.target.files);
            setInvoiceUploading(false);
          } catch (err) {
            enqueueSnackbar('Document is not Valid', {
              variant: 'error'
            });
            if (invoicefileINVNumber) setInvoicefileINVNumber('');
            if (invoice_ref.current) invoice_ref.current.value = '';
            setInvoiceUploading(false);
          }
        }
      };
      reader.readAsText(file);
    }
  };

  const handleCOOFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setCooUploading(true);
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async (event: ProgressEvent<FileReader>) => {
        if (event.target?.result) {
          const fileContent = event.target.result as string;
          try {
            /**
             * !Removed All checks & validation to support other Document Types under TradeTrust Framework
             */

            // if (!formik.values.invoice) {
            //   throw new Error('Please Upload Invoice file');
            // }

            const parsedDocument: WrappedDocument = JSON.parse(fileContent);
            const rawDocument_: rawDocument = getData(parsedDocument);
            console.log('🚀 ~ reader.onload= ~ rawDocument_:', rawDocument_);

            if (!rawDocument_) {
              throw new Error('COO Document is not Valid');
            }

            /**
             * !Removed All checks & validation to support other Document Types under TradeTrust Framework
             */

            // const invoiceNumber = rawDocument_.invoiceNumber;
            // setCoofileINVNumber(invoiceNumber);

            // if (!rawDocument_?.cooCertificateNumber) {
            //   throw new Error('Document is not Valid');
            // }

            // if (invoicefileINVNumber !== '' && invoicefileINVNumber !== invoiceNumber) {
            //   throw new Error('Incorrect COO: Invoice Number doesn\'t match with Invoice ');
            // }

            formik.setFieldValue('certificate_of_origin', e.target.files);
            setCooUploading(false);
          } catch (err) {
            throwErrorMessage(err);
            if (coofileINVNumber) setCoofileINVNumber('');
            if (certificate_of_origin_ref.current) certificate_of_origin_ref.current.value = '';
            setCooUploading(false);
          }
        }
      };
      reader.readAsText(file);
    }
  };

  const handleTSDFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setTsdUploading(true);
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async (event: ProgressEvent<FileReader>) => {
        if (event.target?.result) {
          const fileContent = event.target.result as string;
          try {
            /**
             * !Removed All checks & validation to support other Document Types under TradeTrust Framework
             */

            // if (!formik.values.invoice) {
            //   throw new Error('Please Upload Invoice file');
            // }

            const parsedDocument: WrappedDocument = JSON.parse(fileContent);
            const rawDocument_: rawDocument = getData(parsedDocument);
            console.log('🚀 ~ reader.onload= ~ rawDocument_:', rawDocument_);

            if (!rawDocument_) {
              throw new Error('COO Document is not Valid');
            }

            /**
             * !Removed All checks & validation to support other Document Types under TradeTrust Framework
             */

            // const invoiceNumber = rawDocument_.invoiceNumber;
            // setCoofileINVNumber(invoiceNumber);

            // if (!rawDocument_?.cooCertificateNumber) {
            //   throw new Error('Document is not Valid');
            // }

            // if (invoicefileINVNumber !== '' && invoicefileINVNumber !== invoiceNumber) {
            //   throw new Error('Incorrect COO: Invoice Number doesn\'t match with Invoice ');
            // }

            formik.setFieldValue('trade_supporting_document', e.target.files);
            setTsdUploading(false);
          } catch (err) {
            throwErrorMessage(err);
            if (tsdfileINVNumber) setTsdfileINVNumber('');
            if (trade_supporting_document_ref.current)
              trade_supporting_document_ref.current.value = '';
            setTsdUploading(false);
          }
        }
      };
      reader.readAsText(file);
    }
  };

  useEffect(() => {
    fetchTradeName();
    if (user) {
      fetchAllTradersForEntity(user?.entity.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getFieldProps, handleSubmit } = formik;

  return (
    <>
      <div className="pt-10 pb-20 bg-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-3 text-center">
              <h1
                className=" text-dark mb-5"
                id="team"
                data-kt-scroll-offset="{default: 100, lg: 150}"
              >
                Create Funding Request
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div
                className="card"
                style={{
                  filter: 'drop-shadow(0px 0px 40px rgba(68, 81, 96, 0.08))'
                }}
              >
                <div className="card-body p-lg-20">
                  <div className="stepper stepper-pills" id="kt_stepper_example_clickable">
                    <FormikProvider value={formik}>
                      <Form className="form text-start" onSubmit={handleSubmit}>
                        <StepHeader
                          stepNumber={1}
                          title={`Trade Details - ${tradeName}`}
                          titleDescription=""
                          isActive={true}
                        />

                        <div className="row mx-2 ">
                          <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                              <label className="fs-5 fw-semibold required mb-2">
                                Title Document Type
                              </label>
                              <select
                                className="form-select form-select-solid"
                                value={selectedOption}
                                onChange={(e) => setSelectedOption(e.target.value)}
                              >
                                <option value="promNote">Promissory Note</option>
                                <option value="billOfLading">Bill of Lading</option>
                                <option value="billOfExchange">Bill of Exchange</option>
                              </select>
                            </div>
                            <div className="col-md-6 fv-row">
                              <label className="fs-5 fw-semibold required mb-2">
                                Upload Title Document
                              </label>
                              <input
                                type="file"
                                accept=".tt"
                                ref={bill_of_lading_ref}
                                className="form-control form-control-solid"
                                onChange={handleBLFileChange}
                              />
                              {BLUploading && (
                                <div className="d-flex align-items-center">
                                  <div className="spinner-border text-primary" role="status"></div>
                                  <p
                                    style={{
                                      fontWeight: 500
                                    }}
                                    className="mb-0 ms-2 text-primary"
                                  >
                                    Please wait while we fetch the details from your document!!!
                                  </p>
                                </div>
                              )}
                              <ErrorMessage
                                name="bill_of_lading"
                                component="div"
                                className="text-danger"
                              />
                            </div>

                            <div className="col-md-6 fv-row">
                              <label className="fs-5 fw-semibold mb-2">Invoice</label>
                              <input
                                type="file"
                                ref={invoice_ref}
                                accept=".tt"
                                className="form-control form-control-solid"
                                onChange={handleInvoiceFileChange}
                              />
                              {invoiceUploading && (
                                <div className="d-flex align-items-center">
                                  <div className="spinner-border text-primary" role="status"></div>
                                  <p
                                    style={{
                                      fontWeight: 500
                                    }}
                                    className="mb-0 ms-2 text-primary"
                                  >
                                    Please wait while we fetch the details from your document!!!
                                  </p>
                                </div>
                              )}
                              <ErrorMessage
                                name="invoice"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="col-md-6 fv-row mt-5">
                              <label className="fs-5 fw-semibold mb-2">
                                Certificate of Origin (COO)
                              </label>
                              <input
                                type="file"
                                className="form-control form-control-solid"
                                ref={certificate_of_origin_ref}
                                accept=".tt"
                                onChange={handleCOOFileChange}
                              />
                              {cooUploading && (
                                <div className="d-flex align-items-center">
                                  <div className="spinner-border text-primary" role="status"></div>
                                  <p
                                    style={{
                                      fontWeight: 500
                                    }}
                                    className="mb-0 ms-2 text-primary"
                                  >
                                    Please wait while we fetch the details from your document!!!
                                  </p>
                                </div>
                              )}
                              <ErrorMessage
                                name="certificate_of_origin"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="col-md-6  fv-row">
                              <label className="fs-5 mt-2  fw-semibold  mb-2">
                                Trade Supporting Document
                              </label>
                              <input
                                type="file"
                                accept=".tt"
                                ref={trade_supporting_document_ref}
                                className="form-control form-control-solid"
                                onChange={handleTSDFileChange}
                              />
                              {tsdUploading && (
                                <div className="d-flex align-items-center">
                                  <div className="spinner-border text-primary" role="status"></div>
                                  <p
                                    style={{
                                      fontWeight: 500
                                    }}
                                    className="mb-0 ms-2 text-primary"
                                  >
                                    Please wait while we fetch the details from your document!!!
                                  </p>
                                </div>
                              )}
                              <ErrorMessage
                                name="trade_supporting_document"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>

                          {/* <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                              <label className="fs-5 fw-semibold required mb-2">Exporter</label>
                              <input
                                // readOnly
                                type="text"
                                className="border-1 form-control form-control-solid"
                                {...getFieldProps('bl_exporter')}
                              />
                            </div>
                            <div className="col-md-6 fv-row" hidden>
                              <label className="fs-5 fw-semibold required mb-2">Exporter</label>
                              <input
                                // readOnly
                                type="text"
                                className="border-1 form-control form-control-solid"
                                value={user?.entity?.name}
                              />
                            </div>

                            <div className="col-md-6 fv-row">
                              <label className="fs-5 fw-semibold required mb-2">Importer</label>
                              <input
                                // readOnly
                                type="text"
                                className="border-1 form-control form-control-solid"
                                {...getFieldProps('importer')}
                              />

                              <ErrorMessage
                                name="importer"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div> */}
                          <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                              <label className="fs-5 fw-semibold required  mb-2">
                                Invoice Number
                              </label>
                              <input
                                // readOnly={
                                //   invoice_ref?.current?.files?.length !== undefined &&
                                //   invoice_ref.current.files?.length > 0
                                // }
                                type="text"
                                className="border-1 form-control form-control-solid"
                                {...getFieldProps('invoice_number')}
                              />

                              <ErrorMessage
                                name="invoice_number"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          {(selectedOption === 'billOfLading' ||
                            selectedOption === 'billOfExchange') && (
                            <>
                              <div className="row mb-5">
                                <div className="col-md-6 fv-row">
                                  <label className="fs-5 fw-semibold required mb-2">
                                    Commodity
                                  </label>
                                  <select
                                    // disabled={
                                    //   invoice_ref?.current?.files?.length !== undefined &&
                                    //   invoice_ref.current.files?.length > 0
                                    // }
                                    className="form-select form-select-solid"
                                    data-placeholder="Select a Commodity"
                                    {...getFieldProps('commodity')}
                                    defaultValue=""
                                  >
                                    <option value="" disabled hidden>
                                      Select a Commodity
                                    </option>
                                    {commodities?.map((commodity: Commodity, index: number) => (
                                      <option
                                        key={commodity.hs_code || index}
                                        value={commodity.hs_code}
                                      >
                                        {commodity.hs_code} - {commodity.name}
                                      </option>
                                    ))}
                                  </select>
                                  <ErrorMessage
                                    name="commodity"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="col-md-6 fv-row">
                                  <label className="fs-5 fw-semibold required mb-2">
                                    Shipment Date
                                  </label>

                                  <input
                                    type="date"
                                    // readOnly
                                    className="form-control form-control-solid"
                                    {...getFieldProps('date_of_shipment')}
                                  />
                                  <ErrorMessage
                                    name="date_of_shipment"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                              <div className="row mb-5">
                                <div className="col-md-6 fv-row">
                                  <label className="fs-5 fw-semibold required mb-2">
                                    Description of goods
                                  </label>

                                  <textarea
                                    // readOnly={
                                    //   invoice_ref?.current?.files?.length !== undefined &&
                                    //   invoice_ref.current.files?.length > 0
                                    // }
                                    className="form-control form-control-solid"
                                    {...getFieldProps('description_of_goods')}
                                  ></textarea>
                                  <ErrorMessage
                                    name="description_of_goods"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="col-md-6 fv-row">
                                  <label className="fs-5 fw-semibold required mb-2">
                                    Payment terms (days)
                                  </label>

                                  <input
                                    type="text"
                                    // readOnly={
                                    //   invoice_ref?.current?.files?.length !== undefined &&
                                    //   invoice_ref.current.files?.length > 0
                                    // }
                                    onWheel={(e) => e.currentTarget.blur()}
                                    className="form-control form-control-solid"
                                    {...getFieldProps('payment_terms')}
                                  />
                                  <ErrorMessage
                                    name="payment_terms"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                              <div className="row mb-5">
                                <div className="col-md-6 fv-row">
                                  <label className="fs-5 fw-semibold required mb-2">
                                    Country From
                                  </label>

                                  <select
                                    // disabled
                                    className="form-select form-select-solid"
                                    data-placeholder="Select an option"
                                    {...getFieldProps('from_country')}
                                    defaultValue=""
                                  >
                                    <option value="" disabled hidden>
                                      Select a country
                                    </option>
                                    {countries?.map((country: Country, index: number) => (
                                      <option
                                        key={country.iso_code || index}
                                        value={country.iso_code}
                                      >
                                        {country.iso_code} - {country.name}
                                      </option>
                                    ))}
                                  </select>
                                  <ErrorMessage
                                    name="from_country"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="col-md-6 fv-row">
                                  <label className="fs-5 fw-semibold required mb-2">
                                    Country To
                                  </label>

                                  <select
                                    // disabled
                                    className="form-select form-select-solid"
                                    data-placeholder="Select an option"
                                    {...getFieldProps('to_country')}
                                    defaultValue=""
                                  >
                                    <option value="" disabled hidden>
                                      Select a country
                                    </option>
                                    {countries?.map((country: Country, index: number) => (
                                      <option
                                        key={country.iso_code || index}
                                        value={country.iso_code}
                                      >
                                        {country.iso_code} - {country.name}
                                      </option>
                                    ))}
                                  </select>
                                  <ErrorMessage
                                    name="to_country"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                              <div className="row mb-5">
                                <div className="col-md-6 fv-row">
                                  <label className="fs-5 fw-semibold required mb-2">Currency</label>

                                  <select
                                    // disabled={
                                    //   invoice_ref?.current?.files?.length !== undefined &&
                                    //   invoice_ref.current.files?.length > 0
                                    // }
                                    className="form-select form-select-solid"
                                    data-placeholder="Select an option"
                                    {...getFieldProps('currency')}
                                    defaultValue=""
                                  >
                                    <option value="" disabled hidden>
                                      Select a currency
                                    </option>
                                    {currencies?.map((currency: CurrenciesProps, index: number) => (
                                      <option key={currency?.code || index} value={currency?.code}>
                                        {currency?.code} - {currency?.name}
                                      </option>
                                    ))}
                                  </select>
                                  <ErrorMessage
                                    name="currency"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>

                                <div className="col-md-6 fv-row">
                                  <label className="fs-5 fw-semibold required mb-2">Amount</label>

                                  <input
                                    // readOnly={
                                    //   invoice_ref?.current?.files?.length !== undefined &&
                                    //   invoice_ref.current.files?.length > 0
                                    // }
                                    type="text"
                                    className="form-control form-control-solid"
                                    {...getFieldProps('amount')}
                                  />
                                  <ErrorMessage
                                    name="amount"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <StepHeader
                          stepNumber={2}
                          title="Funding Request Details"
                          titleDescription=""
                          isActive={true}
                        />
                        <div className="row mx-2 ">
                          <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                              <label className="fs-5 fw-semibold required mb-2">Beneficiary</label>
                              <select
                                className="form-select form-select-solid"
                                data-placeholder="Select a Beneficiary"
                                {...getFieldProps('beneficiary')}
                                defaultValue=""
                              >
                                <option value="" disabled hidden>
                                  Select a Beneficiary
                                </option>
                                {allTradersForEntity?.map((entity: EntityProps, index: number) => (
                                  <option key={entity.id || index} value={entity.id}>
                                    {entity.name}
                                  </option>
                                ))}
                              </select>
                              <ErrorMessage
                                name="beneficiary"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="col-md-6 fv-row">
                              <label className="fs-5 fw-semibold required mb-2">
                                Settlement Leg
                              </label>
                              <select
                                className="form-select form-select-solid"
                                data-placeholder="Select a settlement_leg"
                                {...getFieldProps('settlement_leg')}
                                defaultValue=""
                              >
                                <option value="" disabled hidden>
                                  Select a settlement leg
                                </option>
                                <option value="STABLE_COIN">Stable Coin</option>
                                <option value="CASH">Cash</option>
                              </select>
                              <ErrorMessage
                                name="settlement_leg"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                              <label className="fs-5 fw-semibold required mb-2">
                                Amount to be funded
                              </label>
                              <input
                                type="number"
                                min={1}
                                className="form-control form-control-solid"
                                {...getFieldProps('amount_to_be_funded')}
                              />
                              <ErrorMessage
                                name="amount_to_be_funded"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="col-md-6 fv-row">
                              <label className="fs-5 fw-semibold required mb-2">Currency</label>

                              <select
                                className="form-select form-select-solid"
                                data-placeholder="Select an option"
                                {...getFieldProps('funding_currency')}
                                defaultValue=""
                                // disabled
                              >
                                <option value="" disabled hidden>
                                  Select a currency
                                </option>
                                {currencies?.map((currency: CurrenciesProps, index: number) => (
                                  <option key={currency?.code || index} value={currency?.code}>
                                    {currency?.code} {currency?.name}
                                  </option>
                                ))}
                              </select>
                              <ErrorMessage
                                name="funding_currency"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                              <label className="fs-5 fw-semibold required mb-2">ROI (%PA)</label>
                              <input
                                type="number"
                                className="form-control form-control-solid"
                                {...getFieldProps('rate_of_interest')}
                              />
                              <ErrorMessage
                                name="rate_of_interest"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="col-md-6 fv-row">
                              <label className="fs-5 fw-semibold required mb-2">
                                Tenure (days)
                              </label>
                              <input
                                onWheel={(e) => e.currentTarget.blur()}
                                type="number"
                                className="form-control form-control-solid"
                                {...getFieldProps('tenure')}
                              />
                              <ErrorMessage name="tenure" component="div" className="text-danger" />
                            </div>
                          </div>
                          <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                              <label className="fs-5 fw-semibold required mb-2">Declaration</label>
                              <input
                                type="text"
                                className="form-control form-control-solid"
                                {...getFieldProps('declaration')}
                              />
                              <ErrorMessage
                                name="declaration"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <button disabled={submitting} type="submit" className="btn btn-primary">
                            {submitting && (
                              <span
                                className="spinner-border spinner-border-sm mx-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            {submitting ? 'Submitting...' : 'Submit'}
                          </button>
                        </div>
                        <FocusError />
                      </Form>
                    </FormikProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FundingRequestForm;
