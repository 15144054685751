import useStatePersistence from 'helpers/useStatePersistence';
import resourceJsonPersistence from 'persistence/resourceJsonPersistence';

interface IUseResourceOptions {
  noFetchIfCached?: boolean;
}

const useResource = (
  cacheKey: string,
  fetchFunc: () => Promise<any>,
  defaultValue: any,
  options: IUseResourceOptions = {}
) => {
  const { noFetchIfCached } = options;
  const [value, setValue] = useStatePersistence(resourceJsonPersistence, cacheKey, defaultValue);

  const fetch = async () => {
    if (resourceJsonPersistence.get(cacheKey) !== null && noFetchIfCached) {
      return;
    }

    const fetchedValue = await fetchFunc();
    setValue(fetchedValue);
  };

  return [fetch, value, setValue];
};

export default useResource;
