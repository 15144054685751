import { useMemo, useState } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import constate from 'constate';
// import { useSetLoggedOutState } from 'state/useAuthStateShared';
import { API_URL } from 'config';
import { useMagicState } from './useMagicState';
// import { toFormData } from 'helpers/http';
import userTokenPersistence from 'persistence/userTokenPersistence';
import {
  LoginInput,
  MatchingRule,
  Registry,
  SignUpInput,
  UpdateEntityDetails
} from 'types/profileTypes';
import { PaymentTokenProps } from 'types/coreTablesTypes';
import { CreateEntity } from 'types/entity';

type Methods = 'head' | 'options' | 'put' | 'post' | 'patch' | 'delete' | 'get';

interface MyErrorType {
  response?: {
    status: number;
  };
}

const buildApi = (setLoggedOutState: () => void, setIsLoading: (isLoading: boolean) => void) => {
  const processResponse = async (callInstance: any) => {
    try {
      setIsLoading(true);

      const { data } = await callInstance;
      if (data && data.error) {
        throw new Error(data.error);
      }

      return data;
    } catch (e: MyErrorType | any) {
      if (e.response && e.response.status) {
        if (e.response.status === 401) {
          localStorage.clear();
          setLoggedOutState();
          return;
        }
      }

      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const getAuthHeaders = () => {
    const token = userTokenPersistence.get();

    if (!token) {
      return {};
    }

    return { Authorization: `Token ${token}` };
  };

  const callAxiosMethod = (
    methodName: Methods,
    path?: string,
    ...args: AxiosRequestConfig<unknown>[]
  ) =>
    processResponse(
      axios[methodName](`${API_URL}${path}`, ...args, {
        headers: { ...getAuthHeaders() }
      })
    );

  const get = (path: string, ...args: AxiosRequestConfig<unknown>[]) =>
    callAxiosMethod('get', path, ...args);
  const post = (path: string, ...args: any[]) => callAxiosMethod('post', path, ...args);
  // // eslint-disable-next-line no-unused-vars
  const put = (path: string, ...args: any[]) => callAxiosMethod('put', path, ...args);
  const patch = (path: string, ...args: AxiosRequestConfig<unknown>[]) =>
    callAxiosMethod('patch', path, ...args);
  // // eslint-disable-next-line no-unused-vars
  const Delete = (path: string, ...args: AxiosRequestConfig<unknown>[]) =>
    callAxiosMethod('delete', path, ...args);

  // user API's
  const userLogin = (data: LoginInput) => post('user/login/', data);
  const getLoggedInUser = () => get('user/get-logged-in-user/');
  const userSignUp = (data: SignUpInput) => post('user/', data);
  const userSignOut = (userEmail: string) => post(`user/logout/`, {});
  const userForgotPassword = (data: any) => post('user/reset-password/', data);
  const userChangePassword = (data: any) => patch('user/change-password/', data);
  const confirmForgotPassword = (data: any) => post('user/reset-password/confirm/', data);
  const userVerifyToken = (data: any) => post('user/reset-password/verify-token/', data);

  // entity API's
  const getAllApprovedEntitiesUserMap = () => get('entity/get_all_approved_entity_user_maps/');
  const getAllPendingEntitiesUserMap = () => get('entity/get_all_pending_entity_user_maps/');
  const approveEntityUser = (id: string) => patch(`entity/approve_entity_by_id/${id}/`);
  const rejectEntityUser = (id: string) => Delete(`entity/reject_entity_by_id/${id}/`);
  const getEntityById = (id: string) => get(`entity/get_entity_by_id/${id}/`);
  const getEntityTypes = () => get('entity/get-entity-type/');
  const getIdentifierTypes = () => get('entity/get-identifier-type/');
  const createOrUpdateMatchingRule = (data: MatchingRule) =>
    post(`entity/create-or-update-matching-rule/`, data);
  const getMatchingRuleByEntity = (id: string) => get(`entity/get-matching-rule-by-entity/${id}/`);
  const getAllTradersForId = (id: string) => get(`entity/get-traders-for-id/${id}/`);
  const registerCompany = (data: CreateEntity) => post('entity/create-entity/', data);
  const getRegistryByEntityId = (id: string) => get(`entity/get-entity-dns/${id}/`);
  const createOrUpdateRegistryByEntityId = (data: Registry) =>
    post(`entity/create-update-entity-dns/`, data);
  const updateEntityUser = (data: UpdateEntityDetails) =>
    post('entity/update-entity-details/', data);

  // coretable API's
  const getAllCountries = () => get('coretable/get-all-countries/');
  const getAllCurrencies = () => get('coretable/get-all-currencies/');
  const getAllCommodities = () => get('coretable/get-all-commodities/');
  const getAllPaymentTokens = () => get('coretable/get-all-payment-tokens/');
  const addPaymentToken = (data: PaymentTokenProps) =>
    post('coretable/create-payment-token/', data);
  const paymentTokenByCurr = (id: string) =>
    get(`coretable/get-payment-token-by-pegged-currencies/${id}/`);

  //trade API
  const createTradeFundingRequest = (data: any) => post('trade/create-funding-request/', data);
  const getAllFundingRequest = () => get('trade/get-all-funding-request/');
  const getPendingSettlementRequests = () => get('trade/get-unapproved-request-by-beneficiary/');
  const getTradeName = () => get('trade/get-trade-name/');
  const getFundingRequestByID = (id: string) => get(`trade/get-funding-request/${id}/`);
  const approveFundingRequestLP = (id: string, data: any) =>
    patch(`trade/approve-funding-request/${id}/`, data);
  const approveFundingRequestBeneficiary = (id: string, data: any) =>
    patch(`trade/approve-funding-request-beneficiary/${id}/`, data);
  // use getAllApprovedFundingRequests for LP and exporter
  const getAllApprovedFundingRequests = () => get(`trade/get-all-approved-funding-request`);
  const updateFundingRequestStatus = (id: string, data: { status_name: string }) =>
    put(`trade/change-funding-request-status/${id}/`, data);
  const getAllApprovedByBeneficiaryFundingRequests = () =>
    get(`trade/get-approved-request-by-beneficiary/`);
  const getFundingRequestHistory = () => get(`trade/get-funding-request-history/`);
  const checkBlExits = (address: string) => get(`trade/check-bl-exists/${address}/`);
  const deleteFundingRequest = (id: string) => post(`trade/delete-funding-request/${id}/`, {});
  const checkInvoiceExists = (invoice_number: string) =>
    get(`trade/check-invoice-exists/?invoice_number=${invoice_number}`);

  return {
    // user
    userLogin,
    userSignUp,
    userSignOut,
    userVerifyToken,
    userForgotPassword,
    confirmForgotPassword,
    userChangePassword,
    // entity
    getAllApprovedEntitiesUserMap,
    getAllPendingEntitiesUserMap,
    approveEntityUser,
    rejectEntityUser,
    getEntityById,
    getLoggedInUser,
    getEntityTypes,
    getIdentifierTypes,
    getAllTradersForId,
    registerCompany,
    updateEntityUser,
    // coretable API's
    getAllCountries,
    getAllCurrencies,
    getAllCommodities,
    createOrUpdateMatchingRule,
    getMatchingRuleByEntity,
    getAllPaymentTokens,
    addPaymentToken,
    paymentTokenByCurr,
    //trade API's
    createTradeFundingRequest,
    getAllFundingRequest,
    getPendingSettlementRequests,
    getTradeName,
    getFundingRequestByID,
    approveFundingRequestLP,
    approveFundingRequestBeneficiary,
    getAllApprovedFundingRequests,
    updateFundingRequestStatus,
    getAllApprovedByBeneficiaryFundingRequests,
    getFundingRequestHistory,
    getRegistryByEntityId,
    createOrUpdateRegistryByEntityId,
    checkBlExits,
    deleteFundingRequest,
    checkInvoiceExists
  };
};

export const useHttpApi_ = () => {
  // const setLoggedOutState = useSetLoggedOutState();
  const { disconnectMagic: setLoggedOutState } = useMagicState();
  const [isLoading, setIsLoading] = useState(false);

  const api = useMemo(() => buildApi(setLoggedOutState, setIsLoading), [setLoggedOutState]);

  return { api, isLoading };
};

export const [HttpApiProvider, useHttpApi, useHttpApiLoading] = constate(
  useHttpApi_,
  ({ api }) => api,
  ({ isLoading }) => isLoading
);
