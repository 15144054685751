import React, { VoidFunctionComponent, useState } from 'react';
import logo from '../../assets/media/logos/logo-white.svg';
import logoSmall from '../../assets/media/logos/logo-white-small.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppState, useAuthStateShared } from 'state';
import { SidebarLinks } from 'types/sidebarTypes';

interface Links {
  sidebarLinks: SidebarLinks[];
  entityType: string;
  // changeTraderRole: (traderRole: string) => void;
  // traderRole? : string;
}

const Sidebar = ({ sidebarLinks, entityType }: Links) => {
  const location = useLocation();

  const { isExpanded, setIsExpanded, traderRole } = useAppState();

  const navigate = useNavigate();

  const { user } = useAuthStateShared();

  const handleExpanded = () => {
    setIsExpanded(!isExpanded);
    console.log('expnaded', isExpanded);
  };

  // const handleRoleChangeForTrader = (e: any) => {
  //   console.log(e.target.value);
  //   // changeTraderRole(e.target.value)
  //   setTraderRole(e.target.value);

  //   const traderRole = e.target.value;

  //   if (traderRole === 'IMPORTER') {
  //     navigate('/trader/imports');
  //   } else if (traderRole === 'EXPORTER') {
  //     navigate('/trader');
  //   }
  // };
  return (
    <div
      id="kt_app_sidebar"
      className="app-sidebar flex-column animating"
      data-kt-drawer="true"
      data-kt-drawer-name="app-sidebar"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="225px"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
      data-kt-app-sidebar-minimize={isExpanded ? 'off' : 'on'}
    >
      <div className="app-sidebar-logo px-6" id="kt_app_sidebar_logo">
        <a href="/">
          <img
            alt="XDC Trade Network"
            title="XDC Trade Network"
            src={logo}
            className="h-40px app-sidebar-logo-default"
          />
          <img
            alt="XDC Trade Network"
            title="XDC Trade Network"
            src={logoSmall}
            className="h-40px app-sidebar-logo-minimize"
          />
        </a>
        <div
          id="kt_app_sidebar_toggle"
          className={`app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate ${
            isExpanded ? '' : 'active'
          }`}
          data-kt-toggle="true"
          data-kt-toggle-state="active"
          data-kt-toggle-target="#kt_app_sidebar"
          data-kt-toggle-name="app-sidebar-minimize"
          onClick={handleExpanded}
        >
          <i className="ki-duotone ki-double-left fs-2 rotate-180">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        </div>
      </div>

      <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
        <div
          id="kt_app_sidebar_menu_wrapper"
          className="app-sidebar-wrapper hover-scroll-overlay-y my-5"
          data-kt-scroll="true"
          data-kt-scroll-activate="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
          data-kt-scroll-wrappers="#kt_app_sidebar_menu"
          data-kt-scroll-offset="5px"
          data-kt-scroll-save-state="true"
        >
          <div
            className="menu menu-column menu-rounded menu-sub-indention px-3"
            id="#kt_app_sidebar_menu"
            data-kt-menu="true"
            data-kt-menu-expand="false"
          >
            {/* <div className="menu-item">
              <div className="menu-content">
                <span className="menu-heading fw-bold text-uppercase fs-3">Welcome</span>
                <br />
                <span className="menu-heading text-uppercase fs-7">{user?.user?.name}</span>
              </div>
            </div> */}
            <div className="menu-item">
              <div className="menu-content d-flex justify-content-start align-items-center">
                <span className="menu-heading fw-bold text-uppercase fs-7">{entityType}</span>
                {/* {entityType === 'Trader' && (
                  <>
                    <span className="menu-heading text-uppercase fs-7">{} - </span>
                    <div className="d-flex justify-content-start align-items-center">
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        style={{
                          color: 'black',
                          marginLeft: '5px',
                          border: 'none'
                        }}
                        onChange={handleRoleChangeForTrader}
                      >
                        <option
                          selected
                          value="EXPORTER"
                          style={{ backgroundColor: 'white', color: 'black' }}
                        >
                          EXPORTER
                        </option>
                        <option
                          value="IMPORTER"
                          style={{ backgroundColor: 'white', color: 'black' }}
                        >
                          IMPORTER
                        </option>
                      </select>
                    </div>
                  </>
                )} */}
              </div>
            </div>
            {sidebarLinks?.map((link) => {
              return (
                <>
                  {entityType === 'Trader' && traderRole === link?.traderRole ? (
                    <div className="menu-item " key={link.title}>
                      <Link
                        className={`menu-link ${location.pathname === link.route ? 'active' : ''}`}
                        to={link.route}
                      >
                        <span className="menu-icon">
                          <i className={`${link.icon} fs-2`}></i>
                        </span>
                        <span className="menu-title">{link.title}</span>
                      </Link>
                    </div>
                  ) : (
                    <>
                      {entityType !== 'Trader' && (
                        <div className="menu-item " key={link.title}>
                          <Link
                            className={`menu-link ${
                              location.pathname === link.route ? 'active' : ''
                            }`}
                            to={link.route}
                          >
                            <span className="menu-icon">
                              <i className={`${link.icon} fs-2`}></i>
                            </span>
                            <span className="menu-title">{link.title}</span>
                          </Link>
                        </div>
                      )}
                    </>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
