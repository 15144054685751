/**
 * Renders the dashboard for the admin portal.
 * @returns {React.Element} - The rendered component.
 */

import { useEffect } from 'react';
import dashboardIcon02 from '../../assets/media/misc/dashboard-icon-02.jpg';
import dashboardIcon03 from '../../assets/media/misc/dashboard-icon-03.jpg';
import DashboardCard from 'components/common/DashboardCard';
import PendingUserRequests from './PendingUserRequest';
import { useAppState } from 'state';

const AdminDashboard = () => {
  const {
    fetchAllPendingEntitiesUsers,
    allPendingEntitiesUsers,
    allApprovedEntitiesUsers,
    fetchAllApprovedEntitiesUsers
  } = useAppState();
  useEffect(() => {
    fetchAllPendingEntitiesUsers();
    fetchAllApprovedEntitiesUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div id="kt_app_content" className="app-content flex-column-fluid">
      <div id="kt_app_content_container" className="app-container container-fluid">
        <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-3">
          <DashboardCard
            title="Request Pending"
            count={allPendingEntitiesUsers.length}
            imgSrc={dashboardIcon02}
            redirectTo={'/admin'}
          />
          <DashboardCard
            title="Request Approved"
            count={allApprovedEntitiesUsers.length}
            imgSrc={dashboardIcon03}
            redirectTo={'/admin/view-users'}
          />
        </div>
        <PendingUserRequests data={allPendingEntitiesUsers} />
      </div>
    </div>
  );
};

export default AdminDashboard;
