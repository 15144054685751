import { Outlet, useNavigate } from 'react-router-dom';
import Footer from './main/Footer';
import Navbar from './main/Navbar';
import { useAppState, useAuthState } from 'state';
import Sidebar from 'components/core/Sidebar';
import { getSidebarLinks } from 'utils/sidebarLinks';
import { useEffect, useState } from 'react';
import RequireUserWalletOverlay from 'components/core/RequireUserWalletOverlay';
import WalletRequireOverlay from 'components/core/WalletRequireOverlay';
import { SidebarLinks } from 'types/sidebarTypes';

const MainLayout = () => {
  const { isExpanded } = useAppState();
  const { user } = useAuthState();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.user?.role?.title !== 'ADMIN' && (!user?.entity || !user?.entity?.is_approved)) {
      navigate('/register');
    }
  });

  return (
    <div
      id="kt_app_body"
      data-kt-app-layout="dark-sidebar"
      data-kt-app-header-fixed="true"
      data-kt-app-sidebar-enabled="true"
      data-kt-app-sidebar-fixed="true"
      data-kt-app-sidebar-hoverable="false"
      data-kt-app-sidebar-push-header="true"
      data-kt-app-sidebar-push-toolbar="true"
      data-kt-app-sidebar-push-footer="true"
      data-kt-app-toolbar-enabled="true"
      className="app-default d-flex flex-column min-vh-100"
      data-kt-app-sidebar-minimize={isExpanded ? '' : 'on'}
    >
      {/* Sidebar */}
      <Sidebar
        // changeTraderRole = {changeTraderRole}
        sidebarLinks={getSidebarLinks(user?.entity?.entity_type?.name)}
        // traderRole={traderRole}
        entityType={user && user.entity ? user?.entity?.entity_type?.description : 'ADMIN'}
      />

      {/* Header */}
      <div id="kt_app_header" className="app-header">
        <Navbar />
      </div>

      {/* Wrapper */}
      <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
        {/* MainContent */}
        <WalletRequireOverlay />

        <RequireUserWalletOverlay />

        <Outlet />

        {/* Footer */}
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
