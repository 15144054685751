import FundingRequestsTable from 'components/fundingTables/FundingRequestsTable';
import React, { useEffect } from 'react';
import { useAppState, useAuthState } from 'state';

const TraderFunding = () => {
  const { user } = useAuthState();
  const { fetchAllTradersForEntity } = useAppState();

  useEffect(() => {
    if (user) {
      fetchAllTradersForEntity(user?.entity.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = [
    {
      trade_name: 'Universal Goods Inc.',
      commodity: 'Exporter 1',
      roi: '02 Apr, 2023',
      tenure: '180 Days',
      value: '$1.2Mn',
      status: 'New'
    },
    {
      trade_name: 'Transatlantic Corp',
      commodity: 'Exporter 2',
      roi: '02 Apr, 2023',
      tenure: '180 Days',
      value: '590K',
      status: 'In-Review'
    },
    {
      trade_name: 'Best Exports LLC',
      commodity: 'Exporter 3',
      roi: '14 Aug, 1018',
      tenure: '180 Days',
      value: '$1.85Mn',
      status: 'Approved'
    }
  ];

  return (
    <div id="kt_app_content" className="app-content flex-column-fluid pt-5 mt-5">
      <div id="kt_app_content_container" className="app-container container-fluid">
        <FundingRequestsTable data={data} role={'trader'} />
      </div>
    </div>
  );
};

export default TraderFunding;
