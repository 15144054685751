import { titleCase } from 'helpers/common';
import React from 'react';
import { useLocation } from 'react-router';
import { useAppState } from 'state';

interface CardFieldProps {
  trade_name: string;
  exporter: string;
  beneficiary: string;
  rate_of_interest: number | string;
  commodity: string;
  value: number | string;
  tenure: number | string;
  status?: string;
  from_country: string;
  to_country: string;
  liquidityProvider: string;
  shipmentDate: string;
  settlementValue: number | string;
  settlement: string;
}

const CardField = ({
  trade_name,
  exporter,
  beneficiary,
  rate_of_interest,
  commodity,
  value,
  from_country,
  to_country,
  tenure,
  liquidityProvider,
  status,
  shipmentDate,
  settlementValue,
  settlement
}: CardFieldProps) => {
  const location = useLocation();

  const { traderRole } = useAppState();

  return (
    <div className="row py-2 px-2">
      <div className="col-4 pe-20">
        <div className="card ps-2 mb-6" style={{ backgroundColor: '#04308a' }}>
          <div className="card py-2 ps-3">
            <h3 className="card-title">{trade_name}</h3>
            <h5 className="card-title text-muted">Trade Name</h5>
          </div>
        </div>
      </div>
      <div className="col-4 pe-20">
        <div className="card ps-2 mb-6" style={{ backgroundColor: '#04308a' }}>
          <div className="card py-2 ps-3">
            <h3 className="card-title">{exporter}</h3>
            <h5 className="card-title text-muted">Created By</h5>
          </div>
        </div>
      </div>
      <div className="col-4 pe-20">
        <div className="card ps-2 mb-6" style={{ backgroundColor: '#04308a' }}>
          <div className="card py-2 ps-3">
            <h3 className="card-title">{beneficiary}</h3>
            <h5 className="card-title text-muted">Beneficiary Company</h5>
          </div>
        </div>
      </div>
      <div className="col-4 pe-20">
        <div className="card ps-2 mb-6" style={{ backgroundColor: '#04308a' }}>
          <div className="card py-2 ps-3">
            <h3 className="card-title">{commodity}</h3>
            <h5 className="card-title text-muted">Commodity</h5>
          </div>
        </div>
      </div>
      <div className="col-4 pe-20">
        <div className="card ps-2 mb-6" style={{ backgroundColor: '#04308a' }}>
          <div className="card py-2 ps-3">
            <h3 className="card-title">{from_country}</h3>
            <h5 className="card-title text-muted">From Country</h5>
          </div>
        </div>
      </div>
      <div className="col-4 pe-20">
        <div className="card ps-2 mb-6" style={{ backgroundColor: '#04308a' }}>
          <div className="card py-2 ps-3">
            <h3 className="card-title">{to_country}</h3>
            <h5 className="card-title text-muted">To Country</h5>
          </div>
        </div>
      </div>
      <div className="col-4 pe-20">
        <div className="card ps-2 mb-6" style={{ backgroundColor: '#04308a' }}>
          <div className="card py-2 ps-3">
            <h3 className="card-title">{shipmentDate}</h3>
            <h5 className="card-title text-muted">Shipment Date</h5>
          </div>
        </div>
      </div>
      <div className="col-4 pe-20">
        <div className="card ps-2 mb-6" style={{ backgroundColor: '#04308a' }}>
          <div className="card py-2 ps-3">
            <h3 className="card-title">{rate_of_interest}%</h3>
            <h5 className="card-title text-muted">ROI(%PA)</h5>
          </div>
        </div>
      </div>
      <div className="col-4 pe-20">
        <div className="card ps-2 mb-6" style={{ backgroundColor: '#04308a' }}>
          <div className="card py-2 ps-3">
            <h3 className="card-title">{value}</h3>
            <h5 className="card-title text-muted">Funds Requested</h5>
          </div>
        </div>
      </div>
      {traderRole === 'IMPORTER' && (
        <div className="col-4 pe-20">
          <div className="card ps-2 mb-6" style={{ backgroundColor: '#04308a' }}>
            <div className="card py-2 ps-3">
              <h3 className="card-title">{settlementValue}</h3>
              <h5 className="card-title text-muted">Settlement Amount</h5>
            </div>
          </div>
        </div>
      )}
      <div className="col-4 pe-20">
        <div className="card ps-2 mb-6" style={{ backgroundColor: '#04308a' }}>
          <div className="card py-2 ps-3">
            <h3 className="card-title">{tenure}</h3>
            <h5 className="card-title text-muted">Tenure (Days)</h5>
          </div>
        </div>
      </div>
      <div className="col-4 pe-20">
        <div className="card ps-2 mb-6" style={{ backgroundColor: '#04308a' }}>
          <div className="card py-2 ps-3">
            <h3 className="card-title">
              {location.pathname.startsWith('/trader/imports') && status === 'Funded'
                ? 'Pending Settlement'
                : status}
            </h3>
            <h5 className="card-title text-muted">Status</h5>
          </div>
        </div>
      </div>
      <div className="col-4 pe-20">
        <div className="card ps-2 mb-6" style={{ backgroundColor: '#04308a' }}>
          <div className="card py-2 ps-3">
            <h3 className="card-title">{titleCase(settlement)}</h3>
            <h5 className="card-title text-muted">Settlement</h5>
          </div>
        </div>
      </div>
      {status === 'Funded' || status === 'Complete' ? (
        <div className="col-4 pe-20">
          <div className="card ps-2 mb-6" style={{ backgroundColor: '#04308a' }}>
            <div className="card py-2 ps-3">
              <h3 className="card-title">
                {status === 'Complete' && beneficiary}
                {status === 'Funded' && liquidityProvider}
              </h3>
              <h5 className="card-title text-muted">Document Owner</h5>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CardField;
