import CardField from 'components/common/CardField';
import UploadFileModal from 'components/common/UploadFileModal';
import ViewFundingDetails from 'components/common/ViewFundingDetails';
import FundingHeaderTable from 'components/fundingTables/FundingRequestsTable';
import { titleCase } from 'helpers/common';
import React, { useEffect } from 'react';
import { useTradeState } from 'state';

const ViewFunding = () => {
  return (
    <div id="kt_app_content" className="app-content flex-column-fluid mt-10">
      {/* <!--begin::Content container--> */}
      <div id="kt_app_content_container" className="app-container container-fluid">
        <ViewFundingDetails />
      </div>
    </div>
  );
};

export default ViewFunding;
