import Overlay from './Overlay';
import WalletRequire from './WalletRequire';

const WalletRequireOverlay = () => {
  return (
    <WalletRequire not>
      <Overlay show>
        <div
          style={{
            height: '85vh',
            width: '50%',
            margin: 'auto',
            display: 'flex',
            position: 'fixed',
            scrollSnapStop: 'unset',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className="alert alert-primary" role="alert">
            <h4 className="alert-heading">Connecting Wallet...</h4>
            <p
              style={{
                fontWeight: 500
              }}
              className="mb-0"
            >
              Please wait while we connect your wallet.
            </p>
          </div>
        </div>
      </Overlay>
    </WalletRequire>
  );
};

export default WalletRequireOverlay;
