import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppState } from 'state';
import { useTradeState } from 'state/useTradeState';
import { FundingRequestList } from 'types/tradeTypes';
import resourcePersistence from 'persistence/resourcePersistence';
import { statusTradeLabel } from 'helpers/common';
import { titleCase } from 'helpers/common';

const FundingImportsTable = ({ role }: any) => {
  const { pendingSettlementsRequests, fetchPendingSettlementRequests, setFundingRequestID } =
    useTradeState();
  useEffect(() => {
    fetchPendingSettlementRequests();
  }, []);

  const { allTradersForEntity } = useAppState();

  return (
    <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
      <div className="col-xxl-12">
        <div className="card card-flush h-xl-100">
          <div className="card-header pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-dark">Funding Request</span>
            </h3>

            {role === 'trader' && (
              <div className="card-toolbar">
                {/* {allTradersForEntity?.length === 0 ? (
                  <button type="button" className="btn btn-sm btn-danger">
                    No importers available for new funding requests.
                  </button>
                ) : (
                  <Link to="create-funding-request">
                    <button type="button" className="btn btn-sm btn-primary">
                      + New
                    </button>
                  </Link>
                )} */}
                <Link to="create-funding-request">
                  <button type="button" className="btn btn-sm btn-primary">
                    + New
                  </button>
                </Link>
              </div>
            )}
          </div>

          <div className="card-body pt-6">
            <div className="table-responsive">
              <table className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer">
                <thead>
                  <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                    <th className="min-w-175px text-start">Created By</th>
                    <th className="min-w-100px">Invoice No.</th>
                    <th className="min-w-100px">Commodity</th>
                    <th className="min-w-100px">ROI(%PA)</th>
                    <th className="min-w-100px">Settlement Amount</th>
                    <th className="min-w-100px">Funded Value</th>
                    <th className="min-w-100px">Tenure (Days)</th>
                    <th className="min-w-100px">Status</th>
                    <th className="min-w-100px">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {pendingSettlementsRequests?.map(
                    (fundingRequest: FundingRequestList, index: number) => {
                      return (
                        <tr key={fundingRequest.id}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="d-flex justify-content-start flex-column">
                                <span className="text-gray-800 fw-bold mb-1 fs-6">
                                  {fundingRequest.trade?.exporter?.name}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>{fundingRequest?.invoice_number}</td>
                          <td>
                            {fundingRequest.trade.commodity ? fundingRequest.trade.commodity : 'NA'}
                          </td>
                          <td>{fundingRequest.rate_of_interest}%</td>
                          <td className="pe-12">
                            {`${
                              fundingRequest.amount_to_be_funded + fundingRequest?.accured_interest
                            } ${fundingRequest.funding_currency.code}`}
                          </td>
                          <td>{`${fundingRequest?.amount_to_be_funded}  ${fundingRequest.funding_currency.code}`}</td>
                          <td>{fundingRequest.tenure}</td>
                          <td>
                            <span className={`py-3 px-4 fs-7`}>
                              {fundingRequest.status.description === 'Funded'
                                ? 'Pending Settlement'
                                : fundingRequest.status.description}
                            </span>
                          </td>
                          <td>
                            <Link
                              to="view-funding"
                              className="btn btn-primary"
                              onClick={() => {
                                setFundingRequestID(fundingRequest.id);
                                resourcePersistence.set('fundingRequestID', fundingRequest.id);
                              }}
                            >
                              View
                              {/* <i className="ki-duotone ki-black-right fs-2 text-gray-500"></i> */}
                            </Link>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundingImportsTable;
