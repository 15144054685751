import { useWeb3State } from 'state';
import Overlay from './Overlay';
import UserWalletRequire from './UserWalletRequire';

const RequireUserWalletOverlay = () => {
  return (
    <UserWalletRequire not>
      <Overlay show>
        <div
          style={{
            height: '85vh',
            width: '85%',
            display: 'flex',
            position: 'fixed',
            scrollSnapStop: 'unset',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className="alert alert-primary" role="alert">
            <h4 className="alert-heading">Wrong address!</h4>
            The current wallet address is not linked to logged in user account.
          </div>
        </div>
      </Overlay>
    </UserWalletRequire>
  );
};

export default RequireUserWalletOverlay;
