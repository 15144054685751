import { useState, useCallback } from 'react';

interface IPersistence {
  get: (key: string) => any;
  set: (key: string, value: any) => void;
}

const useStatePersistence = (persistence: IPersistence, cacheKey: string, defaultValue: any) => {
  const [value, setValue] = useState<any>(() => {
    const cachedValue = persistence.get(cacheKey);
    return cachedValue !== null ? cachedValue : defaultValue;
  });

  const setValue_ = useCallback((newValue: any) => {
    persistence.set(cacheKey, newValue);
    setValue(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [value, setValue_];
};

export default useStatePersistence;
