import constate from 'constate';
import { useCallback, useState } from 'react';
import { MAGIC_LINK_KEY } from 'config';
import { currentNetwork } from 'helpers/web3';
import { Magic as MagicBase } from 'magic-sdk';
import { OAuthExtension } from '@magic-ext/oauth';
import { AuthExtension } from '@magic-ext/auth';

export type Magic = MagicBase<AuthExtension & OAuthExtension[]>;

const useMagic = () => {
  const [magic, setMagic] = useState<Magic | null>(null);

  const initializeMagicSDK = useCallback(() => {
    const networkRPC = currentNetwork.rpcUrl;
    const magicSDK = new MagicBase(MAGIC_LINK_KEY as string, {
      deferPreload: true,
      network: {
        rpcUrl: networkRPC ? networkRPC : 'https://erpc.apothem.network/',
        chainId: currentNetwork
      },
      extensions: [new AuthExtension(), new OAuthExtension()]
    });

    return magicSDK;
  }, [currentNetwork]);

  const disconnectMagic = useCallback(async () => {
    try {
      let magicSDK = magic;
      if (!magic) {
        magicSDK = initializeMagicSDK();
      }
      const isWalletLoggedIn = await magicSDK?.user.isLoggedIn();
      console.log(isWalletLoggedIn);
      if (isWalletLoggedIn) {
        await magicSDK?.user.logout();
      }
    } catch (err) {
      console.error('Disconnect error:', err);
    }
  }, [magic]);

  return {
    magic,
    setMagic,
    initializeMagicSDK,
    disconnectMagic
  };
};

export const [magicStateProvider, useMagicState] = constate(useMagic);
