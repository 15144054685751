/**
 * Renders a table displaying accepted user data and action to view profile.
 * @param {object} data - The data to populate the table.
 * @returns {React.Element} - The rendered component.
 */

import { Link } from 'react-router-dom';
import { useAppState } from 'state';
import { EntityUserProps } from 'types/entity';

interface AllUsersTableProps {
  data: EntityUserProps[];
}

const AllUsersTable = ({ data }: AllUsersTableProps) => {
  const { setCurrentEntityUserId } = useAppState();
  return (
    <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
      <div className="col-xxl-12">
        <div className="card card-flush h-xl-100">
          <div className="card-header pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-dark">Users</span>
            </h3>
          </div>

          <div className="card-body pt-6">
            <div className="table-responsive">
              <table className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer">
                <thead>
                  <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                    <th className="min-w-250px text-start">Company Name</th>
                    <th className="min-w-250px">Role</th>
                    <th className="min-w-250px">Email ID</th>
                    <th className="min-w-250px">KYC Status</th>
                    <th className="min-w-100px text-end">View Profile</th>
                  </tr>
                </thead>

                <tbody>
                  {data?.map((item: EntityUserProps, index: number) => {
                    return (
                      <tr key={item.id ? item.id : index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span className="text-gray-800 fw-bold mb-1 fs-6">
                                {item?.entity?.name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>{item.entity?.entity_type.description}</td>
                        <td>{item.user?.name}</td>
                        <td>{item.user?.email}</td>
                        <td className="text-end">
                          <Link
                            to="/admin/view-user-profile"
                            onClick={() => setCurrentEntityUserId(item?.id)}
                            className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px"
                          >
                            <i className="ki-duotone ki-black-right fs-2 text-gray-500"></i>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllUsersTable;
