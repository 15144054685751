import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useAppState, useHttpApi } from 'state';
import FocusError from 'components/FocusError';
import TermsAndCondition from './TermsAndCondition';
import { EmailRegex } from 'helpers/regex';

const SignUpForm = (): JSX.Element => {
  const navigate = useNavigate();
  const { userSignUp } = useHttpApi();
  const { throwErrorMessage } = useAppState();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const UserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').matches(EmailRegex, 'Invalid Email Address'),
    password: Yup.string().min(8, 'Minimum 8 characters required').required('Password is required'),
    confirm_password: Yup.string()
      .min(8, 'Minimum 8 characters required')
      .required('Confirm password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
    is_terms_and_conditions_selected: Yup.boolean()
      .required('You must accept the terms and conditions')
      .oneOf([true], 'You must accept the terms and conditions')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      confirm_password: '',
      is_terms_and_conditions_selected: ''
    },
    validationSchema: UserSchema,
    onSubmit: async (data) => {
      try {
        setSubmitting(true);
        console.log(data);
        await userSignUp({
          name: data.name,
          email: data.email.toLowerCase(),
          password: data.password
        });
        navigate('/login', { replace: true });
        setSubmitting(false);
      } catch (e) {
        console.log(e);
        throwErrorMessage(e);
        setSubmitting(false);
      }
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
      <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
        <FormikProvider value={formik}>
          <form
            className="form w-100"
            // noValidate="novalidate"
            id="kt_sign_up_form"
            data-kt-redirect-url="index.html"
            action="#"
            onSubmit={handleSubmit}
          >
            <div className="text-center mb-11">
              <h1 className="text-dark fw-bolder mb-3">Sign Up</h1>

              <div className="text-gray-500 fw-semibold fs-6">Enter your credentials</div>
            </div>
            <div className="fv-row mb-8">
              <input
                type="text"
                placeholder="Name"
                autoComplete="off"
                className="form-control bg-transparent"
                id="name"
                {...getFieldProps('name')}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                  {formik.errors.name}
                </div>
              ) : null}
            </div>
            <div className="fv-row mb-8">
              <input
                type="email"
                placeholder="Email"
                autoComplete="off"
                className="form-control bg-transparent"
                id="email"
                {...getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                  {formik.errors.email}
                </div>
              ) : null}
            </div>

            <div className="fv-row mb-8" data-kt-password-meter="true">
              <div className="mb-1">
                <div className="position-relative mb-3">
                  <input
                    className="form-control bg-transparent"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    autoComplete="off"
                    id="password"
                    {...getFieldProps('password')}
                  />
                  <span
                    className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                    data-kt-password-meter-control="visibility"
                    onClick={handleShowPassword}
                  >
                    {!showPassword ? (
                      <i className="ki-duotone ki-eye-slash fs-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                      </i>
                    ) : (
                      <i className="ki-duotone ki-eye  fs-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                    )}
                  </span>

                  {formik.touched.password && formik.errors.password ? (
                    <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="fv-row mb-8" data-kt-password-meter="true">
              <div className="mb-1">
                <div className="position-relative mb-3">
                  <input
                    className="form-control bg-transparent"
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    autoComplete="off"
                    id="confirm_password"
                    {...getFieldProps('confirm_password')}
                  />
                  <span
                    className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                    data-kt-password-meter-control="visibility"
                    onClick={handleShowConfirmPassword}
                  >
                    {!showConfirmPassword ? (
                      <i className="ki-duotone ki-eye-slash fs-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                      </i>
                    ) : (
                      <i className="ki-duotone ki-eye  fs-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                    )}
                  </span>

                  {formik.touched.confirm_password && formik.errors.confirm_password ? (
                    <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                      {formik.errors.confirm_password}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="fv-row mb-8">
              <label className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  style={{ borderColor: '#636e72' }}
                  type="checkbox"
                  id="is_terms_and_conditions_selected"
                  {...getFieldProps('is_terms_and_conditions_selected')}
                />
                <span className="form-check-label fw-semibold text-gray-700 fs-base ms-1">
                  I Accept the
                  <Link
                    className="link-primary fw-semibold ms-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_account"
                  >
                    terms & conditions
                  </Link>
                </span>

                {formik.touched.is_terms_and_conditions_selected &&
                formik.errors.is_terms_and_conditions_selected ? (
                  <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                    {formik.errors.is_terms_and_conditions_selected}
                  </div>
                ) : null}
              </label>
            </div>

            <div className="d-grid mb-10">
              <button
                type="submit"
                id="kt_sign_up_submit"
                className="btn btn-primary"
                disabled={submitting}
              >
                {submitting && (
                  <span
                    className="spinner-border spinner-border-sm mx-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                {submitting ? 'Submitting...' : 'Signup'}
              </button>
            </div>

            <div className="text-gray-500 text-center fw-semibold fs-6">
              Already have an Account?
              <Link className="link-primary fw-semibold" to="/login">
                {' '}
                Sign in
              </Link>
            </div>
            <FocusError />
          </form>
        </FormikProvider>
      </div>
      <div className="modal fade" id="kt_modal_create_account" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <TermsAndCondition />
          </div>
        </div>
      </div>

      <div className="px-lg-10">
        <div className="text-center text-muted px-lg-10">
          &copy; 2023 XDC Trade Network - All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
