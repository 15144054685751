import FocusError from 'components/FocusError';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAppState, useHttpApi } from 'state';
import * as Yup from 'yup';

const ResetPasswordForm = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const { userForgotPassword } = useHttpApi();
  const { throwErrorMessage } = useAppState();

  const { enqueueSnackbar } = useSnackbar();

  const ResetPasswordSchem = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required')
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: ResetPasswordSchem,
    onSubmit: async (data, { resetForm }) => {
      try {
        setSubmitting(true);
        console.log(data);
        await userForgotPassword(data);
        enqueueSnackbar('Password reset link sent to your email', {
          variant: 'success'
        });
        setSubmitting(false);
        resetForm();
      } catch (e: any) {
        console.log(e);
        console.log(e?.response?.data?.email[0].toString());
        throwErrorMessage(e);
        setSubmitting(false);
        resetForm();
      }
    }
  });

  const { getFieldProps } = formik;

  return (
    <>
      <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
        <div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
          {/* <!--begin::Wrapper--> */}
          <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
            {/* <!--begin::Form--> */}
            <FormikProvider value={formik}>
              <Form
                className="form w-100"
                noValidate
                id="kt_password_reset_form"
                data-kt-redirect-url="index.html"
                action="#"
                onSubmit={formik.handleSubmit}
              >
                {/* <!--begin::Heading--> */}
                <div className="text-center mb-10">
                  {/* <!--begin::Title--> */}
                  <h1 className="text-dark fw-bolder mb-3">Forgot Password ?</h1>
                  {/* <!--end::Title--> */}
                  {/* <!--begin::Link--> */}
                  <div className="text-gray-500 fw-semibold fs-6">
                    Enter your email to reset your password.
                  </div>
                  {/* <!--end::Link--> */}
                </div>
                {/* <!--begin::Heading--> */}
                {/* <!--begin::Input group=--> */}
                <div className="fv-row mb-8">
                  {/* <!--begin::Email--> */}
                  <input
                    type="text"
                    placeholder="Email"
                    {...getFieldProps('email')}
                    autoComplete="off"
                    className="form-control bg-transparent"
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                      {formik.errors.email}
                    </div>
                  ) : null}
                  {/* <!--end::Email--> */}
                </div>
                {/* <!--begin::Actions--> */}
                <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="btn btn-primary btn-active-light-primary me-2"
                  >
                    {submitting && (
                      <span
                        className="spinner-border spinner-border-sm mx-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    {submitting ? 'Submitting...' : 'Submit'}
                  </button>
                  <Link to="/" className="btn btn-light">
                    Cancel
                  </Link>
                </div>
                {/* <FocusError /> */}
                {/* <!--end::Actions--> */}
              </Form>
            </FormikProvider>
            {/* <!--end::Form--> */}
          </div>
          {/* <!--end::Wrapper--> */}
          {/* <!--begin::Footer--> */}
          <div className="text-center text-muted px-lg-10">
            &copy; 2023 XDC Trade Network - All Rights Reserved.
          </div>
          {/* <!--end::Footer--> */}
        </div>
      </div>
    </>
  );
};

export default ResetPasswordForm;
