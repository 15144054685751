import { parseToBnDecimalValue, parseTokenDecimal, parseTokenValue } from 'helpers/numbers';
import { enqueueSnackbar } from 'notistack';
import TransferOwnership from 'pages/trader/TransferOwnership';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTradeState, useWeb3State, useAppState, useHttpApi } from 'state';
import { FundingRequestList } from 'types/tradeTypes';
import resourcePersistence from 'persistence/resourcePersistence';
import { titleCase } from 'helpers/common';
import Web3 from 'web3';

interface ApprovedFundingCardProps {
  fundingRequests: FundingRequestList[];
  title: string;
  owner: string;
  payee: string;
}

interface ButtonProps {
  disable: boolean;
}

interface AllowanceProps {
  paymentTokenAddress: string;
  amount: number;
  decimal: number;
}

export interface TransferOwnershipProps {
  titleEscrowContractAddress?: string;
  payeeAddress: string | undefined;
  escrowId: number | null;
  ownerTransferredOwnership: boolean | undefined;
  fundingRequestId?: string;
  owner: string;
  payee?: string;
}

interface SwapProps {
  fundingRequestId: string;
  escrowId: number | null;
  payee: string;
}
const SwapButton = ({ disable, escrowId, fundingRequestId, payee }: ButtonProps & SwapProps) => {
  const { throwErrorMessage } = useAppState();
  const { swap } = useWeb3State();
  const { updateFundingRequestStatus } = useHttpApi();
  const { fetchAllApprovedFundingRequests, fetchAllApprovedByBeneficiaryFundingRequests } =
    useTradeState();
  const [isLoading, setIsLoading] = useState(false);

  const handleSwap = async () => {
    setIsLoading(true);
    try {
      const res = await swap(escrowId);
      if (res) {
        const _res = await updateFundingRequestStatus(fundingRequestId, {
          status_name: payee === 'LIQUIDITY PROVIDER' ? 'FUNDED' : 'COMPLETE'
        });
        if (_res) {
          setIsLoading(false);
          enqueueSnackbar('Swap Completed!', { variant: 'success' });
          if (payee === 'LIQUIDITY PROVIDER') {
            fetchAllApprovedFundingRequests();
          } else {
            fetchAllApprovedByBeneficiaryFundingRequests();
          }
        }
      }
    } catch (e) {
      setIsLoading(false);
      throwErrorMessage(e);
    }
  };
  return (
    <button
      type="button"
      aria-expanded="false"
      className={`btn btn-sm btn-primary ${disable ? 'disabled' : ''}`}
      onClick={handleSwap}
      disabled={isLoading}
    >
      {isLoading && (
        <span
          className="spinner-border spinner-border-sm mx-2"
          role="status"
          aria-hidden="true"
        ></span>
      )}
      {isLoading ? 'Processing' : 'Settle'}
    </button>
  );
};

const AllowanceButton = ({ paymentTokenAddress, amount, decimal }: AllowanceProps) => {
  console.log(
    '🚀 ~ file: ApprovedFundingCard.tsx:82 ~ AllowanceButton ~ amount:',
    typeof amount,
    amount
  );
  const location = useLocation();
  const { throwErrorMessage } = useAppState();
  const { paymentTokenGiveAllowance, erc20BalanceOf } = useWeb3State();
  const { fetchAllApprovedFundingRequests, fetchAllApprovedByBeneficiaryFundingRequests } =
    useTradeState();
  const [isLoading, setIsLoading] = useState(false);
  const giveAllowance = async () => {
    try {
      setIsLoading(true);
      const balance = await erc20BalanceOf(paymentTokenAddress);

      const requiredAmount = parseToBnDecimalValue(amount, decimal);
      const userBalance = Web3.utils.toBN(balance);

      console.log(
        '🚀 ~ file: ApprovedFundingCard.tsx:91 ~ giveAllowance ~ Amount, balance, BnAmount, BnBalance:',
        amount,
        balance,
        requiredAmount.toString(),
        userBalance.toString()
      );

      if (userBalance.lt(requiredAmount)) {
        setIsLoading(false);
        throw new Error("You don't have enough payment tokens to give allowance!");
      }

      const _amount = parseTokenDecimal(amount, decimal);
      console.log(_amount);

      const res = await paymentTokenGiveAllowance(paymentTokenAddress, _amount);
      if (res) {
        // status => funding
        enqueueSnackbar('Allowance given successfully!', { variant: 'success' });
        setIsLoading(false);
        if (location.pathname.startsWith('/liquidity-provider')) {
          fetchAllApprovedFundingRequests();
        } else {
          fetchAllApprovedByBeneficiaryFundingRequests();
        }
      }
      setIsLoading(false);
    } catch (e) {
      throwErrorMessage(e);
      setIsLoading(false);
    }
  };
  return (
    <button
      type="button"
      aria-expanded="false"
      className={`btn btn-sm btn-primary ${isLoading ? 'disabled' : ''}`}
      onClick={giveAllowance}
      disabled={isLoading}
    >
      {isLoading && (
        <span
          className="spinner-border spinner-border-sm mx-2"
          role="status"
          aria-hidden="true"
        ></span>
      )}
      {isLoading ? 'Processing' : 'Allowance'}
    </button>
  );
};

const InitiateTransferButton = ({
  disable,
  titleEscrowContractAddress,
  payeeAddress,
  escrowId,
  ownerTransferredOwnership,
  fundingRequestId,
  owner,
  payee
}: ButtonProps & TransferOwnershipProps) => {
  const { setCurrentTransferOwnership } = useAppState();

  const handleClick = () => {
    setCurrentTransferOwnership({
      disable,
      titleEscrowContractAddress,
      payeeAddress,
      escrowId,
      ownerTransferredOwnership,
      fundingRequestId,
      owner,
      payee
    });

    resourcePersistence.set('fundingRequestID', fundingRequestId);
  };

  return (
    <Link
      to={'transfer-ownership'}
      onClick={handleClick}
      className={`btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px ${
        disable && 'disabled'
      }`}
    >
      <i className="fa fa-arrow-right"></i>
    </Link>
  );
};

const ApprovedFundingCard = ({
  fundingRequests,
  title,
  owner,
  payee
}: ApprovedFundingCardProps) => {
  const location = useLocation();
  const { setFundingRequestID } = useTradeState();
  const { getEscrowConditions } = useWeb3State();
  console.log('check in props', title, owner, payee);
  const [fundingRequestData, setFundingRequestData] = useState<FundingRequestList[]>();
  const statusCheck = (status: boolean | undefined) => {
    if (status === true) {
      return <i className="fas fa-check-circle fs-1" style={{ color: 'green' }}></i>;
    } else {
      return <i className="fas fa-times-circle fs-1" style={{ color: 'red' }}></i>;
    }
  };

  return (
    <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
      <div className="col-xxl-12">
        <div className="card card-flush h-xl-100">
          <div className="card-header pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-dark">{title} </span>
            </h3>
          </div>
          <div className="card-body pt-6">
            <div className="table-responsive">
              <table className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer">
                <thead>
                  <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                    <th className="min-w-200px text-start">Created By</th>
                    <th className="min-w-100px">Invoice No.</th>
                    <th className="min-w-150px">
                      {payee === 'BENEFICIARY' ? 'Settlement Amount' : 'Funding Amount'}
                    </th>
                    <th className="min-w-100px">Tenure</th>
                    <th className="min-w-100px">ROI (%PA)</th>
                    <th className="min-w-100px">Status</th>
                    <th className="min-w-100px">Payment Allowance</th>
                    <th className="min-w-100px">Nomination</th>
                    <th className="min-w-100px text-end">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {fundingRequests.map((fundingRequest: FundingRequestList) => {
                    console.log('yo', fundingRequest);
                    return (
                      <tr key={fundingRequest?.id}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span className="text-gray-800 fw-bold mb-1 fs-6">
                                {fundingRequest?.trade?.exporter?.name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>{fundingRequest?.invoice_number}</td>
                        <td>
                          {payee === 'BENEFICIARY'
                            ? fundingRequest?.amount_to_be_funded + fundingRequest?.accured_interest
                            : fundingRequest?.amount_to_be_funded}
                        </td>
                        <td>{fundingRequest?.tenure}</td>
                        <td>{fundingRequest?.rate_of_interest}</td>
                        <td>{fundingRequest?.status.description}</td>
                        <td>{statusCheck(fundingRequest?.payeeEscrowed)}</td>
                        <td>{statusCheck(fundingRequest?.ownerTransferredOwnership)}</td>
                        <td className="text-end">
                          {(location.pathname === '/trader/funding' && owner === 'EXPORTER') ||
                          (location.pathname === '/liquidity-provider/redeem-funding' &&
                            owner === 'LIQUIDITY PROVIDER') ? (
                            !fundingRequest?.payeeEscrowed &&
                            !fundingRequest?.ownerTransferredOwnership ? (
                              <InitiateTransferButton
                                disable={true}
                                titleEscrowContractAddress={fundingRequest?.title_escrow_contract}
                                payeeAddress={
                                  payee === 'LIQUIDITY PROVIDER'
                                    ? fundingRequest?.liquidity_provider?.address
                                    : fundingRequest?.beneficiary?.wallet
                                }
                                ownerTransferredOwnership={
                                  fundingRequest?.ownerTransferredOwnership
                                }
                                escrowId={
                                  payee === 'LIQUIDITY PROVIDER'
                                    ? fundingRequest?.liquidity_provider_escrow_id
                                    : fundingRequest?.beneficiary_escrow_id
                                }
                                fundingRequestId={fundingRequest?.id}
                                payee={
                                  payee === 'LIQUIDITY PROVIDER'
                                    ? 'LIQUIDITY PROVIDER'
                                    : 'BENEFICIARY'
                                }
                                owner={owner === 'EXPORTER' ? 'EXPORTER' : 'LIQUIDITY PROVIDER'}
                              />
                            ) : !fundingRequest?.ownerTransferredOwnership ? (
                              <InitiateTransferButton
                                disable={false}
                                titleEscrowContractAddress={fundingRequest?.title_escrow_contract}
                                payeeAddress={
                                  payee === 'LIQUIDITY PROVIDER'
                                    ? fundingRequest?.liquidity_provider?.address
                                    : fundingRequest?.beneficiary?.wallet
                                }
                                ownerTransferredOwnership={
                                  fundingRequest?.ownerTransferredOwnership
                                }
                                escrowId={
                                  payee === 'LIQUIDITY PROVIDER'
                                    ? fundingRequest?.liquidity_provider_escrow_id
                                    : fundingRequest?.beneficiary_escrow_id
                                }
                                fundingRequestId={fundingRequest?.id}
                                payee={
                                  payee === 'LIQUIDITY PROVIDER'
                                    ? 'LIQUIDITY PROVIDER'
                                    : 'BENEFICIARY'
                                }
                                owner={owner === 'EXPORTER' ? 'EXPORTER' : 'LIQUIDITY PROVIDER'}
                              />
                            ) : (
                              <SwapButton
                                disable={
                                  fundingRequest?.payeeEscrowed &&
                                  fundingRequest?.ownerTransferredOwnership
                                    ? false
                                    : true
                                }
                                fundingRequestId={fundingRequest.id}
                                escrowId={
                                  payee === 'LIQUIDITY PROVIDER'
                                    ? fundingRequest?.liquidity_provider_escrow_id
                                    : fundingRequest?.beneficiary_escrow_id
                                }
                                payee={payee}
                              />
                            )
                          ) : !fundingRequest?.payeeEscrowed ? (
                            <AllowanceButton
                              paymentTokenAddress={fundingRequest?.payment_token?.address}
                              amount={
                                payee === 'BENEFICIARY'
                                  ? fundingRequest?.settlement_leg === 'STABLE_COIN'
                                    ? fundingRequest?.amount_to_be_funded +
                                      fundingRequest?.accured_interest
                                    : 0
                                  : fundingRequest?.settlement_leg === 'STABLE_COIN'
                                  ? fundingRequest?.amount_to_be_funded
                                  : 0
                              }
                              decimal={fundingRequest?.payment_token?.decimals}
                            />
                          ) : fundingRequest?.payeeEscrowed &&
                            !fundingRequest?.ownerTransferredOwnership ? (
                            <SwapButton
                              disable={
                                fundingRequest?.payeeEscrowed &&
                                fundingRequest?.ownerTransferredOwnership
                                  ? false
                                  : true
                              }
                              fundingRequestId={fundingRequest.id}
                              escrowId={
                                payee === 'LIQUIDITY PROVIDER'
                                  ? fundingRequest?.liquidity_provider_escrow_id
                                  : fundingRequest?.beneficiary_escrow_id
                              }
                              payee={payee}
                            />
                          ) : (
                            fundingRequest?.payeeEscrowed &&
                            fundingRequest?.ownerTransferredOwnership && (
                              <SwapButton
                                disable={
                                  fundingRequest?.payeeEscrowed &&
                                  fundingRequest?.ownerTransferredOwnership
                                    ? false
                                    : true
                                }
                                fundingRequestId={fundingRequest.id}
                                escrowId={
                                  payee === 'LIQUIDITY PROVIDER'
                                    ? fundingRequest?.liquidity_provider_escrow_id
                                    : fundingRequest?.beneficiary_escrow_id
                                }
                                payee={payee}
                              />
                            )
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovedFundingCard;
