import { AppStateProvider, useAppState } from './useAppState';
import React from 'react';
import { AuthStateSharedProvider } from './useAuthStateShared';
import { HttpApiProvider } from './useHttpApi';
import { AuthStateProvider } from './useAuthState';
import { Web3StateProvider } from './useWeb3';
import { CoreTablesProvider } from './useCoreTableState';
import { TradeStateProvider } from './useTradeState';
import { magicStateProvider } from './useMagicState';
import { Magic } from 'magic-sdk';

interface Props {
  children: React.ReactNode;
}

type ProviderComponent = React.FC<Props>;

const GlobalStateProvider: React.FC<Props> = ({ children }) => {
  const Providers: ProviderComponent[] = [
    magicStateProvider,
    HttpApiProvider,
    AppStateProvider,
    Web3StateProvider,
    AuthStateSharedProvider,
    AuthStateProvider,
    CoreTablesProvider,
    TradeStateProvider
  ];

  let element: React.ReactNode = children;

  while (Providers.length > 0) {
    const Component = Providers.pop();
    if (Component) {
      element = <Component>{element}</Component>;
    }
  }

  return <>{element}</>;
};

export { useAppState } from './useAppState';
export { useWeb3State } from './useWeb3';
export { useAuthStateShared } from './useAuthStateShared';
export { useHttpApi } from './useHttpApi';
export { useAuthState } from './useAuthState';
export { useCoreTableState } from './useCoreTableState';
export { useTradeState } from './useTradeState';
export { useMagicState } from './useMagicState';

export default GlobalStateProvider;
