/**
 * Renders a table displaying accepted user data and action to view profile.
 * @param {object} data - The data to populate the table.
 * @returns {React.Element} - The rendered component.
 */

import { ethToXdcAddress } from 'helpers/web3';
import { PaymentTokenProps } from 'types/coreTablesTypes';

interface AllPaymentTokenTableProps {
  data: PaymentTokenProps[];
}

const PaymentTokenTable = ({ data }: AllPaymentTokenTableProps) => {
  return (
    <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
      <div className="col-xxl-12">
        <div className="card card-flush h-xl-100">
          <div className="card-body pt-6">
            <div className="table-responsive">
              <table className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer">
                <thead>
                  <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                    <th className="min-w-250px text-start">Name</th>
                    <th className="min-w-250px">Symbol</th>
                    <th className="min-w-250px">Address</th>
                    <th className="min-w-250px">Pegged Currency</th>
                  </tr>
                </thead>

                <tbody>
                  {data?.map((item: PaymentTokenProps, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span className="text-gray-800 fw-bold mb-1 fs-6">{item?.name}</span>
                            </div>
                          </div>
                        </td>
                        <td>{item.symbol}</td>
                        <td>{ethToXdcAddress(item.address)}</td>
                        <td>{item.pegged_currency}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentTokenTable;
