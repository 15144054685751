/**
 * View payment token added by admin. If not added, prompts with Add Button.
 * @returns {React.Element} - The rendered component.
 */

import React, { useState } from 'react';
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useAppState, useCoreTableState, useHttpApi, useWeb3State } from 'state';
import { ethToXdcAddress, toChecksumAddress, xdcToEthAddress } from 'helpers/web3';
import { xdcRegex } from 'helpers/regex';
import { CurrenciesProps, PaymentTokenProps } from 'types/coreTablesTypes';
import PaymentTokenTable from 'components/admin/PaymentTokenTable';
import { useSnackbar } from 'notistack';
import FocusError from 'components/FocusError';

const ViewStableCoin = () => {
  const { getERC20Name, getERC20Symbol, getERC20Decimals } = useWeb3State();

  const [paymentToken, setPaymentToken] = useState<string | null>();

  const { currencies, allPaymentTokens, fetchAllPaymentTokens } = useCoreTableState();
  const { addPaymentToken } = useHttpApi();

  const { throwErrorMessage } = useAppState();

  const { enqueueSnackbar } = useSnackbar();

  const documentSchema = Yup.object().shape({
    address: Yup.string().required('Payment Token Address is required'),
    symbol: Yup.string().required('Symbol is required'),
    name: Yup.string().required('Name is required'),
    decimals: Yup.number().required('Decimals is required'),
    pegged_currency: Yup.string().required('Pegged Currency is required')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
      symbol: '',
      decimals: 0,
      pegged_currency: ''
    },
    validationSchema: documentSchema,
    onSubmit: async (data: PaymentTokenProps, { resetForm }) => {
      try {
        if (data) {
          console.log(data);

          data.address = toChecksumAddress(xdcToEthAddress(data.address));

          await addPaymentToken(data);
          setPaymentToken(null);
          resetForm();
          await fetchAllPaymentTokens();
          enqueueSnackbar('Payment Token Added Successfully', { variant: 'success' });
        } else {
          // console.log('Invalid XDC Address');
          throwErrorMessage('Invalid XDC Address');
        }
      } catch (error) {
        console.log(error);
        resetForm();
        setPaymentToken(null);
        throwErrorMessage(error);
      }
    }
  });

  const { getFieldProps, handleSubmit } = formik;

  return (
    <div id="kt_app_content" className="app-content flex-column-fluid">
      <div id="kt_app_content_container" className="app-container container-fluid">
        <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
          <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
            <div className="col-xxl-12">
              <div className="card card-flush h-xl-100">
                <div className="card-header pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark">Payment Token</span>
                  </h3>
                  <div className="card-toolbar">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#new-funding"
                      className="btn btn-sm btn-primary"
                    >
                      + Add
                    </a>
                  </div>
                </div>
                <div className="card-body pt-6">
                  <PaymentTokenTable data={allPaymentTokens} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*    Modal Details */}
      <div className="modal fade" tabIndex={-1} id="new-funding">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Add Payment Token</h3>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ki-duotone ki-cross fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </div>
            </div>
            <div className="modal-body px-12 pb-12">
              <div className="row mb-8">
                <div className="col-10 mb-8">
                  <label className="mb-2">Add Payment Token Address</label>
                  <input
                    type="text"
                    id="paymentToken"
                    onChange={(e) => {
                      setPaymentToken(e.target.value);
                    }}
                    value={paymentToken ?? ''}
                    autoComplete="off"
                    className="form-control bg-transparent"
                  />
                </div>
                <div className="col-2 mb-8">
                  <button
                    className="btn btn-primary mt-7"
                    onClick={async () => {
                      try {
                        console.log(paymentToken);
                        if (paymentToken?.match(xdcRegex)) {
                          console.log(paymentToken);
                          let _address = xdcToEthAddress(paymentToken);
                          _address = toChecksumAddress(_address);
                          const erc20Name = await getERC20Name(_address);
                          if (erc20Name) {
                            formik.setFieldValue('name', erc20Name);
                            formik.setFieldValue('address', ethToXdcAddress(_address));
                          }
                          const erc20Symbol = await getERC20Symbol(_address);
                          if (erc20Symbol) {
                            formik.setFieldValue('symbol', erc20Symbol);
                          }
                          const erc20Decimals = await getERC20Decimals(_address);
                          if (erc20Decimals) {
                            formik.setFieldValue('decimals', erc20Decimals);
                          }
                        } else {
                          enqueueSnackbar('Invalid XDC Address', { variant: 'error' });
                          console.log('Invalid XDC Address');
                        }
                      } catch (e) {
                        throwErrorMessage(e);
                        console.log(e);
                      }
                    }}
                  >
                    Fetch
                  </button>
                </div>
              </div>
              <FormikProvider value={formik}>
                <Form className="form" onSubmit={handleSubmit}>
                  <div className="row mb-8">
                    <div className="col-12 mb-8">
                      <label className="mb-2">Payment Token Address</label>
                      <input
                        type="text"
                        id="address"
                        {...getFieldProps('address')}
                        autoComplete="off"
                        className="form-control bg-transparent"
                        readOnly
                      />
                      {formik.touched.address && formik.errors.address ? (
                        <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                          {formik.errors.address}*
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-8">
                    <div className="col-4 mb-8">
                      <label className="mb-2">Symbol</label>
                      <input
                        type="text"
                        id="symbol"
                        {...getFieldProps('symbol')}
                        autoComplete="off"
                        className="form-control bg-transparent"
                        readOnly
                      />
                      {formik.touched.symbol && formik.errors.symbol ? (
                        <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                          {formik.errors.symbol}*
                        </div>
                      ) : null}
                    </div>
                    <div className="col-8 mb-8">
                      <label className="mb-2">Name</label>
                      <input
                        type="text"
                        id="name"
                        {...getFieldProps('name')}
                        autoComplete="off"
                        className="form-control bg-transparent"
                        readOnly
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                          {formik.errors.name}*
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-8">
                    <div className="col-6 mb-8">
                      <label className="mb-2">Decimals</label>
                      <input
                        type="number"
                        id="decimals"
                        {...getFieldProps('decimals')}
                        autoComplete="off"
                        className="form-control bg-transparent"
                        readOnly
                      />
                      {formik.touched.decimals && formik.errors.decimals ? (
                        <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                          {formik.errors.decimals}*
                        </div>
                      ) : null}
                    </div>
                    <div className="col-6 mb-8">
                      <label className="mb-2">Pegged Currency</label>
                      <select
                        className="form-select form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        id="pegged_currency"
                        {...getFieldProps('pegged_currency')}
                      >
                        <option hidden selected>
                          Select
                        </option>
                        {currencies.map((currency: CurrenciesProps) => {
                          return (
                            <option key={currency.code} value={currency.code}>
                              {currency.code} - {currency.name}
                            </option>
                          );
                        })}
                      </select>
                      {formik.touched.pegged_currency && formik.errors.pegged_currency ? (
                        <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                          {formik.errors.pegged_currency}*
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                      Cancel
                    </button>
                    {formik.getFieldProps('decimals').value &&
                    formik.getFieldProps('address').value &&
                    formik.getFieldProps('symbol').value &&
                    formik.getFieldProps('pegged_currency').value &&
                    formik.getFieldProps('name').value ? (
                      <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">
                        Submit
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-primary disabled">
                        Submit
                      </button>
                    )}
                  </div>
                  <FocusError />
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewStableCoin;
