export const xdcRegex = /^0x[a-fA-F0-9]{40}$|^xdc[a-fA-F0-9]{40}$/;

export function fixUrl(url: string) {
  // Remove extra slashes using a regular expression
  const fixedUrl = url.replace(/\/+/g, '/');
  return fixedUrl;
}

export const DNSRegex =
  /^(?=.{1,253}$)(?:(?!-|[^.]+_)[A-Za-z0-9-]{1,63}(?<!-)\.){1,}[A-Za-z]{2,15}$/;

export const EmailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9]+[.]+[a-zA-Z-.]{1,}$/;
