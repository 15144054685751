import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import constate from 'constate';
import { LOGIN_URL } from 'config';
import { userTokenPersistence } from 'persistence';
import { useWeb3State } from './useWeb3';

const useAuthStateShared_ = () => {
  const [user, setUser] = useState<any | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const navigate = useNavigate();

  const { disconnectWallet, account } = useWeb3State();
  console.log('🚀 ~ account:', account);

  useEffect(() => {
    if (isReady && !isAuthorized) {
      navigate(LOGIN_URL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, isAuthorized]);

  const setLoggedOutState = useCallback(async () => {
    localStorage.clear();
    setUser(null);
    disconnectWallet();
    setIsAuthorized(false);
  }, []);

  return {
    user,
    setUser,
    setLoggedOutState,
    setIsReady,
    setIsAuthorized,
    isReady,
    isAuthorized
  };
};

export const [AuthStateSharedProvider, useAuthStateShared, useSetLoggedOutState, useUser] =
  constate(
    useAuthStateShared_,
    (state) => state,
    ({ setLoggedOutState }) => setLoggedOutState,
    ({ user }) => user
  );
