import CardField from 'components/common/CardField';
import { statusDocCheck, titleCase } from 'helpers/common';
import React, { useEffect } from 'react';
import {
  useAppState,
  useAuthState,
  useCoreTableState,
  useHttpApi,
  useTradeState,
  useWeb3State
} from 'state';
// import UploadFileModal from './UploadFileModal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { API_URL, DOCUTRADE_VERIFY_URL, IS_DEV } from 'config';
import { PaymentToken } from 'types/tradeTypes';
import { parseTokenDecimal } from 'helpers/numbers';
import { useSnackbar } from 'notistack';
import resourcePersistence from 'persistence/resourcePersistence';
import { fixUrl } from 'helpers/regex';
import { ethToXdcAddress, xdcToEthAddress } from 'helpers/web3';

const ViewFundingDetails = () => {
  const modalRef = React.useRef<HTMLDivElement>(null);
  const { fundingRequestID, fetchFundingRequestDetailsByID, fundingRequestsDetails } =
    useTradeState();
  const { approveFundingRequest } = useWeb3State();
  const { paymentTokenByCurrency, fetchPaymentTokenByCurrency, fetchCashPaymentTokenByCurrency } =
    useCoreTableState();
  const { user } = useAuthState();
  const navigate = useNavigate();
  const { approveFundingRequestLP, approveFundingRequestBeneficiary } = useHttpApi();
  const [closeModal, setCloseModal] = React.useState<boolean>(false);
  const [paymentToken, setPaymentToken] = React.useState<PaymentToken | null>(null);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();

  const location = useLocation();

  useEffect(() => {
    const fRID = resourcePersistence.get('fundingRequestID');
    if (fRID) {
      fetchFundingRequestDetailsByID(fRID);
    }
  }, [fundingRequestID]);

  useEffect(() => {
    if (fundingRequestsDetails?.settlement_leg === 'STABLE_COIN') {
      fetchPaymentTokenByCurrency(fundingRequestsDetails.funding_currency.code);
      if (fundingRequestsDetails?.payment_token) {
        setPaymentToken(fundingRequestsDetails.payment_token);
      }
    } else {
      fetchCashPaymentTokenByCurrency();
      if (fundingRequestsDetails?.payment_token) {
        setPaymentToken(fundingRequestsDetails.payment_token);
      }
    }
  }, [fundingRequestsDetails]);

  // console.log('fundingRequestsDetails', fundingRequestsDetails);

  const approveFunding = async () => {
    try {
      setSubmitting(true);
      const amountToParse =
        location.pathname === '/liquidity-provider/view-funding'
          ? fundingRequestsDetails?.amount_to_be_funded
          : (fundingRequestsDetails?.amount_to_be_funded || 0) +
            (fundingRequestsDetails?.accured_interest || 0);
      const _paymentToken =
        fundingRequestsDetails?.settlement_leg === 'STABLE_COIN'
          ? paymentToken
          : paymentTokenByCurrency[0];
      if (!_paymentToken) {
        throw new Error('Payment Token not found');
      }
      const paymentTokenAmount =
        fundingRequestsDetails?.settlement_leg === 'STABLE_COIN'
          ? parseTokenDecimal(amountToParse, _paymentToken?.decimals)
          : 0;
      const paymentTokenDestination =
        location.pathname === '/liquidity-provider/view-funding'
          ? fundingRequestsDetails?.trade?.exporter?.wallet
          : fundingRequestsDetails?.liquidity_provider?.address;

      const res = await approveFundingRequest(
        user?.user?.wallet?.address,
        fundingRequestsDetails?.title_escrow_contract,
        _paymentToken?.address,
        paymentTokenAmount,
        paymentTokenDestination
      );
      const orderId = res.events[0].returnValues.orderId;
      let djangoRes: {
        message: string;
      };
      if (location.pathname === '/liquidity-provider/view-funding') {
        djangoRes = await approveFundingRequestLP(fundingRequestsDetails?.id || '', {
          liquidity_provider_escrow_id: orderId,
          payment_token: _paymentToken?.id
        });
      } else {
        djangoRes = await approveFundingRequestBeneficiary(fundingRequestsDetails?.id || '', {
          beneficiary_escrow_id: orderId
        });
      }
      console.log('djangoRes', djangoRes);
      enqueueSnackbar(djangoRes?.message || 'Funding Requset Approved Successfully', {
        variant: 'success'
      });
      setSubmitting(false);
      fetchFundingRequestDetailsByID(fundingRequestsDetails?.id || '');
      // Hide the modal using bootstrap data-bs functions using ref.
      modalRef?.current?.click();
      if (location.pathname === '/liquidity-provider/view-funding') {
        navigate('/liquidity-provider/settlement');
      } else {
        // for imports section
        navigate(-1);
      }
    } catch (e: any) {
      console.log(e);
      // enqueueSnackbar(e?.message, { variant: 'error' });
      throwErrorMessage(e);
      setSubmitting(false);
    }
  };

  interface DocumentFieldProps {
    doc_name: string;
    status: string;
    file: string;
  }

  const DocumentField = ({ doc_name, status, file }: DocumentFieldProps) => {
    return (
      <tr>
        <td>
          <div className="d-flex align-items-center">
            <div className="d-flex justify-content-start flex-column">
              <span className="text-gray-800 fw-bold mb-1 fs-6">{titleCase(doc_name)}</span>
            </div>
          </div>
        </td>
        {/* <td>
          <span className={`${statusDocCheck(status)}`}>{status}</span>
        </td> */}
        <td className="text-end">
          <button
            type="button"
            onClick={() => {
              console.log(`${API_URL}${file.substring(1)}`);
              // window.open(IS_DEV ? `${API_URL}${file.substring(1)}` : file, '_blank'); // Only if S3 bucket is integrated with Django
              window.open(`${API_URL}${file.substring(1)}`);
            }}
            className="border border-0 px-4 bg-transparent "
            rel="noreferrer"
          >
            <i
              className="bi bi-file-earmark-arrow-down-fill text-dark"
              style={{ fontSize: '25px' }}
            ></i>
          </button>
          {/* <span className="badge py-3 px-4 fs-7 badge-light-primary">
            <a
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#reject-document"
              className="badge-light-danger text-primary text-hover-dark fw-semibold fs-6"
            >
              Reject
            </a>
          </span> */}
          {/* <UploadFileModal title={'Upload file'} submitButtonText={'upload'} /> */}
        </td>
      </tr>
    );
  };

  return (
    <div>
      <div className="py-4">
        <div className="mb-5 py-2">
          <h1 className="card-title ms-3">Funding Request</h1>
        </div>
        {fundingRequestsDetails ? (
          <CardField
            trade_name={fundingRequestsDetails?.trade?.trade_name ?? 'NA'}
            exporter={fundingRequestsDetails?.trade?.exporter.name ?? 'NA'}
            beneficiary={fundingRequestsDetails?.beneficiary?.name ?? 'NA'}
            rate_of_interest={fundingRequestsDetails?.rate_of_interest ?? 'NA'}
            commodity={
              fundingRequestsDetails?.trade?.commodity
                ? titleCase(fundingRequestsDetails?.trade?.commodity)
                : 'NA'
            }
            tenure={fundingRequestsDetails?.tenure ?? 'NA'}
            value={`${fundingRequestsDetails?.amount_to_be_funded ?? 'NA'} ${
              fundingRequestsDetails?.funding_currency.code ?? 'NA'
            }`}
            settlementValue={`${
              fundingRequestsDetails?.amount_to_be_funded + fundingRequestsDetails?.accured_interest
            } ${fundingRequestsDetails?.funding_currency.code}`}
            from_country={fundingRequestsDetails?.trade?.from_country ?? 'NA'}
            to_country={fundingRequestsDetails?.trade?.to_country ?? 'NA'}
            status={titleCase(fundingRequestsDetails?.status.name ?? 'NA')}
            liquidityProvider={fundingRequestsDetails?.liquidity_provider?.name ?? 'NA'}
            shipmentDate={fundingRequestsDetails?.trade?.date_of_shipment ?? 'NA'}
            settlement={fundingRequestsDetails?.settlement_leg ?? 'NA'}
          />
        ) : null}
      </div>
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="col-xxl-12">
          <div className="card">
            <div className="card-header d-flex align-items-center">
              <h1 className="card-title">Trade Documents</h1>
              <a
                href={DOCUTRADE_VERIFY_URL}
                className="text-dark btn btn-sm-primary"
                target="_blank"
                rel="noreferrer"
              >
                Verify Docs <i className="fa fa-external-link"></i>
              </a>
            </div>
            {/* <!--begin::Body--> */}
            <div className="card-body pt-6">
              {/* <!--begin::Table container--> */}
              <div className="table-responsive">
                {/* <!--begin::Table--> */}
                <table className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer">
                  {/* <!--begin::Table head--> */}
                  <thead>
                    <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                      <th className="min-w-125px text-start">DOCUMENT NAME</th>
                      {/* <th className="min-w-100px text-start">STATUS</th> */}
                      <th className="min-w-100px text-end">ACTIONS</th>
                    </tr>
                  </thead>
                  {/* <!--end::Table head--> */}
                  {/* <!--begin::Table body--> */}
                  <tbody>
                    {fundingRequestsDetails?.documents.map((item: any, index) => {
                      return (
                        <DocumentField
                          key={index}
                          doc_name={item.file_type}
                          status={item.status}
                          file={item.file}
                        />
                      );
                    })}
                  </tbody>
                  {/* <!--end::Table body--> */}
                </table>
              </div>
              {/* <!--end::Table--> */}
            </div>
            {/* <!--end::Body--> */}
          </div>
          {/* <!--end::Card--> */}
          <div className="card-footer text-end mt-4 d-flex justify-content-end">
            <button
              type="button"
              onClick={() => {
                navigate(-1);
              }}
              className="btn btn-light btn-active-light-primary me-2"
            >
              Back
            </button>
            {location.pathname === '/liquidity-provider/view-funding' && (
              <>
                {fundingRequestsDetails?.has_liquidity_provider_approved ? (
                  <>
                    {fundingRequestsDetails?.status.name !== 'FUNDED' &&
                      fundingRequestsDetails?.status.name !== 'COMPLETE' && (
                        <button type="button" disabled={true} className="btn btn-primary">
                          Approved
                        </button>
                      )}
                  </>
                ) : fundingRequestsDetails?.settlement_leg === 'STABLE_COIN' ? (
                  // opens modal on approve when settlement_leg is stablecoin
                  <Link to="#">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#new-funding"
                      className="btn btn-primary"
                    >
                      Approve
                    </a>
                  </Link>
                ) : (
                  // doesn't open modal and selects the first paymentToken  from paymentTokenCurrencyList
                  // WARNING: the only it will break is if paymentTokenCurrencyList is empty
                  <button className="btn btn-primary" onClick={approveFunding}>
                    {submitting && (
                      <span
                        className="spinner-border spinner-border-sm mx-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    {submitting ? 'Submitting...' : 'Approve'}
                  </button>
                )}
              </>
            )}

            {location.pathname === '/trader/imports/view-funding' && (
              <>
                {fundingRequestsDetails?.has_beneficiary_approved ? (
                  <>
                    {fundingRequestsDetails?.status.name !== 'FUNDED' &&
                      fundingRequestsDetails?.status.name !== 'COMPLETE' && (
                        <button type="button" disabled={true} className="btn btn-primary">
                          Approved
                        </button>
                      )}
                  </>
                ) : (
                  <div>
                    {user?.entity?.name === fundingRequestsDetails?.beneficiary?.name &&
                      (fundingRequestsDetails?.settlement_leg === 'STABLE_COIN' ? (
                        // opens modal on approve when settlement_leg is stablecoin
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#new-funding"
                          // className="btn btn-primary"
                          className={`btn  ${
                            !fundingRequestsDetails?.can_beneficiary_approve ? 'disabled' : ''
                          }
                          ${
                            !fundingRequestsDetails?.can_beneficiary_approve
                              ? 'btn-secondary'
                              : 'btn-primary'
                          } 
                          `}
                        >
                          Approve
                        </a>
                      ) : (
                        // doesn't open modal and selects the first paymentToken  from paymentTokenCurrencyList
                        // WARNING: the only it will break is if paymentTokenCurrencyList is empty
                        <button
                          className={`btn  ${
                            !fundingRequestsDetails?.can_beneficiary_approve ? 'disabled' : ''
                          }
                          ${
                            !fundingRequestsDetails?.can_beneficiary_approve
                              ? 'btn-secondary'
                              : 'btn-primary'
                          } 
                          `}
                          onClick={approveFunding}
                        >
                          {submitting && (
                            <span
                              className="spinner-border spinner-border-sm mx-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          {submitting ? 'Submitting...' : 'Approve'}
                        </button>
                      ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* <!--end::Col--> */}
      </div>
      {/* Modal Code with a dropdown to take payment token input */}
      {/* <!--begin::Modal - New Card--> */}
      <div
        className="modal fade"
        tabIndex={-1}
        id="new-funding"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">
                {location.pathname === '/liquidity-provider/view-funding'
                  ? 'Liquidity Provider'
                  : 'Beneficiary'}{' '}
                Approve
              </h3>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                ref={modalRef}
                aria-label="Close"
              >
                <i className="ki-duotone ki-cross fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </div>
            </div>
            <div className="modal-body px-12 pb-12">
              <div className="fv-row mb-10">
                <label className="fs-5 fw-bold form-label mb-2">Select Payment Token</label>
                <select
                  className="form-select form-select-solid"
                  aria-label="Default select example"
                  disabled={location.pathname === '/liquidity-provider/view-funding' ? false : true}
                  value={JSON.stringify(paymentToken)}
                  onChange={(e) => {
                    setPaymentToken(JSON.parse(e.target.value));
                  }}
                >
                  <option value="">Select Payment Token</option>
                  {paymentTokenByCurrency?.map((item, index) => {
                    return (
                      <option key={index} value={JSON.stringify(item)}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="row mb-3">
                <label>
                  <b>Current EBL Owner Name: </b>
                  {location.pathname === '/liquidity-provider/view-funding'
                    ? fundingRequestsDetails?.trade?.exporter?.name
                    : fundingRequestsDetails?.liquidity_provider?.name}
                </label>
              </div>
              <div className="row mb-3">
                <label>
                  <b>New EBL Owner Name: </b>
                  {user?.entity?.name}
                </label>
              </div>
              <div className="row mb-3">
                <button
                  type="button"
                  disabled={!paymentToken}
                  className="btn btn-primary btn-active-light-primary me-2"
                  onClick={approveFunding}
                >
                  {submitting && (
                    <span
                      className="spinner-border spinner-border-sm mx-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  {submitting ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--end::Card--> */}
    </div>
  );
};

export default ViewFundingDetails;
