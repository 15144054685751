import { Outlet, useNavigate } from 'react-router-dom';
import Footer from './main/Footer';
import Navbar from './main/Navbar';
import { useAppState, useAuthState } from 'state';
import Sidebar from 'components/core/Sidebar';
import { getSidebarLinks } from 'utils/sidebarLinks';
import { useEffect } from 'react';
import RequireUserWalletOverlay from 'components/core/RequireUserWalletOverlay';
import WalletRequireOverlay from 'components/core/WalletRequireOverlay';

const NormalLayout = () => {
  const { isExpanded } = useAppState();
  const { user } = useAuthState();
  const navigate = useNavigate();

  return (
    <div
      id="kt_app_body"
      data-kt-app-header-fixed="true"
      className="app-default d-flex flex-column min-vh-100"
    >
      {/* Header */}
      <div id="kt_app_header" className="app-header">
        <Navbar />
      </div>

      {/* Wrapper */}
      <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
        {/* MainContent */}
        <WalletRequireOverlay />

        <Outlet />

        {/* Footer */}
        <Footer />
      </div>
    </div>
  );
};

export default NormalLayout;
