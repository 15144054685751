import constate from 'constate';
// import { SafeAppConnector, useSafeAppConnection } from '@gnosis.pm/safe-apps-web3-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  WEB3_STATUS,
  currentNetwork,
  readOnlyWeb3,
  sendTransaction,
  // toChecksumAddress,
  transformProviderFromXinfin
} from 'helpers/web3';
import {
  // InjectedConnector,
  NoEthereumProviderError
} from '@yodaplus/injected-connector';
// import { selectedWalletPersistence } from 'persistence';
import ERC20 from '@yodaplus/dapps-lib/contracts/ERC20.json';
import TitleEscrow from '@yodaplus/dapps-lib/contracts/TitleEscrow.json';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { network, signer } from 'types/global';
import { ChainId } from 'helpers/chainInfo';

import { OAuthExtension } from '@magic-ext/oauth';
import { AuthExtension } from '@magic-ext/auth';
import { Magic as MagicBase } from 'magic-sdk';
import { MAGIC_LINK_KEY } from 'config';
import { useAppState } from './useAppState';
import { useSnackbar } from 'notistack';
import resourceJsonPersistence from 'persistence/resourceJsonPersistence';
import { useMagicState } from './useMagicState';
import { userTokenPersistence } from 'persistence';

export type Magic = MagicBase<AuthExtension & OAuthExtension[]>;

const POLL_INTERVAL = 1000;

const useWeb3 = () => {
  const [status, setStatus] = useState(WEB3_STATUS.UNKNOWN);
  const [balance, setBalance] = useState('0');
  const [walletWeb3, setwalletWeb3] = useState<any>(null);
  const [account, setAccount] = useState<string | null>(null);
  const [active, setActive] = useState<boolean>(false);
  const [signer, setSigner] = useState<signer>(null);
  const [network, setNetwork] = useState<network>(null);
  const [ethersChainId, setEthersChainId] = useState<ChainId>();

  // const [magic, setMagic] = useState<Magic | null>(null);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [web3Connecting, setWeb3Connecting] = useState<boolean>(false);
  const [ethersProvider, setEthersProvider] = useState<ethers.providers.Web3Provider | null>(null);
  const [gasPrice, setGasPrice] = useState(null);

  // const [web3, setWeb3] = useState<any>(readOnlyWeb3);

  const { enqueueSnackbar } = useSnackbar();
  const { throwErrorMessage, setOtpVerificationOngoing, setConnectedAccount } = useAppState();

  const { magic, setMagic, initializeMagicSDK, disconnectMagic } = useMagicState();

  const web3 = walletWeb3 ?? readOnlyWeb3;

  if (!web3) {
    throw new Error('web3 must be available at this point');
  }
  const { ethereum } = window as any;

  // create an ethers provider
  // let ethersProvider: ethers.providers.Web3Provider | undefined | null = undefined;

  useEffect(() => {
    resourceJsonPersistence.set('currentNetwork', currentNetwork);
    const magicSDK = initializeMagicSDK();
    setMagic(magicSDK);

    const provider = new ethers.providers.Web3Provider((magicSDK as any).rpcProvider);
    // Set Gas Price for all transactions

    setEthersProvider(provider);
    // ethersProvider = provider;

    // const networkRPC = getChainInfoByChainId(currentNetwork).rpcUrl;
    const networkRPC = currentNetwork.rpcUrl;
    const _web3 = new Web3(transformProviderFromXinfin(magicSDK.rpcProvider));
    setwalletWeb3(_web3);
    // setWeb3(_web3);
  }, [currentNetwork, account]);

  const signerAndNetwork = async () => {
    // await ethersProvider?.send('eth_requestAccounts', []);
    const signer_ = await ethersProvider?.getSigner();
    setSigner(signer_);
    const network = await ethersProvider?.getNetwork();
    setNetwork(network);
    const chainId_ = network?.chainId;
    setEthersChainId(chainId_);
    return {
      signer: signer_,
      network: network
    };
  };

  const [emailOtpHandler, setEmailOtpHandler] = useState<any>(null);

  const initiateMagicLogin = (email: string, ref?: any, setSubmitting?: any, inputRefs?: any) => {
    const _emailOtpHandler = magic?.auth.loginWithEmailOTP({
      email: email,
      showUI: false
    });

    _emailOtpHandler
      ?.on('email-otp-sent', () => {
        console.log('email-otp-sent');
        // The email has been sent to the user

        // Prompt the user for the OTP
        ref.current.click();
        console.log('inputRefs', inputRefs);
        setTimeout(() => {
          if (inputRefs[0].current) {
            console.log('inputRefs[0].current', inputRefs[0].current);
            inputRefs[0].current?.focus();
          }
        }, 1000);
        setSubmitting(false);

        // Send the OTP for verification
        // _emailOtpHandler.emit('verify-email-otp', otp);
      })
      .on('error', (reason: any) => {
        // is called if the Promise rejects
        console.error('REASEDDDON', reason);
        setOtpVerificationOngoing(false);
      });
    console.log('emailOtpHandler', _emailOtpHandler);
    setEmailOtpHandler(_emailOtpHandler);
    return _emailOtpHandler;
  };

  const connectWallet = async (
    email: string,
    ref?: any,
    setSubmitting?: any,
    inputRefs?: any
  ): Promise<void> => {
    // Check if magic is defined
    if (!magic) {
      console.error('Magic SDK is not initialized.');
      return;
    }

    try {
      // Check if the user is already authenticated
      const isLoggedInResult = await magic.user.isLoggedIn();
      console.log('isLoggedInResult0', await magic.user.isLoggedIn());

      console.log('isLoggedInResult1', isLoggedInResult);

      if (typeof isLoggedInResult === 'boolean') {
        // isLoggedInResult is a boolean value, indicating the authentication status
        if (isLoggedInResult) {
          // If the user is already logged in, logout the user
          await magic.user.logout();
          console.log('LOGOUT', await magic.user.logout());
        }

        initiateMagicLogin(email, ref, setSubmitting, inputRefs);
        setStatus(WEB3_STATUS.READY);

        // After logout or if the user was not logged in, initiate the login process using magic link
      } else {
        // Handle the case where isLoggedInResult is not a boolean (e.g., it might be an object)
        console.error('Unexpected result received from magic.user.isLoggedIn():', isLoggedInResult);
        setSubmitting(false);
      }
    } catch (error) {
      console.error('Error checking authentication status or logging out:', error);
      setSubmitting(false);
    }
  };

  const verifyOtp = useCallback(
    async (otp: string, ref: any, count: any) => {
      try {
        console.log('verifyOtp', otp);
        emailOtpHandler?.emit('verify-email-otp', otp);
        emailOtpHandler?.on('invalid-email-otp', () => {
          enqueueSnackbar('Invalid OTP', { variant: 'error', preventDuplicate: true });
          setOtpVerificationOngoing(false);
          if (count === 2) {
            emailOtpHandler?.emit('cancel');
            ref?.current?.click();
            enqueueSnackbar('You have exceeded the maximum number of tries', {
              variant: 'error'
            });
          }
        });
        emailOtpHandler?.on('done', async () => {
          const userDetails = await magic?.user.getInfo();
          console.log('🚀 ~ emailOtpHandler?.on ~ userDetails:', userDetails);
          const balance = await ethersProvider?.getBalance(userDetails?.publicAddress ?? '');
          setBalance(balance ? ethers.utils.formatEther(balance) : '');
          localStorage.setItem('token', JSON.stringify(userDetails?.publicAddress));
          setUserDetails({ email: userDetails?.email ?? '' });
          setActive(true);
          setAccount(userDetails?.publicAddress ? userDetails?.publicAddress : '');
          setConnectedAccount(userDetails?.publicAddress ? userDetails?.publicAddress : '');
          setWeb3Connecting(false);
          ref?.current?.click();
          setOtpVerificationOngoing(false);
          setStatus(WEB3_STATUS.READY);
          // console.log('BALAMCED', balance?.toString());
          // const balanceOf = weiToEth(balance?.toString());
          // console.log('BLANCEOF', balanceOf);

          // if (
          //   parseFloat(balanceOf) < 0.1 &&
          //   ChainInfo[chainId as ChainId] != ChainInfo[ChainId.StabilityTestnet as ChainId] &&
          //   ChainInfo[chainId as ChainId] != ChainInfo[ChainId.Stability as ChainId]
          // ) {
          //   enqueueSnackbar(
          //     'Low balance: Transaction risk. Ensure minimum balance of 0.1 is maintained, Please contact Admin',
          //     {
          //       variant: 'warning'
          //     }
          //   );
          // }
        });
        emailOtpHandler?.on('error', (reason: any) => {
          // is called if the Promise rejects
          console.error('REASEDDDON', reason);
          setOtpVerificationOngoing(false);
        });
      } catch (error) {
        console.log('error', error);
        throwErrorMessage(error);
        setOtpVerificationOngoing(false);
      }
    },
    [emailOtpHandler, magic]
  );

  const disconnectWallet = async () => {
    try {
      disconnectMagic();
      setActive(false);
      setAccount('');
      setUserDetails(null);
    } catch (err) {
      console.error('Disconnect error:', err);
    }
  };

  useEffect(() => {
    signerAndNetwork();
  }, [account, magic]);

  useEffect(() => {
    const token = userTokenPersistence.get();
    try {
      const fetchdata = async () => {
        if (magic && token) {
          const isLoggedIn = await magic?.user.isLoggedIn();
          // console.log('isLoggedIn', isLoggedIn);
          if (isLoggedIn) {
            const userDetails = await magic?.user.getInfo();
            localStorage.setItem('token', JSON.stringify(userDetails?.publicAddress));
            setUserDetails({ email: userDetails?.email ?? '' });
            setActive(true);
            setAccount(userDetails?.publicAddress ? userDetails?.publicAddress : '');
            setConnectedAccount(userDetails?.publicAddress ? userDetails?.publicAddress : '');
            setWeb3Connecting(false);
          }
        }
      };
      fetchdata();
    } catch (error) {
      console.error('Error fetching data, might Logged OUT:', error);
    }
  }, [magic]);

  useEffect(() => {
    const fetchGasPrice = async () => {
      try {
        const currentGasPrice = await web3.eth.getGasPrice();
        setGasPrice(currentGasPrice);
      } catch (error) {
        console.error('Error fetching gas price:', error);
      }
    };

    fetchGasPrice();
  }, [web3]);

  const erc20Contract = useMemo(
    () =>
      new web3.eth.Contract(ERC20.abi, {
        from: account,
        gasPrice: gasPrice
        // gasPrice: 1 * 10 ** 9
      }),
    [web3, account, gasPrice]
  );

  const TitleEscrowContract = useMemo(
    () =>
      new web3.eth.Contract(TitleEscrow.abi, {
        from: account,
        gasPrice: gasPrice
        // gasPrice: 1 * 10 ** 9
      }),
    [web3, account, gasPrice]
  );

  const EscrowManagerContract = useMemo(() => {
    return new web3.eth.Contract(
      currentNetwork.EscrowManagerABI,
      currentNetwork.EscrowMangerAddress,
      {
        from: account,
        gasPrice: gasPrice
        // gasPrice: 1 * 10 ** 9
      }
    );
  }, [web3, account, gasPrice]);

  const wrapContractCall = useCallback(
    (func) => {
      return (...args: any) => {
        if (!erc20Contract) {
          throw new Error('Smart contract is not available');
        }

        return func(...args);
      };
    },
    [erc20Contract]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const approveFundingRequest = useCallback(
    wrapContractCall(
      (
        newEBLOwner: string,
        titleEscrowContract: string,
        paymentToken: string,
        paymentTokenAmount: number,
        paymentTokenDestination: string
      ) => {
        return sendTransaction(
          web3,
          EscrowManagerContract.methods[
            'approveFundingRequest(address,address,address,uint256,address)'
          ](
            newEBLOwner,
            titleEscrowContract,
            paymentToken,
            paymentTokenAmount,
            paymentTokenDestination
          )
        );
      }
    ),
    [wrapContractCall, web3, EscrowManagerContract]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkAllowance = useCallback(
    wrapContractCall(
      (
        newEBLOwner: string,
        titleEscrowContract: string,
        paymentToken: string,
        paymentTokenAmount: number,
        paymentTokenDestination: string,
        status: string
      ) => {
        return EscrowManagerContract.methods
          .checkAllowance({
            newEBLOwner,
            titleEscrowContract,
            paymentToken,
            paymentTokenAmount,
            paymentTokenDestination,
            status
          })
          .call();
      }
    ),
    [wrapContractCall, web3, EscrowManagerContract]
  );
  const checkHolder = useCallback(
    wrapContractCall(
      (
        newEBLOwner: string,
        titleEscrowContract: string,
        paymentToken: string,
        paymentTokenAmount: number,
        paymentTokenDestination: string,
        status: string
      ) => {
        return EscrowManagerContract.methods
          .checkHolder({
            newEBLOwner,
            titleEscrowContract,
            paymentToken,
            paymentTokenAmount,
            paymentTokenDestination,
            status
          })
          .call();
      }
    ),
    [wrapContractCall, web3, EscrowManagerContract]
  );
  const checkNominee = useCallback(
    wrapContractCall(
      (
        newEBLOwner: string,
        titleEscrowContract: string,
        paymentToken: string,
        paymentTokenAmount: number,
        paymentTokenDestination: string,
        status: string
      ) => {
        return EscrowManagerContract.methods
          .checkNominee({
            newEBLOwner,
            titleEscrowContract,
            paymentToken,
            paymentTokenAmount,
            paymentTokenDestination,
            status
          })
          .call();
      }
    ),
    [wrapContractCall, web3, EscrowManagerContract]
  );
  const checkBeneficiary = useCallback(
    wrapContractCall(
      (
        newEBLOwner: string,
        titleEscrowContract: string,
        paymentToken: string,
        paymentTokenAmount: number,
        paymentTokenDestination: string,
        status: string
      ) => {
        return EscrowManagerContract.methods
          .checkBeneficiary({
            newEBLOwner,
            titleEscrowContract,
            paymentToken,
            paymentTokenAmount,
            paymentTokenDestination,
            status
          })
          .call();
      }
    ),
    [wrapContractCall, web3, EscrowManagerContract]
  );
  const checkEscrowConditions = useCallback(
    wrapContractCall((orderId: number) => {
      return EscrowManagerContract.methods.checkEscrowConditions(orderId).call();
    }),
    [wrapContractCall, web3, EscrowManagerContract]
  );

  const getEscrowConditions = useCallback(
    wrapContractCall((orderId: number) => {
      return EscrowManagerContract.methods.getEscrowConditions(orderId).call();
    }),
    [wrapContractCall, web3, EscrowManagerContract]
  );

  const swap = useCallback(
    wrapContractCall((orderId: number) => {
      return sendTransaction(web3, EscrowManagerContract.methods.swap(orderId));
    }),
    [wrapContractCall, web3, EscrowManagerContract]
  );

  const cancelFundingRequest = useCallback(
    wrapContractCall((orderId: number) => {
      return sendTransaction(web3, EscrowManagerContract.methods.cancelFundingRequest(orderId));
    }),
    [wrapContractCall, web3, EscrowManagerContract]
  );

  // ERC20 token methods

  const getERC20Name = useCallback(
    wrapContractCall((tokenAddress: string) => {
      erc20Contract.options.address = tokenAddress;
      return erc20Contract.methods.name().call();
    }),
    [wrapContractCall, web3, erc20Contract]
  );

  const getERC20Symbol = useCallback(
    wrapContractCall((tokenAddress: string) => {
      erc20Contract.options.address = tokenAddress;
      return erc20Contract.methods.symbol().call();
    }),
    [wrapContractCall, web3, erc20Contract]
  );

  const getERC20Decimals = useCallback(
    wrapContractCall((tokenAddress: string) => {
      erc20Contract.options.address = tokenAddress;
      return erc20Contract.methods.decimals().call();
    }),
    [wrapContractCall, web3, erc20Contract]
  );
  const erc20BalanceOf = useCallback(
    wrapContractCall((tokenAddress: string) => {
      erc20Contract.options.address = tokenAddress;
      return erc20Contract.methods.balanceOf(account).call();
    }),
    [wrapContractCall, web3, erc20Contract]
  );
  const paymentTokenGiveAllowance = useCallback(
    wrapContractCall((tokenAddress: string, value: string) => {
      erc20Contract.options.address = tokenAddress;
      return sendTransaction(
        web3,
        erc20Contract.methods.approve(currentNetwork.EscrowMangerAddress, value)
      );
    }),
    [wrapContractCall, web3, erc20Contract]
  );
  /**
   * * Title Escrow Contract methods *
   * TODO: Add methods for Title Escrow Contract
   * ! #1: Add method to nominate a new beneficiary
   * ! #2: Add method to transfer holdership
   */

  // nomiantion of new holder
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const nominateOwner = useCallback(
    wrapContractCall((titleEscrowAddress: string, newOwner: string) => {
      TitleEscrowContract.options.address = titleEscrowAddress;
      return sendTransaction(web3, TitleEscrowContract.methods.nominate(newOwner));
    }),
    [wrapContractCall, web3, TitleEscrowContract]
  );

  // transfer of holdership
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const transferHoldership = useCallback(
    wrapContractCall((titleEscrowAddress: string, newHolder: string) => {
      console.log('check', titleEscrowAddress, newHolder);
      TitleEscrowContract.options.address = titleEscrowAddress;
      return sendTransaction(web3, TitleEscrowContract.methods.transferHolder(newHolder));
    }),
    [wrapContractCall, web3, TitleEscrowContract]
  );

  // useEffect(() => {
  //   if (!web3 || !account) {
  //     setBalance('0');
  //     return;
  //   }

  //   let timerId: any = null;
  //   let canceled = false;

  //   const poll = async () => {
  //     timerId = null;

  //     try {
  //       const balance_ = await web3.eth.getBalance(account);

  //       if (!canceled) {
  //         setBalance(balance_);
  //       }
  //     } catch (e) {
  //       console.warn(`Something is wrong when polling for account balance: ${e}`);
  //     }

  //     if (!canceled) {
  //       timerId = setTimeout(poll, POLL_INTERVAL);
  //     }
  //   };

  //   poll();

  //   return () => {
  //     if (timerId) {
  //       clearTimeout(timerId);
  //     }

  //     canceled = true;
  //   };
  // }, [web3, account]);

  return {
    // isMultisig,
    status,
    active,
    account,
    // chainId,
    web3,
    balance,
    signer,
    network,
    ethersChainId,
    connectWallet,
    disconnectWallet,
    approveFundingRequest,
    checkAllowance,
    checkHolder,
    checkNominee,
    checkBeneficiary,
    checkEscrowConditions,
    getEscrowConditions,
    swap,
    cancelFundingRequest,
    getERC20Name,
    getERC20Symbol,
    getERC20Decimals,
    erc20BalanceOf,
    paymentTokenGiveAllowance,
    // Title Escrow Contract methods
    nominateOwner,
    transferHoldership,
    verifyOtp,
    web3Connecting
  };
};

export const [Web3StateProvider, useWeb3State] = constate(useWeb3);
