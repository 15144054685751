import constate from 'constate';
import { useEffect, useState } from 'react';
import { useHttpApi } from 'state/useHttpApi';
import { FundingRequestList } from 'types/tradeTypes';
import { useWeb3State } from './useWeb3';
const useTradeState_ = () => {
  const {
    getAllFundingRequest,
    getFundingRequestByID,
    getPendingSettlementRequests,
    getAllApprovedFundingRequests,
    getAllApprovedByBeneficiaryFundingRequests,
    getFundingRequestHistory
  } = useHttpApi();

  const { getEscrowConditions } = useWeb3State();

  const [pendingSettlementsRequests, setPendingSettlementRequests] = useState<FundingRequestList[]>(
    []
  );
  const [fundingRequestID, setFundingRequestID] = useState<string>('');
  const [fundingRequests, setFundingRequests] = useState<FundingRequestList[]>([]);
  const [fundingRequestsDetails, setFundingRequestsDetails] = useState<FundingRequestList>();
  const [approvedFundingRequests, setApprovedFundingRequests] = useState<FundingRequestList[]>();
  const [approvedByBeneficiaryFundingRequests, setApprovedByBeneficiaryFundingRequests] =
    useState<FundingRequestList[]>();
  const [fundingRequestHistory, setFundingRequestHistory] = useState<FundingRequestList[]>();
  const fetchFundingRequests = async () => {
    const _res = await getAllFundingRequest();
    setFundingRequests(_res);
  };

  const fetchPendingSettlementRequests = async () => {
    const _res = await getPendingSettlementRequests();
    setPendingSettlementRequests(_res);
  };

  const fetchFundingRequestDetailsByID = async (id: string) => {
    const _res = await getFundingRequestByID(id);
    setFundingRequestsDetails(_res);
  };

  const fetchAllApprovedFundingRequests = async () => {
    const _res = await getAllApprovedFundingRequests();
    const _data = await Promise.all(
      _res.map(async (item: FundingRequestList) => {
        const result = await getEscrowConditions(item.liquidity_provider_escrow_id);
        item.payeeEscrowed = result[0];
        item.ownerTransferredOwnership = result[2];
        return item; // Return the updated item after processing
      })
    );
    setApprovedFundingRequests(_data);
  };

  const fetchAllApprovedByBeneficiaryFundingRequests = async () => {
    const _res = await getAllApprovedByBeneficiaryFundingRequests();
    const _data = await Promise.all(
      _res.map(async (item: FundingRequestList) => {
        const result = await getEscrowConditions(item.beneficiary_escrow_id);
        item.payeeEscrowed = result[0];
        item.ownerTransferredOwnership = result[2];
        return item; // Return the updated item after processing
      })
    );
    setApprovedByBeneficiaryFundingRequests(_data);
  };

  const fetchFundingRequestHistory = async () => {
    const _res = await getFundingRequestHistory();
    setFundingRequestHistory(_res);
  };
  return {
    pendingSettlementsRequests,
    fetchPendingSettlementRequests,
    fundingRequests,
    fetchFundingRequests,
    fundingRequestID,
    setFundingRequestID,
    fundingRequestsDetails,
    fetchFundingRequestDetailsByID,
    fetchAllApprovedFundingRequests,
    approvedFundingRequests,
    fetchAllApprovedByBeneficiaryFundingRequests,
    approvedByBeneficiaryFundingRequests,
    fetchFundingRequestHistory,
    fundingRequestHistory
  };
};

export const [TradeStateProvider, useTradeState] = constate(useTradeState_);
