import React from 'react';
import logo from '../../assets/media/logos/logo.svg';
import { Link } from 'react-router-dom';
import SignUpForm from 'components/authentication/SignUpForm';

const SignUp = (): JSX.Element => {
  return (
    <div
      id="kt_body"
      className="app-blank bgi-size-cover bgi-attachment-fixed bgi-position-center bgi-no-repeat"
    >
      <div className="d-flex flex-column flex-root" id="kt_app_root">
        <div className="d-flex flex-column flex-column-fluid flex-lg-row">
          <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
            <div className="d-flex flex-center flex-lg-start flex-column">
              <a href="index.html" className="mb-7">
                <img
                  alt="XDC Trade Network"
                  title="XDC Trade Network"
                  src={logo}
                  className="h-50px"
                />
              </a>

              <h2 className="fw-normal m-0">
                One-stop solution for Trade Document
                <br />
                Digitization and Liquidity for your Trades
              </h2>
            </div>
          </div>

          <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
            <SignUpForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
