import ApprovedFundingCard from 'components/common/ApprovedFundingCard';
import React, { useEffect } from 'react';
import { useTradeState } from 'state';

const LiquidityFunding = () => {
  const { fetchAllApprovedFundingRequests, approvedFundingRequests } = useTradeState();
  useEffect(() => {
    fetchAllApprovedFundingRequests();
  }, []);
  return (
    <div id="kt_app_content" className="app-content flex-column-fluid pt-5 mt-5">
      {/* <!--begin::Content container--> */}
      <div id="kt_app_content_container" className="app-container container-fluid">
        {/* <!--begin::Row--> */}
        <ApprovedFundingCard
          fundingRequests={approvedFundingRequests || []}
          owner={'EXPORTER'}
          payee={'LIQUIDITY PROVIDER'}
          title={'Approved Funding Requests'}
        />
      </div>
    </div>
  );
};

export default LiquidityFunding;
