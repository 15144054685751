/**
 * Renders a page displaying the profile data for a user.
 * @returns {React.Element} - The rendered component.
 */

import { titleCase } from 'helpers/common';
import { ethToXdcAddress } from 'helpers/web3';
import { useEffect } from 'react';
import { useAppState } from 'state';

const ViewUserProfile = () => {
  const { entityUser, loadCurrentEntityUserId } = useAppState();

  useEffect(() => {
    loadCurrentEntityUserId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="kt_app_content" className="app-content flex-column-fluid">
      <div id="kt_app_content_container" className="app-container container-fluid">
        <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
          <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
            <div className="col-xxl-12">
              <div className="card card-flush h-xl-100">
                <div className="card-header pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark">{entityUser?.user.name}</span>
                  </h3>
                </div>

                <div className="card-body pt-6">
                  <div className="row">
                    <div className="col-6 mb-8">
                      <label className="mb-2">Company Name</label>
                      <p className="text-muted fs-6 fw-bold">{entityUser?.entity.name}</p>
                    </div>
                    <div className="col-6 mb-8">
                      <label className="mb-2">Company Email</label>
                      <p className="text-muted  fs-6 fw-bold">{entityUser?.entity.email}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 mb-8">
                      <label className="mb-2">Xinfin Address</label>
                      <div className="text-muted  fs-6 fw-bold">
                        {entityUser?.user?.wallet?.address &&
                          ethToXdcAddress(entityUser?.user.wallet.address)}
                      </div>
                    </div>
                    <div className="col-6 mb-8">
                      <label className="mb-2">Phone Number</label>
                      <p className="text-muted  fs-6 fw-bold">{entityUser?.entity.phone_number}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 mb-8">
                      <label className="mb-2">Entity Type</label>
                      <p className="text-muted  fs-6 fw-bold">
                        {entityUser?.entity.entity_type.name}
                      </p>
                    </div>
                    <div className="col-6 mb-8">
                      <label className="mb-2">Role</label>
                      <div className="text-muted  fs-6 fw-bold">
                        {titleCase(`${entityUser?.user.role.title}`)}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 mb-8">
                      <label className="mb-2">Identifier Type</label>
                      <p className="text-muted  fs-6 fw-bold">
                        {entityUser?.entity.identifier_type.name}
                      </p>
                    </div>
                    <div className="col-6 mb-8">
                      <label className="mb-2">Identifier</label>
                      <p className="text-muted  fs-6 fw-bold">{entityUser?.entity.identifier}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 mb-8">
                      <label className="mb-2">Address Line 1</label>
                      <p className="text-muted  fs-6 fw-bold">
                        {entityUser?.entity.address_line_1}
                      </p>
                    </div>
                    <div className="col-6 mb-8">
                      <label className="mb-2">Address Line 2</label>
                      <p className="text-muted  fs-6 fw-bold">
                        {entityUser?.entity.address_line_2}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 mb-8">
                      <label className="mb-2">Country</label>
                      <p className="text-muted  fs-6 fw-bold">
                        {entityUser?.entity?.country?.name}
                      </p>
                    </div>
                    <div className="col-6 mb-8">
                      <label className="mb-2">State</label>
                      <p className="text-muted  fs-6 fw-bold">{entityUser?.entity.state}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 mb-8">
                      <label className="mb-2">City</label>
                      <p className="text-muted  fs-6 fw-bold">{entityUser?.entity.city}</p>
                    </div>
                    <div className="col-6 mb-8">
                      <label className="mb-2">Zipcode</label>
                      <p className="text-muted  fs-6 fw-bold">{entityUser?.entity.zipcode}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUserProfile;
