import FocusError from 'components/FocusError';
import { titleCase } from 'helpers/common';
import { ethToXdcAddress } from 'helpers/web3';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppState, useAuthStateShared } from 'state';
// import logo from '../../assets/media/logos/logo.svg';
// import { useNavigate } from 'react-router-dom';
import { ProfileFormProps } from 'types/profileTypes';
import RegistryForm from './RegistryForm';
import ProfileForm from './ProfileForm';

const ProfilePage = () => {
  //   const navigate = useNavigate();
  const { user } = useAuthStateShared();
  const navigate = useNavigate();
  const { traderRole } = useAppState();
  const { fetchRegistryByEntityId, entityRegistryDetails } = useAppState();

  useEffect(() => {
    fetchRegistryByEntityId(user?.entity?.id);
  }, []);

  return (
    <div id="kt_app_content" className="mt-5 pt-5 app-content flex-column-fluid">
      {/*begin::Content container*/}
      <div id="kt_app_content_container" className="app-container container-fluid">
        {/*begin::Card*/}
        <ProfileForm
          name={user?.user.name}
          entity_type={
            titleCase(user?.user?.role?.title) ?? titleCase(user?.entity?.entity_type?.name)
          }
          user_email={user?.user?.email ?? 'NA'}
          company_name={user?.entity?.name ?? 'NA'}
          company_email={user?.entity?.email ?? 'NA'}
          xinfin_address={user?.user?.wallet?.address ?? 'NA'}
          phone_number={user?.entity?.phone_number ?? 'NA'}
          identifier_type={user?.entity?.identifier_type?.name ?? 'NA'}
          identifier={user?.entity?.identifier ?? 'NA'}
          address_line_1={user?.entity?.address_line_1 ?? 'NA'}
          address_line_2={user?.entity?.address_line_2 ?? 'NA'}
          country={user?.entity?.country?.name ?? 'NA'}
          state={user?.entity?.state ?? 'NA'}
          city={user?.entity?.city ?? 'NA'}
          zipcode={user?.entity?.zipcode ?? 'NA'}
          registry={entityRegistryDetails ?? null}
        />
      </div>
    </div>
  );
};

export default ProfilePage;
