import RegistrationForm from 'components/authentication/RegistrationForm';
import Footer from 'layouts/main/Footer';
import Navbar from 'layouts/main/Navbar';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAuthState, useHttpApi } from 'state';

const Registration = () => {
  const { user } = useAuthState();

  return (
    <div className="d-flex flex-column flex-column-fluid flex-lg-row">
      <div className="d-flex flex-column-fluid justify-content-center p-12 p-lg-20">
        <div className="bg-body d-flex flex-column align-items-stretch flex-center w-md-1000px rounded-4 p-20">
          <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
            {!user?.entity?.id && <RegistrationForm />}
            {user?.entity && !user?.entity?.is_approved && (
              <h1 className="text-center">
                Your request for Entity creation has been received. <br />
                You will receive a notification mail once the request is processed!
              </h1>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
