import ResetPassword from 'pages/authentication/ResetPassword';
import AdminDashboard from 'pages/admin/AdminDashboard';
import AllUsers from 'pages/admin/AllUsers';
import ViewStableCoin from 'pages/admin/ViewStableCoin';
import ViewUserProfile from 'pages/admin/ViewUserProfile';
import Login from 'pages/authentication/Login';
import Registration from 'pages/authentication/Registration';
import SignUp from 'pages/authentication/SignUp';
import LiquidityDashboard from 'pages/liquidityProvider/LiquidityDashboard';
import LiquidityFunding from 'pages/liquidityProvider/LiquidityFunding';
import LiquidityMatchingRules from 'pages/liquidityProvider/LiquidityMatchingRules';
import LiquidityViewFunding from 'pages/liquidityProvider/LiquidityViewFunding';
import TraderDashboard from 'pages/trader/TraderDashboard';
import TraderFunding from 'pages/trader/TraderFunding';
import TraderImports from 'pages/trader/TraderImports';
import { useRoutes } from 'react-router-dom';
import AuthRequired from 'components/AuthRequired';
import MainLayout from 'layouts/MainLayout';
import PageNotFound from 'pages/PageNotFound';
import ImporterFundingDetails from 'pages/trader/ImporterFundingDetails';
import ProfilePage from 'components/common/ProfilePage';
import TransferOwnership from 'pages/trader/TransferOwnership';
import NormalLayout from 'layouts/NormalLayout';
import TraderHistory from 'pages/trader/TraderHistory';
import FundingRequestForm from 'pages/trader/FundingRequestForm';
import ChangePasswordPage from 'pages/authentication/ChangePasswordPage';
import RedeemFunding from 'pages/liquidityProvider/RedeemFunding';
import ChangeOldPaswwordForm from 'components/authentication/ChangeOldPaswwordForm';
import ViewFunding from 'pages/common/ViewFunding';

const Router = (): any => {
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthRequired>
          <MainLayout />
        </AuthRequired>
      ),

      children: [
        {
          path: '/profile',
          element: <ProfilePage />
        },
        {
          path: '/history',
          element: <TraderHistory />
        },
        {
          path: '/trader',
          children: [
            { path: '/trader', element: <TraderFunding /> },
            { path: '/trader/imports', element: <TraderImports /> },
            { path: '/trader/imports/view-funding', element: <ViewFunding /> },
            { path: '/trader/history/view-funding', element: <ViewFunding /> },
            {
              path: '/trader/view-funding',
              element: <ViewFunding />
            },
            {
              path: '/trader/create-funding-request',
              element: <FundingRequestForm />
            },
            {
              path: '/trader/funding',
              children: [{ path: '/trader/funding', element: <TraderDashboard /> }]
            },
            {
              path: '/trader/funding/transfer-ownership',
              element: <TransferOwnership />
            },
            {
              path: '/trader/change-password',
              element: <ChangeOldPaswwordForm />
            }
          ]
        },
        {
          path: '/liquidity-provider',
          children: [
            { path: '/liquidity-provider', element: <LiquidityDashboard /> },
            { path: '/liquidity-provider/settlement', element: <LiquidityFunding /> },
            {
              path: '/liquidity-provider/redeem-funding',
              children: [
                { path: '/liquidity-provider/redeem-funding', element: <RedeemFunding /> },
                {
                  path: '/liquidity-provider/redeem-funding/transfer-ownership',
                  element: <TransferOwnership />
                }
              ]
            },
            { path: '/liquidity-provider/matching-rules', element: <LiquidityMatchingRules /> },
            { path: '/liquidity-provider/view-funding', element: <ViewFunding /> },
            { path: '/liquidity-provider/history/view-funding', element: <ViewFunding /> },
            {
              path: '/liquidity-provider/change-password',
              element: <ChangeOldPaswwordForm />
            }
          ]
        },
        {
          path: '/admin',
          children: [
            {
              path: '/admin',
              element: <AdminDashboard />
            },
            {
              path: '/admin/view-payment-token',
              element: <ViewStableCoin />
            },
            {
              path: '/admin/view-users',
              element: <AllUsers />
            },
            {
              path: '/admin/view-user-profile',
              element: <ViewUserProfile />
            }
          ]
        }
      ]
    },
    { path: '/login', element: <Login /> },
    { path: '/sign-up', element: <SignUp /> },

    {
      path: '/register',
      element: (
        <AuthRequired>
          <NormalLayout />
        </AuthRequired>
      ),
      children: [
        {
          path: '/register',
          element: <Registration />
        }
      ]
    },
    { path: '/reset-password', element: <ResetPassword /> },
    { path: '/change-password/:token', element: <ChangePasswordPage /> },
    { path: '*', element: <PageNotFound /> }
  ]);
};

export default Router;
