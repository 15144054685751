import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, Formik, ErrorMessage } from 'formik';
import EntityDetails from 'components/carrier/EntityDetails';
import { doesNotMatch } from 'assert';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { Registry } from 'types/profileTypes';
import { useAppState, useHttpApi } from 'state';
import { DNSRegex, xdcRegex } from 'helpers/regex';
import { toChecksumAddress, xdcToEthAddress } from 'helpers/web3';

const RegistryForm = ({
  entity,
  verifiable_dns,
  verifiable_contract_address,
  transferable_contract_address,
  transferable_dns
}: Registry) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { createOrUpdateRegistryByEntityId } = useHttpApi();
  const { fetchRegistryByEntityId } = useAppState();
  const [isValueReset, setIsValueReset] = useState(false);

  const RegistrySchema = Yup.object().shape({
    verifiable_dns: Yup.string()
      .test(
        'no-whitespace',
        'DNS cannot be empty value',
        (value: any) => value && value.trim().length > 0
      )
      .matches(DNSRegex, 'Invalid DNS format'),
    verifiable_contract_address: Yup.string()
      .test(
        'no-whitespace',
        'Contract Address cannot be empty value',
        (value: any) => value && value.trim().length > 0
      )
      .matches(xdcRegex, 'Invalid contract address'), //TODO: add contract address validation
    transferable_dns: Yup.string()
      .test(
        'no-whitespace',
        'DNS cannot be empty value',
        (value: any) => value && value.trim().length > 0
      )
      .matches(DNSRegex, 'Invalid DNS format'),
    transferable_contract_address: Yup.string()
      .test(
        'no-whitespace',
        'Contract Address cannot be empty value',
        (value: any) => value && value.trim().length > 0
      )
      .matches(xdcRegex, 'Invalid contract address')
  });
  const formik = useFormik({
    initialValues: {
      entity: entity,
      verifiable_dns: verifiable_dns,
      verifiable_contract_address: verifiable_contract_address,
      transferable_dns: transferable_dns,
      transferable_contract_address: transferable_contract_address
    },
    validationSchema: RegistrySchema,
    validate: (values) => {
      const errors: any = {};
      if (values.verifiable_contract_address && !values.verifiable_dns) {
        errors.verifiable_dns = 'Enter DNS';
      } else if (!values.verifiable_contract_address && values.verifiable_dns) {
        errors.verifiable_contract_address = 'Enter Contract Address';
      }

      if (values.transferable_contract_address && !values.transferable_dns) {
        errors.transferable_dns = 'Enter DNS';
      } else if (!values.transferable_contract_address && values.transferable_dns) {
        errors.transferable_contract_address = 'Enter Contract Address';
      }
      return errors;
    },
    onSubmit: async (data: Registry) => {
      try {
        setSubmitting(true);
        if (
          !(
            (data.verifiable_contract_address && !data.verifiable_dns) ||
            (!data.verifiable_contract_address && data.verifiable_dns) ||
            (data.transferable_contract_address && !data.transferable_dns) ||
            (!data.transferable_contract_address && data.transferable_dns)
          )
        ) {
          data.verifiable_contract_address = toChecksumAddress(
            xdcToEthAddress(data.verifiable_contract_address)
          );
          data.transferable_contract_address = toChecksumAddress(
            xdcToEthAddress(data.transferable_contract_address)
          );
          const res = await createOrUpdateRegistryByEntityId(data);
          if (res) {
            if (isValueReset) {
              enqueueSnackbar('Registry Details Reset!', { variant: 'success' });
              setIsValueReset(false);
            } else {
              enqueueSnackbar('Registry Details Set!', { variant: 'success' });
            }
            await fetchRegistryByEntityId(data.entity);
            resetForm({ values: data });
          }

          setSubmitting(false);
        }
      } catch (e: any) {
        // TODO: reset fields
        setSubmitting(false);
        // TODO: set back to initial values, if errors thrown or return the new values
        if (e?.response?.data?.title === 'TRANSFERABLE') {
          formik.setFieldError('transferable_dns', 'Use a different DNS');
          formik.setFieldError('transferable_contract_address', 'Use a different contract address');
        } else if (e?.response?.data?.title === 'VERIFIABLE') {
          formik.setFieldError('verifiable_dns', 'Use a different DNS');
          formik.setFieldError('verifiable_contract_address', 'Use a different contract address');
        }

        enqueueSnackbar(e?.response?.data?.message, { variant: 'error' });
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <FormikProvider value={formik}>
      <form className="form w-100" id="kt_sign_up_form" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-4 mb-8">
            <label className="mb-2 mx-4">Document Type</label>
            <input
              type="text"
              autoComplete="off"
              readOnly
              className="form-control form-control-solid"
              id="name"
              value="Verifiable"
            />
          </div>
          <div className="col-4 mb-8 ">
            <label className="mb-2 mx-4">DNS</label>
            <input
              type="text"
              autoComplete="off"
              className="form-control form-control-solid"
              id="verifiable_dns"
              {...getFieldProps('verifiable_dns')}
              placeholder="Enter DNS"
            />
            <ErrorMessage name="verifiable_dns" component="div" className="text-danger" />
          </div>
          <div className="col-4 mb-8">
            <label className="mb-2 mx-4">Contract Address</label>
            <div className="d-flex align-items-center">
              <input
                type="text"
                autoComplete="off"
                className="form-control form-control-solid"
                id="email"
                {...getFieldProps('verifiable_contract_address')}
                maxLength={43}
                placeholder="Enter Contract Address"
              />
              <button
                className=""
                style={{ backgroundColor: 'transparent', border: 'none' }}
                type="submit"
                onClick={() => {
                  setIsValueReset(true);
                  formik.setFieldValue('verifiable_dns', '');
                  formik.setFieldValue('verifiable_contract_address', '');
                }}
              >
                <i className="fa fa-trash" style={{ fontSize: '20px' }}></i>
              </button>
            </div>

            <ErrorMessage
              name="verifiable_contract_address"
              component="div"
              className="text-danger"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 mb-8">
            <input
              type="text"
              autoComplete="off"
              readOnly
              className="form-control form-control-solid"
              id="name"
              value="Transferable"
            />
          </div>
          <div className="col-4 mb-8 ">
            <input
              type="text"
              autoComplete="off"
              className="form-control form-control-solid"
              id="name"
              {...getFieldProps('transferable_dns')}
              placeholder="Enter DNS"
            />
            <ErrorMessage name="transferable_dns" component="div" className="text-danger" />
          </div>
          <div className="col-4 mb-8">
            <div className="d-flex align-items-center">
              <input
                type="text"
                autoComplete="off"
                className="form-control form-control-solid"
                id="email"
                {...getFieldProps('transferable_contract_address')}
                placeholder="Enter Contract Address"
                maxLength={43}
              />
              <button
                className=""
                style={{ backgroundColor: 'transparent', border: 'none' }}
                type="submit"
                onClick={() => {
                  setIsValueReset(true);
                  formik.setFieldValue('transferable_dns', '');
                  formik.setFieldValue('transferable_contract_address', '');
                }}
              >
                <i className="fa fa-trash" style={{ fontSize: '20px' }}></i>
              </button>
            </div>
            <ErrorMessage
              name="transferable_contract_address"
              component="div"
              className="text-danger"
            />
          </div>
        </div>
        {formik.dirty && (
          <div className="justify-content-end d-flex mb-2">
            <button
              type="submit"
              // id="kt_sign_up_submit"
              className="btn btn-primary"
              disabled={submitting || !formik.isValid}
            >
              {submitting && (
                <span
                  className="spinner-border spinner-border-sm mx-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {submitting ? 'Submitting...' : 'Set Registry'}
            </button>
          </div>
        )}
      </form>
    </FormikProvider>
  );
};

export default RegistryForm;
