/**
 * Renders a skeleton for transaction particulars.
 * @param {object} props - The props object.
 * @param {number} props.count - The count to display in the card.
 * @param {string} props.title - The title to display in the card.
 * @param {string} props.imgSrc - The image source for the card.
 * @returns {React.Element} - The rendered component.
 */

import { redirect, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

interface DashboardCardProps {
  count: number;
  title: string;
  imgSrc: string;
  redirectTo: string;
}

const DashboardCard = ({ title, count, imgSrc, redirectTo }: DashboardCardProps) => {
  const navigate = useNavigate();
  return (
    <div className="col-md-3">
      <div className="card">
        <div className="card-body pt-9">
          <div className="d-flex flex-wrap flex-sm-nowrap">
            <div className="me-4">
              <div className="symbol symbol-50px symbol-fixed position-relative">
                <img src={imgSrc} alt="image" />
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap">
                <div className="d-flex flex-column w-100">
                  <div className="d-flex align-items-center mb-0">
                    <h2 className="text-gray-900 text-hover-primary mb-0 fs-1 fw-bold me-1">
                      {count}
                    </h2>
                  </div>
                  <div className="fw-semibold fs-7 pe-2 w-100">
                    <Link to={`${redirectTo}`}>
                      <span className="float-start text-gray-400">{title}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
