import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAppState, useHttpApi } from 'state';
import * as Yup from 'yup';
import logo from '../../assets/media/logos/logo.svg';

const ChangePassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  // const { confirmForgotPassword } = useHttpApi();
  const { token } = useParams();
  const { throwErrorMessage } = useAppState();
  const navigate = useNavigate();
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [error, setError] = React.useState('');
  const { confirmForgotPassword, userVerifyToken } = useHttpApi();

  const handleShowConfirmPassword = () => {
    setShowNewPassword((show) => !show);
  };
  console.log('TOKEN', token);

  const ChangePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('New Password is required')
      .min(8, 'Password must be minimum 8 characters long')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
        'Must Contain atleast one Uppercase, one Lowercase, one Number and one special case Character'
      ),
    confirm_new_password: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Passwords must match')
      .required('Confirm New Password is required')
  });
  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_new_password: '',
      token: token
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async (data, { resetForm }) => {
      try {
        setError('');
        // console.log(data.password);
        console.log('PASSWORD DATA', data);

        const res = await userVerifyToken({ token: data.token });
        console.log('RES', res);

        if (res?.status === 'OK') {
          await confirmForgotPassword(data);
          enqueueSnackbar('Password Changed Successfully', { variant: 'success' });
          resetForm();
          navigate('/login');
        } else {
          setError(res?.status);
          enqueueSnackbar(res?.status, { variant: 'error' });
        }
      } catch (e) {
        const errorMessage = await throwErrorMessage(e);
        setError(errorMessage);
      }
    }
  });

  const { handleSubmit, getFieldProps } = formik;

  return (
    <>
      <div className="container" style={{ maxWidth: '1000px', margin: 'auto' }}>
        <h2
          className="text-center font-weight-bold"
          style={{ fontSize: '1.5rem', marginTop: '5px' }}
        >
          Create New Password
        </h2>
        {error && (
          <div className="mb-4">
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          </div>
        )}
        <div className="my-2">
          <div className="card">
            <FormikProvider value={formik}>
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <div className="card-body" style={{ margin: '16px' }}>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pr-1">
                      <div className="mt-2">
                        <label htmlFor="password" className="form-label">
                          New Password
                        </label>
                        <div className="input-group mb-3">
                          <input
                            type={showNewPassword ? 'text' : 'password'}
                            className="form-control bg-transparent"
                            {...getFieldProps('password')}
                            autoComplete="off"
                            id="password"
                            placeholder="Enter New Password"
                          />
                          <span
                            className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                            data-kt-password-meter-control="visibility"
                            onClick={handleShowConfirmPassword}
                          >
                            {!showNewPassword ? (
                              <i className="ki-duotone ki-eye-slash fs-1">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                              </i>
                            ) : (
                              <i className="ki-duotone ki-eye  fs-1">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                              </i>
                            )}
                          </span>
                        </div>
                        {formik.touched.password && formik.errors.password && (
                          <div className="text-danger">{formik.errors.password}</div>
                        )}
                      </div>
                      <div className="mt-2">
                        <label htmlFor="confirm_new_password" className="form-label">
                          New Password Confirmation
                        </label>
                        <input
                          type="password"
                          {...getFieldProps('confirm_new_password')}
                          placeholder="Confirm New Password"
                          className="form-control bg-transparent"
                          autoComplete="off"
                          id="confirm_new_password"
                        />
                      </div>
                      {formik.touched.confirm_new_password &&
                        formik.errors.confirm_new_password && (
                          <div className="text-danger">{formik.errors.confirm_new_password}</div>
                        )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <h5 className="font-weight-bold">
                        In order to protect your account, make sure your password is:
                      </h5>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          New password must be different from previously used old password
                        </li>
                        <li className="list-group-item">At least 8 characters long</li>
                        <li className="list-group-item">
                          Characters from 3 different groups (lowercase, uppercase, numbers, special
                          characters)
                        </li>
                      </ul>
                      <div className="text-center mt-10">
                        <img
                          alt="XDC Trade Network"
                          title="XDC Trade Network"
                          src={logo}
                          className="h-50px"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-start">
                      <button
                        className="btn btn-outline-danger mx-1"
                        type="button"
                        onClick={() => {
                          navigate('/login');
                        }}
                      >
                        Cancel
                      </button>
                      <button className="btn btn-primary mx-1" type="submit">
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </FormikProvider>
            <div className="text-center text-muted px-lg-10 mb-4">
              &copy; 2023 XDC Trade Network - All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
