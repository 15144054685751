import { useAuthState, useWeb3State } from 'state';
type Props = {
  children: JSX.Element;
  not?: boolean;
};
const WalletRequire = ({ children, not }: Props) => {
  const { active, account } = useWeb3State();
  const { user } = useAuthState();
  const isWallet = localStorage.getItem('selectedWallet');
  const role = user?.user?.role?.title;
  const condition = role !== 'ADMIN' ? isWallet || (active && account) : true;
  const show = not ? !condition : condition;

  return show ? children : null;
};

export default WalletRequire;
