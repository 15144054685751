import React, { useEffect } from 'react';
import { useAuthState, useAuthStateShared } from 'state';
import { useNavigate, useLocation } from 'react-router';

type Props = {
  children: JSX.Element;
};

const AuthRequired = ({ children }: Props) => {
  const { initAuth, isAuthorized, isReady } = useAuthState();
  const location = useLocation();
  const { user } = useAuthStateShared();
  const navigate = useNavigate();

  const redirectToAllowedRoutes = (defaultRoute: string) => {
    location?.pathname.startsWith(`${defaultRoute}`)
      ? navigate(location?.pathname)
      : navigate(defaultRoute);
  };

  useEffect(() => {
    initAuth();
    if (!user) {
      return;
    }

    const roleTitle = user.user?.role?.title;
    const entityType = user.entity?.entity_type?.name;
    const isApproved = user.entity?.is_approved;

    if (roleTitle === 'ADMIN') {
      redirectToAllowedRoutes('/admin');
    } else if (isApproved) {
      if (entityType === 'TRADER') {
        redirectToAllowedRoutes('/trader');
      } else if (entityType === 'LIQUIDITY_PROVIDER') {
        redirectToAllowedRoutes('/liquidity-provider');
      }
    } else {
      navigate('/register');
    }
  }, [user]);

  return isAuthorized ? children : null;
};

export default AuthRequired;
