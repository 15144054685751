import { useNavigate } from 'react-router';
import StepHeader from '../../components/swap/StepHeader';
import { useAppState } from '../../state/useAppState';
import { step } from '../../types/global';
import { useHttpApi, useTradeState, useWeb3State } from 'state';
import { useSnackbar } from 'notistack';
import { currentNetwork } from 'helpers/web3';
import { useEffect, useState } from 'react';
import resourcePersistence from 'persistence/resourcePersistence';
import { titleCase } from 'helpers/common';

const TransferOwnership = () => {
  const {
    currentStep,
    setCurrentStep,
    throwErrorMessage,
    loadCurrentTransferOwnership,
    transferOwnership: props
  } = useAppState();
  const { enqueueSnackbar } = useSnackbar();
  const { swap, transferHoldership, nominateOwner } = useWeb3State();
  const { updateFundingRequestStatus } = useHttpApi();
  const [isLoading, setIsLoading] = useState(false);
  const { fetchHolderNomineeAdress, fetchHolderAddress, fetchNomineeAddress } = useAppState();
  const { signer } = useWeb3State();

  const { fetchFundingRequestDetailsByID, fundingRequestsDetails } = useTradeState();
  const fundingRequestID = props?.fundingRequestId;
  console.log('FDDDD', fundingRequestsDetails);
  useEffect(() => {
    const fRID = resourcePersistence.get('fundingRequestID');
    if (fRID) {
      fetchFundingRequestDetailsByID(fRID);
    }
  }, [fundingRequestID]);

  const checkAndSetCurrentStep = async () => {
    const [isHoldershipTransferred] = await fetchHolderNomineeAdress({
      titleEscrowAddress: fundingRequestsDetails?.title_escrow_contract,

      signer: signer
    });

    if (isHoldershipTransferred) {
      setCurrentStep('nominate');
    }
  };

  useEffect(() => {
    loadCurrentTransferOwnership();
    checkAndSetCurrentStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  const steps: {
    key: step;
    title: string;
    titleDescription: string;
    body: React.ReactElement;
  }[] = [
    {
      key: 'transfer_holdership',
      title: 'Transfer Holdership',
      titleDescription: '',
      body: (
        <>
          <div className="text-center">
            <p className="fs-4 fw-semibold">
              Transfer Holdership of Title Document to XDC Network Smart Contract
            </p>

            {/* <input
                type="text"
                readOnly
                className="form-control form-control-solid"
                value={currentNetwork.EscrowMangerAddress}
              /> */}

            <button
              type="button"
              className="btn btn-primary "
              style={{ width: '15rem' }}
              disabled={isLoading}
              onClick={async () => {
                setIsLoading(true);
                console.log(
                  'transfer',
                  props?.titleEscrowContractAddress,
                  currentNetwork.EscrowMangerAddress
                );
                try {
                  const res = await transferHoldership(
                    props?.titleEscrowContractAddress,
                    currentNetwork.EscrowMangerAddress
                  );
                  if (res) {
                    setIsLoading(false);
                    enqueueSnackbar('Transferred Holdership', { variant: 'info' });
                    setCurrentStep('nominate');
                  }
                } catch (e) {
                  setIsLoading(false);
                  throwErrorMessage(e);
                }
              }}
            >
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm mx-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {isLoading ? 'Processing' : 'Transfer'}
            </button>
          </div>
        </>
      )
    },
    {
      key: 'nominate',
      title: 'Set Nominee',
      titleDescription: '',
      body: (
        <>
          <div className="text-center">
            <p className="fs-4 fw-semibold">
              Nominate Change of Ownership to&nbsp;
              <b>
                {props?.payee}
                {/* -&nbsp;
                {props?.payee === 'LIQUIDITY PROVIDER'
                  ? fundingRequestsDetails?.liquidity_provider?.name
                  : fundingRequestsDetails?.beneficiary?.name} */}
              </b>
            </p>

            {/* <input
              type="text"
              className="form-control form-control-solid"
              value={props?.payeeAddress}
            /> */}

            <button
              type="button"
              className="btn btn-primary"
              disabled={isLoading}
              onClick={async () => {
                console.log(
                  '👉️ titleEscrow, payeeAddress',
                  props?.titleEscrowContractAddress,
                  props?.payeeAddress
                );
                setIsLoading(true);
                try {
                  /*  row.title_escrow_contract , payeeAddres  = liquidity_provider || beneficiary wallet address */
                  const res = await fetchHolderAddress({
                    titleEscrowAddress: fundingRequestsDetails?.title_escrow_contract,
                    payeeAddress: currentNetwork.EscrowMangerAddress,
                    signer: signer
                  });
                  console.log(res);
                  if (!res) {
                    setIsLoading(false);
                    enqueueSnackbar('Transfer Holdership in progress. Please wait', {
                      variant: 'info'
                    });
                    return;
                  }

                  await nominateOwner(props?.titleEscrowContractAddress, props?.payeeAddress);
                  setIsLoading(false);
                  enqueueSnackbar('Nominated', { variant: 'info' });
                  setCurrentStep('swap');
                } catch (e) {
                  setIsLoading(false);
                  throwErrorMessage(e);
                }
              }}
            >
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm mx-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {isLoading ? 'Processing' : 'Nominate'}
            </button>
          </div>
        </>
      )
    },
    {
      key: 'swap',
      titleDescription: '',
      title: 'Atomic Swap',
      body: (
        <>
          <div className="text-center">
            <p className=" fs-4 fw-semibold">
              Proceeding with this action will reassign the ownership of the Title Document to the{' '}
              {titleCase(props?.payee)}
              {/* -{' '}
              {props?.payee === 'LIQUIDITY PROVIDER'
                ? fundingRequestsDetails?.liquidity_provider?.name
                : fundingRequestsDetails?.beneficiary?.name} */}
              . In exchange, {props?.owner} will receive a corresponding value in their bank
              account.
            </p>

            <button
              type="button"
              className="btn btn-primary"
              style={{ width: '15rem' }}
              disabled={isLoading}
              onClick={async () => {
                setIsLoading(true);
                try {
                  const isNominee = await fetchNomineeAddress({
                    titleEscrowAddress: fundingRequestsDetails?.title_escrow_contract,
                    payeeAddress: props.payeeAddress,
                    signer: signer
                  });
                  if (!isNominee) {
                    setIsLoading(false);
                    enqueueSnackbar('Nominate Change of Ownership in progress. Please wait', {
                      variant: 'info'
                    });
                    return;
                  }
                  const res = await swap(props?.escrowId);
                  if (res) {
                    const _res = await updateFundingRequestStatus(props?.fundingRequestId, {
                      status_name: props?.payee === 'LIQUIDITY PROVIDER' ? 'FUNDED' : 'COMPLETE'
                    });
                    if (_res) {
                      setIsLoading(false);
                      enqueueSnackbar('Trade Completed!', { variant: 'success' });
                      setCurrentStep('transfer_holdership');
                      if (props?.owner === 'LIQUIDITY PROVIDER')
                        navigate('/liquidity-provider/redeem-funding');
                      else {
                        navigate('/trader');
                      }
                    }
                  }
                } catch (e) {
                  setIsLoading(false);
                  throwErrorMessage(e);
                }
              }}
            >
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm mx-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {isLoading ? 'Processing' : 'Settle'}
            </button>
          </div>

          <div className="text-end">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                if (props?.owner === 'LIQUIDITY PROVIDER')
                  navigate('/liquidity-provider/redeem-funding');
                else {
                  navigate('/trader');
                }
              }}
            >
              Back
            </button>
          </div>
        </>
      )
    }
  ];

  return (
    <div className="pt-5 pb-5 bg-grey">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-10 text-center">
            <h3
              className="fs-2hx text-dark mb-5"
              id="team"
              data-kt-scroll-offset="{default: 100, lg: 150}"
            >
              Settlement
            </h3>

            <div className="fs-5 text-muted fw-bold">
              Transferring ownership of Title Document from {titleCase(props?.owner)}
              &nbsp;
              {/* -{' '}
              {props?.owner === 'LIQUIDITY PROVIDER'
                ? fundingRequestsDetails?.liquidity_provider?.name
                : fundingRequestsDetails?.trade?.exporter?.name}{' '} */}
              to {titleCase(props?.payee)}
              {/* -{' '}
              {props?.payee === 'LIQUIDITY PROVIDER'
                ? fundingRequestsDetails?.liquidity_provider?.name
                : fundingRequestsDetails?.beneficiary?.name} */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div
              className="card"
              style={{
                filter: 'drop-shadow(0px 0px 40px rgba(68, 81, 96, 0.08))'
              }}
            >
              <div className="card-body p-lg-20">
                <div className="stepper stepper-pills" id="kt_stepper_example_clickable">
                  <div className="stepper-nav flex-center flex-wrap mb-10">
                    {steps.map((step, index) => (
                      <StepHeader
                        key={step.key}
                        stepNumber={index + 1}
                        title={step.title}
                        titleDescription={step.titleDescription}
                        isActive={currentStep === step.key}
                      />
                    ))}
                  </div>
                  {steps.map((step) => (
                    <form className="form" id="kt_stepper_example_basic_form" key={step.key}>
                      {currentStep === step.key && step.body}
                    </form>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferOwnership;
