export const statusLabel = (status: string) => {
  switch (status) {
    case 'New':
      return 'badge-light';
    case 'In-Review':
      return 'badge-light-primary';
    case 'Approved':
      return 'badge-light-success';
    default:
      return 'badge-light';
  }
};

export const statusTradeLabel = (status: string) => {
  switch (status) {
    case 'New':
      return 'badge-light';
    case 'In-Review':
      return 'badge-light-primary';
    case 'Approved':
      return 'badge-light-primary';
    case 'In-Progress':
      return 'badge-light-warning';
    case 'Funded':
      return 'badge-light-warning';
    case 'Initiate Settlement':
      return 'badge-light-warning';
    case 'Pending Settlement':
      return 'badge-light-warning';
    case 'Completed':
      return 'badge-light-success';
    case 'Complete':
      return 'badge-light-success';
    default:
      return 'badge-light';
  }
};

export const statusDocCheck = (status: string) => {
  switch (status) {
    case 'PENDING':
      return 'badge py-3 px-4 fs-7 badge-light-primary';
    case 'SUBMITTED':
      return 'badge py-3 px-4 fs-7 badge-light-success';
    default:
      return 'badge py-3 px-4 fs-7 badge-light-primary';
  }
};

export const titleCase = (str: string) => {
  str = str ?? '';
  str = str.replaceAll('_', ' ');
  return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
};
