import React from 'react';

const Footer = () => {
  return (
    <div id="kt_app_footer" className="app-footer">
      {/*begin::Footer container*/}
      <div className="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3 mt-auto">
        {/*begin::Copyright*/}
        <div className="text-muted order-2 order-md-1">
          &copy; 2023 XDC Trade Network - All Rights Reserved.
        </div>
        {/*end::Copyright*/}
        {/*begin::Menu*/}
        {/* <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
          <li className="menu-item text-muted">Privacy Policy</li>
        </ul> */}
        {/*end::Menu*/}
      </div>
      {/*end::Footer container*/}
    </div>
  );
};

export default Footer;
