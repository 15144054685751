import constate from 'constate';
import { useHttpApi } from 'state/useHttpApi';
import { useAuthStateShared } from 'state/useAuthStateShared';
import { userNamePersistence, userTokenPersistence } from 'persistence';
import { LoginInput } from 'types/profileTypes';
import { json } from 'stream/consumers';
import resourceJsonPersistence from 'persistence/resourceJsonPersistence';

const useAuthState_ = () => {
  const { userLogin, getLoggedInUser, userSignOut, userForgotPassword, userChangePassword } =
    useHttpApi();
  const { user, setUser, setLoggedOutState, setIsReady, setIsAuthorized, isReady, isAuthorized } =
    useAuthStateShared();

  const initAuth = async () => {
    if (isReady) {
      return;
    }
    // const user = resourceJsonPersistence.get('user');
    // console.log(user)
    // setUser(user);
    // setIsAuthorized(true);

    // if (!user) {
    //   setLoggedOutState();
    //   setIsReady(true);
    // }
    const token = userTokenPersistence.get();

    if (!token) {
      setLoggedOutState();
      setIsReady(true);
    }
    try {
      const loggedInUser = await getLoggedInUser();
      setUser(loggedInUser);
      resourceJsonPersistence.set('user', loggedInUser);
      setIsAuthorized(true);

      console.log('🚀 ~ file: useAuthState.ts:34 ~ initAuth ~ loggedInUser:', loggedInUser);
    } catch (e) {
      setLoggedOutState();
    } finally {
      setIsReady(true);
    }
  };

  const login = async ({ email, password }: LoginInput) => {
    const res = await userLogin({ email, password });
    console.log('🚀 ~ file: useAuthState.ts:43 ~ login ~ res:', res);
    userNamePersistence.set(res.user.email);
    resourceJsonPersistence.set('user', res);
    userTokenPersistence.set(res.token);
    setUser(res);
    setIsAuthorized(true);
    setIsReady(true);
    return res;
  };

  const logout = async () => {
    const name = userNamePersistence.get();
    const res = await userSignOut(user?.user?.email);
    console.log('🚀 ~ file: useAuthState.ts:43 ~ login ~ res:', res);
    setLoggedOutState();
    localStorage.clear();
    // return res;
    return 'OK';
  };

  const fetchLoggedinUser = async () => {
    try {
      const loggedInUser = await getLoggedInUser();
      setUser(loggedInUser);
      resourceJsonPersistence.set('user', loggedInUser);
      setIsAuthorized(true);
    } catch (e) {
      setLoggedOutState();
    } finally {
      setIsReady(true);
    }
  };

  const changePassword = async (data: any) => {
    await userChangePassword(data);
    setLoggedOutState();
  };

  const forgetPassword = async (data: any) => {
    const res = await userForgotPassword(data);
    console.log('🚀 ~ file: useAuthState.ts:43 ~ login ~ res:', res);
  };

  return {
    user,
    isReady,
    isAuthorized,
    login,
    initAuth,
    logout,
    fetchLoggedinUser,
    forgetPassword,
    changePassword
  };
};
export const [AuthStateProvider, useAuthState] = constate(useAuthState_);
