import React, { useEffect } from 'react';
import dashboardIcon01 from '../../assets/media/misc/dashboard-icon-01.jpg';
import dashboardIcon02 from '../../assets/media/misc/dashboard-icon-02.jpg';
import dashboardIcon03 from '../../assets/media/misc/dashboard-icon-03.jpg';
import dashboardIcon04 from '../../assets/media/misc/dashboard-icon-04.jpg';
import ApprovedFundingCard from 'components/common/ApprovedFundingCard';
import DashboardCard from 'components/common/DashboardCard';
import { userTokenPersistence } from 'persistence';
import { useNavigate } from 'react-router';
import { useTradeState } from 'state';

const TraderDashboard = () => {
  const navigate = useNavigate();
  const { fetchAllApprovedFundingRequests, approvedFundingRequests } = useTradeState();

  useEffect(() => {
    fetchAllApprovedFundingRequests();
  }, []);

  return (
    <div id="kt_app_content" className="app-content flex-column-fluid">
      <div id="kt_app_content_container" className="app-container container-fluid">
        {/* <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-3">
          <DashboardCard title="Request Submitted" count={1} imgSrc={dashboardIcon01} />
          <DashboardCard title="Request Pending" count={1} imgSrc={dashboardIcon02} />
          <DashboardCard title="Request Approved" count={1} imgSrc={dashboardIcon03} />
          <DashboardCard title="Request Rejected" count={1} imgSrc={dashboardIcon04} />
        </div> */}
        <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-3">
          <ApprovedFundingCard
            fundingRequests={approvedFundingRequests || []}
            title={'Approved Funding Requests'}
            owner={'EXPORTER'}
            payee={'LIQUIDITY PROVIDER'}
          />
        </div>
      </div>
    </div>
  );
};

export default TraderDashboard;
