import React from 'react';

import FundingRequestsTable from 'components/fundingTables/FundingRequestsTable';
import LiquidityDashBoardCard from 'components/liquidityProvider/LiquidityDashBoardCard';

const LiquidityDashboard = () => {
  const newData = [
    {
      trade_name: 'Universal Goods Inc.',
      company_name: 'Exporter 1',
      date: '02 Apr, 2023',
      value: '$1.2Mn',
      status: 'New'
    },
    {
      trade_name: 'Transatlantic Corp',
      company_name: 'Exporter 2',
      date: '02 Apr, 2023',
      value: '590K',
      status: 'In-Review'
    },
    {
      trade_name: 'Best Exports LLC',
      company_name: 'Exporter 3',
      date: '14 Aug, 1018',
      value: '$1.85Mn',
      status: 'Approved'
    }
  ];
  return (
    <div id="kt_app_content" className="app-content flex-column-fluid pt-5 mt-5">
      {/* Content container */}
      <div id="kt_app_content_container" className="app-container container-fluid">
        {/* Row */}
        {/* <LiquidityDashBoardCard eblsSubmitted={5} fundingRequested={2} fundingDone={3} /> */}
        {/* End Row */}
        {/* Row */}
        <FundingRequestsTable data={newData} />
        {/* End Row */}
      </div>
      {/* End Content container */}
    </div>
  );
};

export default LiquidityDashboard;
