import { ethToXdcAddress } from 'helpers/web3';
import { useNavigate } from 'react-router';
import { useAppState, useAuthState, useAuthStateShared, useHttpApi } from 'state';
import { ProfileFormProps } from 'types/profileTypes';
import RegistryForm from './RegistryForm';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { EmailRegex } from 'helpers/regex';

const ProfileForm = ({
  name,
  user_email,
  entity_type,
  company_name,
  company_email,
  xinfin_address,
  phone_number,
  identifier_type,
  identifier,
  address_line_1,
  address_line_2,
  country,
  state,
  city,
  zipcode,
  registry
}: ProfileFormProps) => {
  const { fetchLoggedinUser } = useAuthState();
  const { user } = useAuthStateShared();
  const navigate = useNavigate();
  const { traderRole, throwErrorMessage } = useAppState();
  const isAdmin = entity_type === 'Admin';
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const { updateEntityUser } = useHttpApi();

  const profileValidationSchema = Yup.object().shape({
    companyEmail: Yup.string()
      .email('Invalid Email Address')
      .required('Email is required')
      .matches(EmailRegex, 'Invalid Email Address'),
    phoneNumber: Yup.number().required('Phone number is required')
  });

  const formik = useFormik({
    initialValues: {
      companyEmail: company_email,
      phoneNumber: phone_number
    },
    validationSchema: profileValidationSchema,
    onSubmit: async (data) => {
      try {
        if (isDisabled) {
          setIsDisabled(false);
          console.log('disabled');
          setSubmitting(false);
        } else {
          setSubmitting(true);
          console.log('values', data);
          console.log('enabled');
          const response = await updateEntityUser({
            id: user?.entity?.id,
            email: data.companyEmail.toLowerCase(),
            phone_number: data.phoneNumber
          });
          console.log('response', response);
          enqueueSnackbar('Profile updated successfully', { variant: 'success' });
          await fetchLoggedinUser();
          setIsDisabled(true);
          setSubmitting(false);
        }
      } catch (error) {
        console.log('error', error);
        throwErrorMessage(error);
        setSubmitting(false);
      }
    }
  });

  const toggleEdit = () => {
    setIsDisabled(!isDisabled);
    console.log('disabled');
  };

  const role = user?.user?.role?.title === 'PRIMARY_TRADER' ? 'trader' : 'liquidity_proivder';

  return (
    <div className="card shadow-sm">
      <div className="card-header">
        <h1 className="card-title">Profile</h1>
      </div>
      <div className="card-body">
        <FormikProvider value={formik}>
          <Form className="form w-100" id="kt_sign_up_form" data-kt-redirect-url="matching-rules">
            <div className="row">
              <div className="col-5 mb-8">
                <label className="mb-2 mx-4" data-testid="t_label_name">
                  User Name
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control form-control-solid"
                  id="name"
                  value={name}
                  readOnly
                />
              </div>
              <div className="col-5 mb-8">
                <label className="mb-2 mx-4" data-testid="t_label_role">
                  Role
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control form-control-solid"
                  id="entity_type"
                  value={entity_type}
                  readOnly
                />
              </div>
            </div>
            <div className="row">
              <div className="col-5 mb-8">
                <label className="mb-2 mx-4" data-testid="t_label_name">
                  User Email
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control form-control-solid"
                  id="name"
                  value={user_email}
                  readOnly
                />
              </div>
            </div>
            {!isAdmin && (
              <>
                <hr className="my-10" />
                <div className="row">
                  <div className="col-5 mb-8">
                    <label className="mb-2 mx-4" data-testid="t_label_company_name">
                      Company Name
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control form-control-solid"
                      id="company_name"
                      value={company_name}
                      readOnly
                    />
                  </div>
                  <div className="col-5 mb-8">
                    <label className="mb-2 mx-4" data-testid="t_label_company_email">
                      Company Email
                    </label>
                    <input
                      type="email"
                      autoComplete="off"
                      className="form-control form-control-solid"
                      id="company_email"
                      {...formik.getFieldProps('companyEmail')}
                      readOnly={isDisabled}
                    />
                    {formik.touched.companyEmail && formik.errors.companyEmail ? (
                      <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                        {formik.errors.companyEmail}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-5 mb-8">
                    <label className="mb-2 mx-4" data-testid="t_label_xinfin_address">
                      Xinfin Address
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control form-control-solid"
                      id="xinfin_address"
                      value={xinfin_address && ethToXdcAddress(xinfin_address)}
                      readOnly
                    />
                  </div>
                  <div className="col-5 mb-8">
                    <label className="mb-2 mx-4" data-testid="t_label_phone_number">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control form-control-solid"
                      id="phone_number"
                      {...formik.getFieldProps('phoneNumber')}
                      readOnly={isDisabled}
                    />
                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                      <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                        {formik.errors.phoneNumber}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-5 mb-8">
                    <label className="mb-2 mx-4" data-testid="t_label_identifier_type">
                      Identifier Type
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control form-control-solid"
                      id="identifier_type"
                      value={identifier_type}
                      readOnly
                    />
                  </div>
                  <div className="col-5 mb-8">
                    <label className="mb-2 mx-4" data-testid="t_label_identifier">
                      Identifier
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control form-control-solid"
                      id="identifier"
                      value={identifier}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-5 mb-8">
                    <label className="mb-2 mx-4" data-testid="t_label_address_line_1">
                      Address Line 1
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control form-control-solid"
                      id="address_line_1"
                      value={address_line_1}
                      readOnly
                    />
                  </div>
                  <div className="col-5 mb-8">
                    <label className="mb-2 mx-4" data-testid="t_label_address_line_2">
                      Address Line 2
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control form-control-solid"
                      id="address_line_2"
                      value={address_line_2}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-5 mb-8">
                    <label className="mb-2 mx-4" data-testid="t_label_country">
                      Country
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control form-control-solid"
                      id="country"
                      value={country}
                      readOnly
                    />
                  </div>
                  <div className="col-5 mb-8">
                    <label className="mb-2 mx-4" data-testid="t_label_state">
                      State
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control form-control-solid"
                      id="state"
                      value={state}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-5 mb-8">
                    <label className="mb-2 mx-4" data-testid="t_label_city">
                      City
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control form-control-solid"
                      id="city"
                      value={city}
                      readOnly
                    />
                  </div>
                  <div className="col-5 mb-8">
                    <label className="mb-2 mx-4" data-testid="t_label_zipcode">
                      Zipcode
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control form-control-solid"
                      id="zipcode"
                      value={zipcode}
                      readOnly
                    />
                  </div>
                </div>
              </>
            )}
            <div className="justify-content-end d-flex">
              <button
                type="button"
                onClick={() => {
                  if (isDisabled) {
                    toggleEdit();
                  } else {
                    formik.handleSubmit();
                  }
                }}
                id="kt_sign_up_submit"
                className="btn btn-primary"
                disabled={submitting}
              >
                {submitting && (
                  <span
                    className="spinner-border spinner-border-sm mx-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                {isDisabled ? 'Edit' : <>{submitting ? 'Saving...' : 'Save'}</>}
              </button>
              <button
                onClick={() => {
                  if (role === 'trader') {
                    if (role === 'trader' && traderRole === 'EXPORTER') {
                      navigate('/trader');
                    } else if (role === 'trader' && traderRole === 'IMPORTER') {
                      navigate('/trader/imports');
                    }
                  } else {
                    navigate('/liquidity-provider');
                  }
                }}
                type="button"
                className="btn btn-primary mx-2"
              >
                <span className="indicator-label">Back</span>
              </button>
            </div>

            {/* <div className="row justify-content-md-center">
            <div className="col-5 mb-8">
              <label className="mb-2 mx-4">Signature</label>
              <img
                src={signature}
                className="img-fluid rounded-circle"
                alt="signature"
                id="signature"
                width={250}
                height={100}
              />
            </div>
          </div> */}
          </Form>
        </FormikProvider>
        {user?.entity?.entity_type?.name === 'TRADER' && registry && (
          <>
            <hr className="my-10" />
            <RegistryForm
              entity={registry.entity}
              verifiable_dns={registry.verifiable_dns}
              verifiable_contract_address={registry.verifiable_contract_address}
              transferable_dns={registry.transferable_dns}
              transferable_contract_address={registry.transferable_contract_address}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileForm;
