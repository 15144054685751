import React, { useState, useRef, useEffect } from 'react';
import logo from '../../assets/media/logos/logo.svg';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useCoreTableState, useHttpApi, useWeb3State, useAppState, useAuthState } from 'state';
import { ethToXdcAddress, toChecksumAddress, xdcToEthAddress } from 'helpers/web3';
import { enqueueSnackbar } from 'notistack';
import FocusError from 'components/FocusError';
import { IdentifierTypesResponse } from 'types/entity';
import { titleCase } from 'helpers/common';
import { EmailRegex } from 'helpers/regex';
const RegistrationForm = () => {
  const navigate = useNavigate();

  const { fetchEntityTypes, fetchIdentifierTypes, entityTypes, identifierTypes, countries } =
    useCoreTableState();
  const { account } = useWeb3State();
  const { registerCompany } = useHttpApi();
  const { throwErrorMessage } = useAppState();
  const { fetchLoggedinUser } = useAuthState();
  const [regexPattern, setRegexPattern] = useState('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const EntitySchema = Yup.object().shape({
    name: Yup.string().required('Company Name is required'),
    email: Yup.string()
      .email('Invalid Email Address')
      .required('Company Email is required')
      .matches(EmailRegex, 'Invalid Email Address'),
    wallet_address: Yup.string().required('Xinfin Address is required'),
    country: Yup.string().required('Please select a country'),
    phone_number: Yup.string().required('Phone Number is required'),
    entity_type: Yup.string().required('Please select a role'),
    identifier_type: Yup.string().required('Please select an identifier'),
    identifier: Yup.string().required('Identifier is required'),
    address_line_1: Yup.string().required('Address is required'),
    address_line_2: Yup.string().required('Address is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    zipcode: Yup.string().required('Zipcode is required')
  });

  const formik = useFormik({
    validate: (values) => {
      const errors: any = {};

      if (values.identifier_type) {
        const identifierType = JSON.parse(values.identifier_type);
        let regexPatternString = identifierType.regex;
        if (regexPatternString) {
          regexPatternString = regexPatternString.replace(/^\/|\/$/g, '');
          const regexPattern = new RegExp(regexPatternString);
          if (!regexPattern.test(values.identifier)) {
            errors.identifier = `Invalid ${identifierType.name}`;
          }
        }
      }

      return errors;
    },
    initialValues: {
      name: '',
      email: '',
      wallet_address: '',
      entity_type: '',
      phone_number: '',
      identifier_type: '',
      identifier: '',
      address_line_1: '',
      address_line_2: '',
      state: '',
      city: '',
      zipcode: '',
      country: ''
    },
    validationSchema: EntitySchema,
    onSubmit: async (data) => {
      try {
        setSubmitting(true);
        const identifierType = JSON.parse(data.identifier_type).id;
        const res = await registerCompany({
          name: data.name,
          email: data.email.toLowerCase(),
          wallet_address: toChecksumAddress(xdcToEthAddress(data.wallet_address)),
          entity_type: data?.entity_type,
          phone_number: data?.phone_number,
          identifier_type: identifierType,
          identifier: data.identifier,
          address_line_1: data.address_line_1,
          address_line_2: data.address_line_2,
          state: data.state,
          city: data.city,
          zipcode: data.zipcode,
          country: data.country
        });
        if (res) {
          await fetchLoggedinUser();
          enqueueSnackbar('Registration Submitted! Please wait for the approval.', {
            variant: 'success'
          });
        }
        navigate('/login', { replace: true });
        setSubmitting(false);
      } catch (e) {
        throwErrorMessage(e);
        setSubmitting(false);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    fetchEntityTypes();
    fetchIdentifierTypes();
  }, []);

  useEffect(() => {
    if (account) {
      formik.setFieldValue('wallet_address', ethToXdcAddress(toChecksumAddress(account)));
    }
  }, [account]);

  return (
    <FormikProvider value={formik}>
      <form className="form w-100" id="kt_sign_up_form" onSubmit={handleSubmit}>
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">Register Your Company</h1>
          <div className="text-gray-500 fw-semibold fs-6">Profile Set-Up</div>
        </div>

        <div className="row">
          <div className="col-6 mb-8">
            <label className="mb-2">Company Name</label>
            <input
              type="text"
              autoComplete="off"
              className="form-control bg-transparent"
              id="name"
              {...getFieldProps('name')}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                {formik.errors.name}
              </div>
            ) : null}
          </div>
          <div className="col-6 mb-8">
            <label className="mb-2">Email</label>
            <input
              type="eamil"
              autoComplete="off"
              className="form-control bg-transparent"
              id="email"
              {...getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                {formik.errors.email}
              </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-6 mb-8">
            <label className="mb-2">Xinfin Address</label>
            <input
              readOnly
              type="text"
              autoComplete="off"
              className="form-control bg-transparent"
              id="wallet_address"
              {...getFieldProps('wallet_address')}
            />
            {formik.touched.wallet_address && formik.errors.wallet_address ? (
              <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                {formik.errors.wallet_address}
              </div>
            ) : null}
          </div>
          <div className="col-6 mb-8">
            <label className="mb-2">Phone Number</label>
            <input
              type="number"
              autoComplete="off"
              className="form-control bg-transparent"
              id="phone_number"
              {...getFieldProps('phone_number')}
            />
            {formik.touched.phone_number && formik.errors.phone_number ? (
              <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                {formik.errors.phone_number}
              </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-6 mb-8">
            <label className="mb-2">Select a Role</label>
            <select
              className="form-select form-select-lg mb-3"
              aria-label=".form-select-lg example"
              id="entity_type"
              {...getFieldProps('entity_type')}
            >
              <option selected hidden>
                Select
              </option>
              {entityTypes?.map((entityType) => (
                <option value={entityType?.id}>{titleCase(entityType?.name)}</option>
              ))}
            </select>
            {formik.touched.entity_type && formik.errors.entity_type ? (
              <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                {formik.errors.entity_type}
              </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-6 mb-8">
            <label className="mb-2">Identifier Type</label>
            <select
              className="form-select form-select-lg mb-3"
              aria-label=".form-select-lg example"
              id="identifier_type"
              {...getFieldProps('identifier_type')}
            >
              <option selected hidden>
                Select
              </option>
              {identifierTypes?.map((identifierType) => (
                <option value={JSON.stringify(identifierType)} key={identifierType.id}>
                  {identifierType?.name}
                </option>
              ))}
            </select>

            {formik.touched.identifier_type && formik.errors.identifier_type ? (
              <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                {formik.errors.identifier_type}
              </div>
            ) : null}
          </div>
          <div className="col-6 mb-8">
            <label className="mb-2">Identifier Value</label>
            <input
              type="text"
              autoComplete="off"
              className="form-control bg-transparent"
              id="identifier"
              {...getFieldProps('identifier')}
            />
            {formik.touched.identifier && formik.errors.identifier ? (
              <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                {formik.errors.identifier}
              </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-6 mb-8">
            <label className="mb-2">Address Line 1</label>
            <input
              type="text"
              autoComplete="off"
              className="form-control bg-transparent"
              id="address_line_1"
              {...getFieldProps('address_line_1')}
            />
            {formik.touched.address_line_1 && formik.errors.address_line_1 ? (
              <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                {formik.errors.address_line_1}
              </div>
            ) : null}
          </div>
          <div className="col-6 mb-8">
            <label className="mb-2">Address Line 2</label>
            <input
              type="text"
              autoComplete="off"
              className="form-control bg-transparent"
              id="address_line_2"
              {...getFieldProps('address_line_2')}
            />
            {formik.touched.address_line_2 && formik.errors.address_line_2 ? (
              <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                {formik.errors.address_line_2}
              </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-6 mb-8">
            <label className="mb-2">Country</label>
            <select
              className="form-select form-select-lg mb-3"
              aria-label=".form-select-lg example"
              id="country"
              {...getFieldProps('country')}
            >
              <option selected hidden>
                Select
              </option>
              {countries?.map((country: any) => (
                <option value={country?.iso_code}>{country?.name}</option>
              ))}
            </select>

            {formik.touched.country && formik.errors.country ? (
              <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                {formik.errors.country}
              </div>
            ) : null}
          </div>
          <div className="col-6 mb-8">
            <label className="mb-2">State</label>
            <input
              type="text"
              autoComplete="off"
              className="form-control bg-transparent"
              id="state"
              {...getFieldProps('state')}
            />
            {formik.touched.state && formik.errors.state ? (
              <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                {formik.errors.state}
              </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-6 mb-8">
            <label className="mb-2">City</label>
            <input
              type="text"
              autoComplete="off"
              className="form-control bg-transparent"
              id="city"
              {...getFieldProps('city')}
            />
            {formik.touched.city && formik.errors.city ? (
              <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                {formik.errors.city}
              </div>
            ) : null}
          </div>
          <div className="col-6 mb-8">
            <label className="mb-2">Zipcode</label>
            <input
              type="text"
              autoComplete="off"
              className="form-control bg-transparent"
              id="zipcode"
              {...getFieldProps('zipcode')}
            />
            {formik.touched.zipcode && formik.errors.zipcode ? (
              <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                {formik.errors.zipcode}
              </div>
            ) : null}
          </div>
        </div>

        <div className="justify-content-end d-flex">
          <button
            type="submit"
            id="kt_sign_up_submit"
            className="btn btn-primary"
            disabled={submitting}
            onClick={() => {
              if (!account) {
                enqueueSnackbar('Please connect your wallet', {
                  variant: 'error'
                });
                return;
              }
            }}
          >
            {submitting && (
              <span
                className="spinner-border spinner-border-sm mx-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {submitting ? 'Submitting...' : 'Register'}
          </button>
        </div>
        <FocusError />
      </form>
    </FormikProvider>
  );
};

export default RegistrationForm;
