import { Form, FormikProvider, useFormik, ErrorMessage, FormikErrors } from 'formik';
import React, { useState, useRef, useEffect } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import * as Yup from 'yup';
import { Commodity, Country, Currency } from 'types/globalTypes';
import { useAppState, useCoreTableState, useHttpApi, useAuthState } from 'state';
import { MatchingRule } from 'types/profileTypes';
import { useSnackbar } from 'notistack';
import { match } from 'assert';
import FocusError from 'components/FocusError';

const AddMatchingRule = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { countries, commodities, currencies } = useCoreTableState();
  const { createOrUpdateMatchingRule } = useHttpApi();
  const { fetchMatchingRuleByEntity, matchingRuleForEntity } = useAppState();
  const { user } = useAuthState();

  useEffect(() => {
    fetchMatchingRuleByEntity(user?.entity?.id);
  }, [user?.entity?.id]);

  useEffect(() => {
    formik.setFieldValue('minimum_amount', matchingRuleForEntity?.minimum_amount);
    formik.setFieldValue('maximum_amount', matchingRuleForEntity?.maximum_amount);
    formik.setFieldValue('minimum_interest_rate', matchingRuleForEntity?.minimum_interest_rate);
  }, [matchingRuleForEntity]);

  const commoditiesSelectRef = useRef<Multiselect | null>(null);
  const currenciesSelectRef = useRef<Multiselect | null>(null);
  const toCountriesSelectRef = useRef<Multiselect | null>(null);
  const fromCountriesSelectRef = useRef<Multiselect | null>(null);

  const selectedCommodities: Commodity[] = matchingRuleForEntity?.commodities || [];

  const selectedCurrencies: Currency[] = matchingRuleForEntity?.currencies || [];

  const toCountries: Country[] = countries;
  const selectedToCountries: Country[] = matchingRuleForEntity?.to_country || [];

  const fromCountries: Country[] = countries;
  const selectedFromCountries: Country[] = matchingRuleForEntity?.from_country || [];

  const AddMatchingRuleSchema = Yup.object().shape({
    commodities: Yup.array().nullable(),
    currencies: Yup.array().nullable(),
    minimum_amount: Yup.number()
      .positive()
      .min(1, 'Amount should be greater than 1')
      .lessThan(Yup.ref('maximum_amount'), 'Minimum amount should be less than maximum amount')
      .nullable(),
    maximum_amount: Yup.number()
      .positive()
      .min(1, 'Amount should be greater than 1')
      .moreThan(Yup.ref('minimum_amount'), 'Maximum amount should be greater than minimum amount')
      .nullable(),
    minimum_interest_rate: Yup.number()
      .min(1, 'Minimum Interest Rate should be greater than 1')
      .nullable(),
    to_country: Yup.array().nullable(),
    from_country: Yup.array().nullable()
  });
  const formik = useFormik({
    initialValues: {
      entity: user?.entity?.id,
      commodities: [],
      currencies: [],
      minimum_amount: 0,
      maximum_amount: 0,
      minimum_interest_rate: 0,
      to_country: [],
      from_country: []
    },
    validationSchema: AddMatchingRuleSchema,
    onSubmit: async (data: MatchingRule, { resetForm }) => {
      try {
        console.log(data);
        data.commodities = commoditiesSelectRef?.current
          ?.getSelectedItems()
          .map((commodity: Commodity) => commodity.hs_code);
        data.currencies = currenciesSelectRef?.current
          ?.getSelectedItems()
          .map((currency: Currency) => currency.code);
        data.to_country = toCountriesSelectRef?.current
          ?.getSelectedItems()
          .map((country: Country) => country.iso_code);
        data.from_country = fromCountriesSelectRef?.current
          ?.getSelectedItems()
          .map((country: Country) => country.iso_code);

        if (!data.commodities?.length) {
          delete data.commodities;
        }
        if (!data.currencies?.length) {
          delete data.currencies;
        }
        if (!data.to_country?.length) {
          delete data.to_country;
        }
        if (!data.from_country?.length) {
          delete data.from_country;
        }
        const res = await createOrUpdateMatchingRule(data);
        if (res) {
          enqueueSnackbar('Matching Rule Set!', { variant: 'success' });
        }
        // resetForm();
      } catch (e: any) {
        console.log(e);
        enqueueSnackbar(e.response.data.message, { variant: 'error' });
      }
    }
  });

  const handleReset = async () => {
    const res = await createOrUpdateMatchingRule({
      entity: user?.entity?.id,
      minimum_amount: null,
      maximum_amount: null,
      minimum_interest_rate: null
    });
    if (res) {
      commoditiesSelectRef?.current?.resetSelectedValues();
      currenciesSelectRef?.current?.resetSelectedValues();
      toCountriesSelectRef?.current?.resetSelectedValues();
      fromCountriesSelectRef?.current?.resetSelectedValues();
      enqueueSnackbar('Matching Rule Reset!', { variant: 'success' });
    }
    resetForm();
  };
  const { getFieldProps, handleSubmit, errors, resetForm } = formik;
  return (
    <FormikProvider value={formik}>
      <div id="kt_app_content" className="mt-5 pt-5 app-content flex-column-fluid">
        <div id="kt_app_content_container" className="app-container container-fluid">
          <div className="card shadow-sm">
            <div className="card-header">
              <h1 className="card-title">Matching Rule</h1>
            </div>
            <div className="card-body">
              <Form className="form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-4 fv-row mb-6">
                    <label
                      className="fs-5 fw-semibold required mb-2"
                      data-testid="t_label_commodity"
                    >
                      Commodity
                    </label>
                    <Multiselect
                      avoidHighlightFirstOption
                      options={commodities}
                      selectedValues={selectedCommodities}
                      displayValue="name"
                      ref={commoditiesSelectRef}
                      id="commodities"
                    />
                    <ErrorMessage name="commodities" component="div" className="text-danger" />
                  </div>
                  <div className="col-md-4 fv-row mb-6">
                    <label
                      className="fs-5 fw-semibold required mb-2"
                      data-testid="t_label_commodity"
                    >
                      Currency
                    </label>
                    <Multiselect
                      avoidHighlightFirstOption
                      options={currencies}
                      selectedValues={selectedCurrencies}
                      displayValue="name"
                      ref={currenciesSelectRef}
                      id="currencies"
                    />
                    <ErrorMessage name="currencies" component="div" className="text-danger" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 fv-row mb-6">
                    <label
                      className="fs-5 fw-semibold required mb-2"
                      data-testid="t_label_minimum_amount"
                    >
                      Minimum Amount
                    </label>
                    <input
                      type="number"
                      min={0}
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control form-control-solid"
                      placeholder="Enter the minimum amount"
                      {...getFieldProps('minimum_amount')}
                    />
                    <ErrorMessage name="minimum_amount" component="div" className="text-danger" />
                  </div>
                  <div className="col-md-4 fv-row mb-6">
                    <label
                      className="fs-5 fw-semibold required mb-2"
                      data-testid="t_label_maximum_amount"
                    >
                      Maximum Amount
                    </label>
                    <input
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control form-control-solid"
                      placeholder="Enter the maximum amount"
                      {...getFieldProps('maximum_amount')}
                    />
                    <ErrorMessage name="maximum_amount" component="div" className="text-danger" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 fv-row mb-6">
                    <label
                      className="fs-5 fw-semibold required mb-2"
                      data-testid="t_label_minimum_interest"
                    >
                      Minimum Interest Rate
                    </label>
                    <input
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control form-control-solid"
                      placeholder="Enter a minimum interest rate"
                      {...getFieldProps('minimum_interest_rate')}
                    />
                    <ErrorMessage
                      name="minimum_interest_rate"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 fv-row mb-6">
                    <label
                      className="fs-5 fw-semibold required mb-2"
                      data-testid="t_label_country_shipping_to"
                    >
                      Country Shipping From
                    </label>
                    <Multiselect
                      avoidHighlightFirstOption
                      options={fromCountries}
                      selectedValues={selectedFromCountries}
                      displayValue="name"
                      ref={fromCountriesSelectRef}
                      id="from_country"
                    />
                    <ErrorMessage name="from_country" component="div" className="text-danger" />
                  </div>
                  <div className="col-md-4 fv-row mb-6">
                    <label
                      className="fs-5 fw-semibold required mb-2"
                      data-testid="t_label_country_shipping_from"
                    >
                      Country Shipping To
                    </label>
                    <Multiselect
                      avoidHighlightFirstOption
                      options={toCountries}
                      selectedValues={selectedToCountries}
                      displayValue="name"
                      ref={toCountriesSelectRef}
                      id="to_country"
                    />
                    <ErrorMessage name="to_country" component="div" className="text-danger" />
                  </div>
                </div>
                <div className="card-footer text-end ">
                  <button className="btn btn-primary mx-2" type="submit">
                    Set Rule
                  </button>
                  <button
                    className="btn btn-"
                    type="button"
                    onClick={handleReset}
                    style={{ backgroundColor: '#00194c', color: 'white' }}
                  >
                    Reset
                  </button>
                </div>
                <FocusError />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </FormikProvider>
  );
};

export default AddMatchingRule;
