import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import Router from 'routes';
import GlobalStateProvider from 'state';
import React, { useRef } from 'react';

function App() {
  // const { closeSnackbar } = useSnackbar();
  console.log('REACT_APP_NETWORK_ID', process.env.REACT_APP_NETWORK_ID);
  const notistackRef = React.createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => {
    notistackRef?.current?.closeSnackbar(key);
  };
  return (
    <SnackbarProvider
      style={{
        fontSize: '1.1rem'
      }}
      ref={notistackRef}
      action={(key) => (
        <i
          className="fa fa-times text-white px-2"
          aria-hidden="true"
          onClick={() => onClickDismiss(key)}
        ></i>
      )}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <GlobalStateProvider>
        <Router />
      </GlobalStateProvider>
    </SnackbarProvider>
  );
}

export default App;
