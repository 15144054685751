type StepProps = {
  title: string;
  stepNumber: number;
  titleDescription?: string;
  isActive: boolean;
};

const StepHeader = ({ stepNumber, title, titleDescription, isActive }: StepProps) => (
  <div className={`stepper-item mx-8 my-4 ${isActive ? 'current' : ''}`}>
    <div className="stepper-wrapper d-flex align-items-center">
      <div className="stepper-icon w-40px h-40px">
        <i className="stepper-check fas fa-check"></i>
        <span className="stepper-number">{stepNumber}</span>
      </div>
      <div className="stepper-label">
        <h3 className="stepper-title">{title}</h3>
        <div className="stepper-desc">{titleDescription}</div>
      </div>
    </div>
    <div className="stepper-line h-40px"></div>
  </div>
);

export default StepHeader;
