// Create all the sidebar nav links for each layout
import { SidebarLinks } from 'types/sidebarTypes';

export const getSidebarLinks = (entityType: string) => {
  switch (entityType) {
    case 'TRADER':
      return traderLinks;
    case 'LIQUIDITY_PROVIDER':
      return liquidityProviderLinks;
    default:
      return adminLinks;
  }
};

export const carrierLinks: SidebarLinks[] = [
  {
    title: 'Dashboard',
    icon: 'bi bi-speedometer2',
    route: '/carrier'
  },
  {
    title: 'Trade Details',
    icon: 'bi bi-card-list',
    route: '/carrier/trade-details'
  }
  // {
  //   title: 'Profile',
  //   icon: 'bi bi-code-square',
  //   route: '/carrier/profile'
  // }
];

export const traderLinks: SidebarLinks[] = [
  {
    title: 'Dashboard',
    icon: 'bi bi-speedometer2',
    route: '/trader',
    traderRole: 'EXPORTER'
  },
  {
    title: 'Settlement - Leg 1',
    icon: 'bi bi-cash-stack',
    route: '/trader/funding',
    traderRole: 'EXPORTER'
  },
  // {
  //   title: 'Profile',
  //   icon: 'bi bi-code-square',
  //   route: '/profile',
  //   traderRole: 'EXPORTER'
  // },

  {
    title: 'Settlement - Leg 2',
    icon: 'bi bi-card-list',
    route: '/trader/imports',
    traderRole: 'EXPORTER'
  },
  // {
  //   title: 'Profile',
  //   icon: 'bi bi-code-square',
  //   route: '/profile',
  //   traderRole: 'IMPORTER'
  // },
  {
    title: 'History',
    icon: 'fa fa-history',
    route: '/history',
    traderRole: 'EXPORTER'
  }
];

export const liquidityProviderLinks: SidebarLinks[] = [
  {
    title: 'Dashboard',
    icon: 'bi bi-speedometer2',
    route: '/liquidity-provider'
  },
  {
    title: 'Settlement',
    icon: 'bi bi-cash-stack',
    route: '/liquidity-provider/settlement'
  },
  {
    title: 'Redeem Funding',
    icon: 'bi bi-cash-stack',
    route: '/liquidity-provider/redeem-funding'
  },
  // {
  //   title: 'Profile',
  //   icon: 'bi bi-code-square',
  //   route: '/profile'
  // },
  {
    title: 'Matching Rules',
    icon: 'bi bi-file-ruled',
    route: '/liquidity-provider/matching-rules'
  },
  {
    title: 'History',
    icon: 'fa fa-history',
    route: '/history'
  }
];

export const adminLinks: SidebarLinks[] = [
  {
    title: 'Dashboard',
    icon: 'bi bi-speedometer2',
    route: '/admin'
  },
  {
    title: 'Stable Coin',
    icon: 'bi bi-cash-stack',
    route: '/admin/view-payment-token'
  },
  {
    title: 'Users',
    icon: 'bi bi-file-ruled',
    route: '/admin/view-users'
  }
];
