import ApprovedFundingCard from 'components/common/ApprovedFundingCard';
import FundingImportsTable from 'components/fundingTables/FundingImportsTable';
import React, { useEffect } from 'react';
import { useTradeState } from 'state';

const TradeImports = () => {
  const { approvedByBeneficiaryFundingRequests, fetchAllApprovedByBeneficiaryFundingRequests } =
    useTradeState();

  useEffect(() => {
    fetchAllApprovedByBeneficiaryFundingRequests();
  }, []);
  return (
    <div id="kt_app_content" className="app-content flex-column-fluid pt-5 mt-5">
      {/* <!--begin::Content container--> */}
      <div id="kt_app_content_container" className="app-container container-fluid">
        {/* <!--begin::Row--> */}
        <FundingImportsTable />
        <ApprovedFundingCard
          fundingRequests={approvedByBeneficiaryFundingRequests || []}
          owner={'LIQUIDITY PROVIDER'}
          payee={'BENEFICIARY'}
          title={'Funding Settlement'}
        />
      </div>
    </div>
  );
};

export default TradeImports;
