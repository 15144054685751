import constate from 'constate';
import { useState } from 'react';
import {
  BLStep,
  FundingRequestStepper,
  rawDocument,
  signer,
  status,
  step,
  wrappedDocument
} from '../types/global';
import { useHttpApi } from './useHttpApi';
import resourcePersistence from 'persistence/resourcePersistence';
import { EntityProps, EntityUserProps } from 'types/entity';
import { MatchingRule, Registry } from 'types/profileTypes';
import { TradeDetails, TradeRequest } from 'types/tradeTypes';
import { useSnackbar } from 'notistack';
import { getErrorMessage, getErrorMessageAlternative } from 'helpers/errors';
import resourceJsonPersistence from 'persistence/resourceJsonPersistence';
import {
  TitleEscrow__factory,
  TitleEscrowFactory__factory,
  TradeTrustToken__factory
} from '@govtechsg/token-registry/contracts';
import { useWeb3State } from './useWeb3';
import { BurnAddress } from 'config';
import { currentNetwork, readOnlyWeb3 as web3 } from 'helpers/web3';

export interface GetTitleEscrowProps {
  rawDocument: rawDocument;
  wrappedDocument: wrappedDocument;
  signer: signer;
}

const useAppState_ = () => {
  const {
    getAllApprovedEntitiesUserMap,
    getAllPendingEntitiesUserMap,
    getEntityById,
    getMatchingRuleByEntity,
    getAllTradersForId,
    getTradeName,
    getRegistryByEntityId
  } = useHttpApi();

  const { enqueueSnackbar } = useSnackbar();
  // const { account } = useWeb3State();
  const [isExpanded, setIsExpanded] = useState(true);
  const [status, setStatus] = useState<status>('initial');
  const [currentStep, setCurrentStep] = useState<step>('transfer_holdership');
  const [currentBLStep, setCurrentBLStep] = useState<BLStep>('entity_details');
  const [currentFundingRequestStep, setCurrentFundingRequestStep] =
    useState<FundingRequestStepper>('trade_details');
  const [account, setConnectedAccount] = useState<string | null>('');

  // entity states
  const [allPendingEntitiesUsers, setAllPendingEntitiesUsers] = useState<EntityUserProps[]>([]);
  const [allApprovedEntitiesUsers, setAllApprovedEntitiesUsers] = useState<EntityUserProps[]>([]);
  const [entityUser, setEntityUser] = useState<EntityUserProps>();
  const [matchingRuleForEntity, setMatchingRuleForEntity] = useState<MatchingRule>();
  const [allTradersForEntity, setAllTradersForEnity] = useState<EntityProps[]>([]);
  const [tradeRequest, setTradeRequest] = useState<TradeRequest | null>();
  const [fundingRequest, setFundingRequest] = useState<any | null>();
  const [tradeName, setTradeName] = useState<string>();
  const [transferOwnership, setTransferOwnership] = useState<any | null>();
  const [titleEscrowAddress, setTitleEscrowAddress] = useState<string>('');
  const [traderRole, setTraderRole] = useState<string>('EXPORTER');
  const [entityRegistryDetails, setEntityRegistryDetails] = useState<Registry>();

  const [otpVerificationOngoing, setOtpVerificationOngoing] = useState<boolean>(false);

  // const throwErrorMessage = async (error: any) => {
  //   const _message = getErrorMessage(error);
  //   const newMessage = getErrorMessageAlternative(_message);

  //   enqueueSnackbar(newMessage, {
  //     variant: 'error'
  //   });
  //   return newMessage;
  // };

  function cleanString(str: any) {
    // // Regular expression to remove non-printable characters
    // // This regex retains only printable ASCII characters (from space to tilde)
    // const printableASCII = /[ -~]/g;

    // // Extract only the printable ASCII characters from the input string
    // const cleanStr = str.match(printableASCII).join('');

    // // Trim leading and trailing white spaces (if any)
    // const trimmedStr = cleanStr.trim();

    // return trimmedStr;

    // Regular expression to retain only alphanumeric characters (letters and numbers)
    const alphanumericPattern = /[a-zA-Z0-9 ]/g;

    // Extract and concatenate only alphanumeric characters
    const alphanumericStr = str.match(alphanumericPattern).join('');

    // Trim any leading or trailing white spaces
    const trimmedStr = alphanumericStr.trim();
    console.log('🚀 ~ cleanString ~ trimmedStr:', trimmedStr);

    return trimmedStr;
  }

  const throwErrorMessage = async (error: any) => {
    let newMessage = 'Something went wrong! Please try again.';
    try {
      // Magic RPC Error: [-32603] Error forwarded from node: insufficient funds for gas * price + value

      if (error?.message.includes('insufficient funds for gas')) {
        newMessage = 'Insufficient funds for the transaction.';
      }

      // check if error.message consist of "Internal JSON-RPC error" or "execution reverted"
      else if (
        typeof error === 'object' &&
        Object.keys(error).length === 0 &&
        (error.message.includes('Internal JSON-RPC error') ||
          error.message.includes('execution reverted'))
      ) {
        const errorParam = error.toString();

        // Extract JSON section from the error string
        const jsonStart = errorParam?.indexOf('{');
        const jsonEnd = errorParam?.lastIndexOf('}') + 1;
        const jsonError = errorParam?.substring(jsonStart, jsonEnd);

        // Parse the extracted JSON part into a JavaScript object
        const errorObject = JSON.parse(jsonError);

        const decodedData = web3.utils.hexToAscii(errorObject.data);
        const cleanData = cleanString(decodedData);
        newMessage = cleanData;
      } else {
        const _message = getErrorMessage(error);

        newMessage = _message;
      }
    } catch (error) {
      console.log('💁 ~ throwErrorMessage ~ error:', error);
    }

    // const CustodianContract = new web3.eth.Contract(CustodianContractABI);
    // const decodedData = CustodianContract.(errorObject.data);
    // const decodedData = web3.utils.hexToAscii(
    //   '0x7b40159da00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000400000000000000000000000000000000000000000000000000000000000000012657363726f7720697320636f6d706c6574650000000000000000000000000000'
    // );
    // console.log('🚀😥😥😥 ~ throwErrorMessage ~ errorObject.data:', errorObject.data);
    // console.log('💁💁💁🚀💁 ~ throwErrorMessage ~ decodedData:', decodedData);
    // console.log('💁💁💁💁 ~ throwErrorMessage ~ decodedData:', typeof decodedData);

    // const decodeData = web3.eth.decodeParameters(CustodianContractABI, errorObject.data);
    // console.log('⚠️⚠️🚀 ~ throwErrorMessage ~ decodeData:', decodeData);

    // // console.log('⚠️⚠️', decodeData(CustodianContractABI, errorObject.data));
    enqueueSnackbar(newMessage, {
      variant: 'error'
    });
    return newMessage;
  };

  // entity fetch functions
  const fetchAllPendingEntitiesUsers = async () => {
    const _res = await getAllPendingEntitiesUserMap();
    setAllPendingEntitiesUsers(_res);
  };
  const fetchAllApprovedEntitiesUsers = async () => {
    const _res = await getAllApprovedEntitiesUserMap();
    setAllApprovedEntitiesUsers(_res);
  };
  const fetchEntitiyUserProfile = async (id: string) => {
    const _res = await getEntityById(id);
    setEntityUser(_res);
  };
  const setCurrentEntityUserId = (id: string) => {
    resourcePersistence.set('entityUserID', id);
  };
  const loadCurrentEntityUserId = () => {
    const entityId = resourcePersistence.get('entityUserID');
    if (entityId !== null) {
      fetchEntitiyUserProfile(entityId);
    }
  };

  const fetchMatchingRuleByEntity = async (id: string) => {
    const _res = await getMatchingRuleByEntity(id);
    setMatchingRuleForEntity(_res);
  };

  const fetchAllTradersForEntity = async (id: string) => {
    const _res = await getAllTradersForId(id);
    setAllTradersForEnity(_res);
  };

  const fetchTradeName = async () => {
    const _res = await getTradeName();
    setTradeName(_res);
  };

  const fetchRegistryByEntityId = async (id: string) => {
    const _res = await getRegistryByEntityId(id);
    setEntityRegistryDetails(_res);
  };

  const setCurrentTransferOwnership = (data: any) => {
    resourceJsonPersistence.set('transferOwnership', data);
  };

  const loadCurrentTransferOwnership = () => {
    const res = resourceJsonPersistence.get('transferOwnership');
    setTransferOwnership(res);
  };

  const getTitleEscrow = async ({
    rawDocument,
    wrappedDocument,
    signer
  }: GetTitleEscrowProps): Promise<[string, boolean, boolean, boolean]> => {
    const tokenId_ = `0x${wrappedDocument?.signature.merkleRoot}`;

    const tokenRegistry_ = rawDocument?.issuers[0].tokenRegistry;

    try {
      const connectedRegistry = TradeTrustToken__factory.connect(tokenRegistry_, signer!);
      const titleEscrowFactoryAddress = await connectedRegistry.titleEscrowFactory();
      const documentOwner = await connectedRegistry.ownerOf(tokenId_);
      const isSurrendered = documentOwner === tokenRegistry_;
      const isTokenBurt = documentOwner === BurnAddress;
      const connectedEscrowFactory = TitleEscrowFactory__factory.connect(
        titleEscrowFactoryAddress,
        signer!
      );

      const titleEscrowAddress_ = await connectedEscrowFactory.getAddress(tokenRegistry_, tokenId_);
      console.log('check title Escrow', titleEscrowAddress_);
      setTitleEscrowAddress(titleEscrowAddress_);
      const connectedEscrow = TitleEscrow__factory.connect(titleEscrowAddress_, signer!);
      const ownerAddress = await connectedEscrow.beneficiary();
      const holderAddress = await connectedEscrow.holder();
      const isOwner = ownerAddress === account;
      console.log('🚀 ~ account:', ownerAddress, account);
      const isHolder = holderAddress === account;
      return [titleEscrowAddress_, isSurrendered || isTokenBurt, isOwner, isHolder];
    } catch (e) {
      console.error(e);
      return ['', false, false, false];
    }
  };

  const fetchHolderNomineeAdress = async ({ titleEscrowAddress, payeeAddress, signer }: any) => {
    if (titleEscrowAddress) {
      const connectedEscrow = TitleEscrow__factory.connect(titleEscrowAddress, signer);
      const holderAddress = await connectedEscrow.holder();
      const isHoldershipTransferred = holderAddress === currentNetwork.EscrowMangerAddress;
      return [isHoldershipTransferred];
    }
    return [false];
  };
  const fetchHolderAddress = async ({ titleEscrowAddress, payeeAddress, signer }: any) => {
    if (titleEscrowAddress) {
      const connectedEscrow = TitleEscrow__factory.connect(titleEscrowAddress, signer);
      const holderAddress = await connectedEscrow.holder();
      return holderAddress === payeeAddress;
    };
    return false;
  }
  const fetchNomineeAddress = async ({ titleEscrowAddress, payeeAddress, signer }: any) => {
    if (titleEscrowAddress) {
      const connectedEscrow = TitleEscrow__factory.connect(titleEscrowAddress, signer);
      const holderAddress = await connectedEscrow['nominee()']();
      return holderAddress === payeeAddress;
    };
    return false;
  }

  return {
    isExpanded,
    setIsExpanded,
    status,
    setStatus,
    currentStep,
    setCurrentStep,
    currentBLStep,
    setCurrentBLStep,
    currentFundingRequestStep,
    setCurrentFundingRequestStep,
    // entity states
    allPendingEntitiesUsers,
    fetchAllPendingEntitiesUsers,
    allApprovedEntitiesUsers,
    fetchAllApprovedEntitiesUsers,
    fetchEntitiyUserProfile,
    loadCurrentEntityUserId,
    entityUser,
    setCurrentEntityUserId,
    fetchMatchingRuleByEntity,
    matchingRuleForEntity,
    fetchAllTradersForEntity,
    allTradersForEntity,
    // trade
    tradeRequest,
    setTradeRequest,
    setFundingRequest,
    fundingRequest,
    fetchTradeName,
    tradeName,
    throwErrorMessage,
    setCurrentTransferOwnership,
    loadCurrentTransferOwnership,
    transferOwnership,
    getTitleEscrow,
    traderRole,
    setTraderRole,
    entityRegistryDetails,
    fetchRegistryByEntityId,
    fetchHolderNomineeAdress,
    fetchHolderAddress,
    fetchNomineeAddress,
    otpVerificationOngoing,
    setOtpVerificationOngoing,
    account,
    setConnectedAccount
  };
};

export const [AppStateProvider, useAppState] = constate(useAppState_);
