import React, {
  useState,
  useRef,
  KeyboardEvent,
  ClipboardEvent,
  ChangeEvent,
  useEffect
} from 'react';
import logo from '../../assets/media/logos/logo.svg';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'state/useAuthState';
import { useAppState, useAuthStateShared, useWeb3State } from 'state';
import FocusError from 'components/FocusError';
import { EmailRegex } from 'helpers/regex';
import { userTokenPersistence } from 'persistence';
import email from '../../assets/images/email.svg';

const LoginForm = () => {
  const { login } = useAuthState();
  const { user } = useAuthStateShared();
  const { throwErrorMessage, otpVerificationOngoing, setOtpVerificationOngoing } = useAppState();
  const [showPassword, setShowPassword] = useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const otpLaunchModalRef = React.useRef<HTMLButtonElement>(null);
  const modelRef = React.useRef<HTMLDivElement>(null);
  const [tryCount, setTryCount] = useState<number>(0);
  const { verifyOtp, connectWallet, account } = useWeb3State();

  const navigate = useNavigate();
  const EntitySchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid Email Address')
      .required('Email is required')
      .matches(EmailRegex, 'Invalid Email Address'),
    password: Yup.string().required('Password is required')
  });

  const token = userTokenPersistence.get();

  useEffect(() => {
    if (!otpVerificationOngoing && token && account) {
      navigate('/');
    }
  }, [token, account, otpVerificationOngoing]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: EntitySchema,
    onSubmit: async (data) => {
      try {
        setSubmitting(true);
        setTryCount(0);
        setOtp(['', '', '', '', '', '']);
        data.email = data.email.toLowerCase();
        const res = await login(data);
        if (res?.user?.role?.title !== 'ADMIN') {
          connectWallet(values.email, otpLaunchModalRef, setSubmitting, inputRefs);
          // navigate('/');
        } else {
          setSubmitting(false);
          navigate('/admin');
        }
      } catch (e) {
        console.log(e);
        setSubmitting(false);
        throwErrorMessage(e);
      }
    }
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const incrementTryCount = () => {
    setTryCount((prev) => prev + 1);
  };

  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null)
  ];

  const handleInputChange = async (
    index: number,
    value: string,
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input field if value is not empty
    if (value !== '' && index < 5 && inputRefs[index + 1]?.current) {
      if (inputRefs[index + 1].current) {
        inputRefs[index + 1]?.current?.focus();
      }
    }

    // Move to the previous input field if backspace is pressed and the current input is empty
    if (event.key === 'Backspace' && value === '' && index > 0 && inputRefs[index - 1]?.current) {
      if (inputRefs[index - 1].current) {
        inputRefs[index - 1]?.current?.focus();
      }
    }

    // Call submit otp api if all the otp fields are filled

    if (newOtp.join('').length >= 6) {
      setOtpVerificationOngoing(true);
      if (tryCount <= 2) {
        incrementTryCount();
        await verifyOtp(newOtp.join(''), modelRef, tryCount);
        formik.resetForm();
      }
    }
  };

  const handlePaste = async (event: ClipboardEvent<HTMLInputElement>) => {
    const pastedData = event.clipboardData.getData('text/plain');
    const pastedChars = pastedData.split('');

    // Update OTP values
    const newOtp = [...otp];
    for (let i = 0; i < pastedChars.length && i < 6; i++) {
      newOtp[i] = pastedChars[i];
    }
    setOtp(newOtp);

    // Move focus to the next input
    if (pastedChars.length < 6 && inputRefs[pastedChars.length]?.current) {
      if (inputRefs[pastedChars.length].current) {
        inputRefs[pastedChars.length]?.current?.focus();
      }
    }

    // Call submit otp api if all the otp fields are filled

    if (pastedChars.length >= 6) {
      setOtpVerificationOngoing(true);
      if (tryCount <= 2) {
        incrementTryCount();
        await verifyOtp(newOtp.join(''), modelRef, tryCount);
        formik.resetForm();
      }
    }
  };
  return (
    <div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
      <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
        <FormikProvider value={formik}>
          <form
            className="form w-100"
            // noValidate="novalidate"
            id="kt_sign_in_form"
            data-kt-redirect-url="form-details.html"
            action="#"
            onSubmit={handleSubmit}
          >
            <div className="text-center mb-11">
              <h1 className="text-dark fw-bolder mb-3">Sign In</h1>

              <div className="text-gray-500 fw-semibold fs-6">Enter Your Credentials</div>
            </div>

            <div className="fv-row mb-8">
              <input
                type="text"
                placeholder="Email"
                autoComplete="off"
                className="form-control bg-transparent"
                id="email"
                {...getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                  {formik.errors.email}*
                </div>
              ) : null}
            </div>
            <div className="fv-row mb-8" data-kt-password-meter="true">
              <div className="mb-1">
                <div className="position-relative mb-3">
                  <input
                    className="form-control bg-transparent"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    autoComplete="off"
                    id="password"
                    {...getFieldProps('password')}
                  />
                  <span
                    className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                    data-kt-password-meter-control="visibility"
                    onClick={handleShowPassword}
                  >
                    {!showPassword ? (
                      <i className="ki-duotone ki-eye-slash fs-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                      </i>
                    ) : (
                      <i className="ki-duotone ki-eye  fs-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                    )}
                  </span>

                  {formik.touched.password && formik.errors.password ? (
                    <div className="form-error" style={{ fontSize: 12, color: 'red' }}>
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
              <div></div>

              <a href="reset-password" className="link-primary">
                Forgot Password ?
              </a>
            </div>

            <div className="d-grid mb-10">
              <button
                disabled={submitting}
                type="submit"
                id="kt_sign_in_submit"
                className="btn btn-primary"
              >
                {submitting && (
                  <span
                    className="spinner-border spinner-border-sm mx-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                {submitting ? 'Signing in...' : 'Sign In'}

                {/* <span className="indicator-progress"
                                            >Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                        */}
              </button>
            </div>

            <div className="text-gray-500 text-center fw-semibold fs-6">
              Not a Member yet?
              <Link className={`link-primary`} to="/sign-up">
                Sign up
              </Link>
            </div>
            <FocusError />
          </form>
        </FormikProvider>
      </div>

      <div className="text-center text-muted px-lg-10">
        &copy; 2023 XDC Trade Network - All Rights Reserved.
      </div>

      {/*********** OTP MODAL ************/}
      <button
        style={{
          display: 'none'
        }}
        ref={otpLaunchModalRef}
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#otpModal"
      >
        Launch OTP MODAL
      </button>
      <div
        className="modal fade"
        id="otpModal"
        data-kt-modal-name="otpModal"
        data-bs-backdrop="static"
        data-kt-bs-modal="true"
        tabIndex={-1}
        data-bs-keyboard="false"
        aria-labelledby="otpModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  mw-410px">
          <div
            style={{
              display: 'none'
            }}
            className="modal-header"
          >
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              ref={modelRef}
              aria-label="Close"
            >
              <i className="ki-duotone ki-cross fs-1">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
          </div>
          <div
            className="modal-content px-10"
            style={{
              borderRadius: '28px',
              boxShadow: ' 0 12px 56px rgba(119,118,122,.15)'
            }}
          >
            <div className="position-relative my-10 mx-6">
              <div className="card p-2 text-center">
                <img
                  src={email}
                  style={{
                    width: '100px',
                    height: '100px',
                    margin: 'auto'
                  }}
                />
                <p className="mt-4 fs-3">
                  Please enter the code sent to <br />
                  <span className="fs-3 fw-bold">{formik.values.email}</span>
                </p>

                {otpVerificationOngoing ? (
                  <div className="d-flex justify-content-center my-14">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
                      {otp.map((value, index) => (
                        <input
                          key={index}
                          className="mx-3 py-4 text-center form-control rounded"
                          type="text"
                          pattern="[0-9\s]{13,19}"
                          maxLength={1}
                          value={value}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(index, e.target.value, e?.nativeEvent as any)
                          }
                          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                            // console.log(e?.keyCode);
                            if (e.key === 'Backspace' && !value) {
                              handleInputChange(index, '', e);
                            }
                          }}
                          onPaste={(e: ClipboardEvent<HTMLInputElement>) => handlePaste(e)}
                          ref={inputRefs[index]}
                        />
                      ))}
                    </div>
                    <div className="mt-4">
                      <p className="text-small">Tries remaining: {3 - tryCount}</p>
                    </div>
                    <div className="mt-10">
                      <p className="text-small ">
                        Secured By{' '}
                        <a className="text-dark" href="#">
                          Magic
                        </a>
                      </p>
                    </div>
                    {/* <div className="mt-4">
                      <button
                        className="btn btn-primary px-4 validate"
                        onClick={async () => {
                          setOtpVerificationOngoing(true);
                          console.log('OTP IS', otp.join(''));
                          await verifyOtp(otp.join(''), modelRef);
                        }}
                      >
                        {otpVerificationOngoing ? <span>Verifying...</span> : 'Verify OTP'}
                      </button>
                    </div> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*********** OTP MODAL END ************/}
    </div>
  );
};

export default LoginForm;
