import User from '../../assets/media/avatars/300-1.jpg';
import { useAppState, useAuthState, useAuthStateShared, useMagicState, useWeb3State } from 'state';
import { formatAddressShort } from 'helpers/web3';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';

const Navbar = () => {
  const { account, active, connectWallet, disconnectWallet } = useWeb3State();
  const { disconnectMagic: setLoggedOutState } = useMagicState();

  const navigate = useNavigate();
  const { user } = useAuthStateShared();
  const { logout } = useAuthState();
  const { throwErrorMessage, setTradeRequest, setFundingRequest, traderRole, setTraderRole } =
    useAppState();

  // useEffect(() => {
  //   setTraderRole('EXPORTER');
  // }, [user]);
  // const handleRoleChangeForTrader = (e: any) => {
  //   console.log(e.target.value);
  //   // changeTraderRole(e.target.value)
  //   setTraderRole(e.target.value);

  //   const traderRole = e.target.value;

  //   if (traderRole === 'IMPORTER') {
  //     navigate('/trader/imports');
  //   } else if (traderRole === 'EXPORTER') {
  //     navigate('/trader');
  //   }
  // };
  return (
    <div id="kt_app_header" className="app-header">
      {/* begin::Header container */}
      <div
        className="app-container container-fluid d-flex align-items-stretch justify-content-between"
        id="kt_app_header_container"
      >
        {/* begin::Sidebar mobile toggle */}
        <div
          className="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2"
          title="Show sidebar menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="kt_app_sidebar_mobile_toggle"
          >
            <i className="ki-duotone ki-abstract-14 fs-2 fs-md-1">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </div>
        </div>
        {/* end::Sidebar mobile toggle */}

        {/* begin::Mobile logo */}
        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
          <Link to="/" className="d-lg-none">
            {/* <img alt="Logo" src={User} className="h-30px" /> */}
            <i
              className="fa fa-user bg-light rounded"
              style={{
                fontSize: '2rem',
                padding: '0.7rem'
              }}
              aria-hidden="true"
            ></i>
          </Link>
        </div>
        {/* end::Mobile logo */}

        {/* begin::Header wrapper */}
        <div
          className="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
          id="kt_app_header_wrapper"
        >
          {/* begin::Menu wrapper */}
          <div
            className="app-header-menu app-header-mobile-drawer align-items-stretch"
            data-kt-drawer="true"
            data-kt-drawer-name="app-header-menu"
            data-kt-drawer-activate="{default: true, lg: hover}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="250px"
            data-kt-drawer-direction="end"
            data-kt-drawer-toggle="#kt_app_header_menu_toggle"
            data-kt-swapper="true"
            data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
            data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
          ></div>
          {/* end::Menu wrapper */}

          {/* begin::Navbar */}
          <div className="app-navbar">
            {/* <div className="app-navbar-item ms-1 ms-lg-3">
              {user?.entity?.entity_type?.description === 'Trader' && (
                <>
                  <div className="form-group pb-1">
                    <label htmlFor="input1" className="">
                      <span
                        className="h6 small bg-white text-muted"
                        style={{
                          marginLeft: '15px',
                          padding: '0px 5px 0px 5px'
                        }}
                      >
                        My Role
                      </span>
                    </label>
                    <select
                      className="form-control mt-n3 form-select-sm"
                      aria-label=".form-select-sm example"
                      id="role"
                      style={{
                        color: '#000000',
                        marginLeft: '5px',
                        border: '#c3c3c3 1px solid',
                        width: '120px'
                      }}
                      onChange={handleRoleChangeForTrader}
                    >
                      <option
                        selected
                        value="EXPORTER"
                        style={{ backgroundColor: 'white', color: 'black' }}
                      >
                        EXPORTER
                      </option>
                      <option value="IMPORTER" style={{ backgroundColor: 'white', color: 'black' }}>
                        IMPORTER
                      </option>
                    </select>
                  </div>
                </>
              )}
            </div> */}

            {/* begin::Wallet */}
            {user?.user?.role?.title !== 'ADMIN' && (
              <div
                className="app-navbar-item ms-1 ms-lg-3"
                // onClick={() => {
                //   if (active) {
                //     disconnectWallet();
                //   } else {
                //     // connectWallet();
                //   }
                // }}
              >
                <div className="btn btn-light btn-active-color-primary wallet">
                  <div>
                    {/* <!--begin::Svg Icon | path: icons/duotune/general/gen032.svg--> */}
                    <span className="svg-icon svg-icon-2x">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 20C6 20.6 5.6 21 5 21C4.4 21 4 20.6 4 20H6ZM18 20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20 20H18Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M21 20H3C2.4 20 2 19.6 2 19V3C2 2.4 2.4 2 3 2H21C21.6 2 22 2.4 22 3V19C22 19.6 21.6 20 21 20ZM12 10H10.7C10.5 9.7 10.3 9.50005 10 9.30005V8C10 7.4 9.6 7 9 7C8.4 7 8 7.4 8 8V9.30005C7.7 9.50005 7.5 9.7 7.3 10H6C5.4 10 5 10.4 5 11C5 11.6 5.4 12 6 12H7.3C7.5 12.3 7.7 12.5 8 12.7V14C8 14.6 8.4 15 9 15C9.6 15 10 14.6 10 14V12.7C10.3 12.5 10.5 12.3 10.7 12H12C12.6 12 13 11.6 13 11C13 10.4 12.6 10 12 10Z"
                          fill="currentColor"
                        />
                        <path
                          d="M18.5 11C18.5 10.2 17.8 9.5 17 9.5C16.2 9.5 15.5 10.2 15.5 11C15.5 11.4 15.7 11.8 16 12.1V13C16 13.6 16.4 14 17 14C17.6 14 18 13.6 18 13V12.1C18.3 11.8 18.5 11.4 18.5 11Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    {/* <!--end::Svg Icon--> */}
                  </div>

                  <div className="float-left">
                    <div className="d-flex">
                      {/* <span>{!account ? ' Not Connected' : formatAddressShort(account)}</span> */}
                      <span>{!account ? ' Not Connected' : user?.user?.email}</span>
                      {account && (
                        <span
                          onClick={() => {
                            if (account) {
                              navigator.clipboard.writeText(account);
                            }
                          }}
                        >
                          {!account ? '' : <i className="fa fa-copy mx-2"></i>}
                        </span>
                      )}
                    </div>

                    <span className="d-block">
                      <span
                        className={`bullet bullet-dot ${
                          !account ? 'bg-danger' : 'bg-success'
                        } h-6px w-6px translate-middle start-50 animation-blink`}
                      ></span>{' '}
                      {!account ? '' : 'Connected'}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {/* end::Wallet */}

            {/* begin::User menu */}
            <div className="app-navbar-item ms-1 ms-md-3 dropdown" id="kt_header_user_menu_toggle">
              {/* begin::Menu wrapper */}
              <div
                className="cursor-pointer symbol symbol-30px symbol-md-40px"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                data-bs-toggle="dropdown"
              >
                {/* <img src={User} alt="user name" title="user name" /> */}
                <i
                  className="fa fa-user bg-light rounded"
                  style={{
                    fontSize: '2rem',
                    padding: '0.7rem'
                  }}
                  aria-hidden="true"
                ></i>
              </div>
              {/* begin::User account menu */}
              <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px dropdown-menu"
                data-kt-menu="true"
              >
                {/* begin::Menu item */}
                <div className="menu-item px-3">
                  <div className="menu-content d-flex align-items-center px-3">
                    {/* begin::Avatar */}
                    <div className="symbol symbol-50px me-5">
                      {/* <img alt="user name" title="user name" src={User} /> */}
                      <i
                        className="fa fa-user bg-light rounded"
                        style={{
                          fontSize: '2rem',
                          padding: '0.7rem'
                        }}
                        aria-hidden="true"
                      ></i>
                    </div>
                    {/* end::Avatar */}
                    {/* begin::Username */}
                    <div className="d-flex flex-column">
                      <div className="fw-bold d-flex align-items-center fs-5">
                        {user?.user?.name}
                        {/* <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                          Pro
                        </span> */}
                      </div>
                      <a href="#" className="fw-semibold text-muted text-hover-primary fs-7">
                        {user?.user?.email}
                      </a>
                    </div>
                    {/* end::Username */}
                  </div>
                </div>
                {/* end::Menu item */}
                {/* begin::Menu separator */}
                <div className="separator my-2"></div>
                {/* end::Menu separator */}
                {/* begin::Menu item */}
                {user?.entity?.is_approved && (
                  <div className="menu-item px-5 my-1">
                    <Link to="/profile" className="menu-link px-5">
                      Profile
                    </Link>
                  </div>
                )}
                {user?.entity?.is_approved && (
                  <div className="menu-item px-5 my-1">
                    <Link
                      to={
                        user?.user?.role?.title === 'PRIMARY_TRADER'
                          ? '/trader/change-password'
                          : '/liquidity-provider/change-password'
                      }
                      className="menu-link px-5"
                    >
                      Change Password
                    </Link>
                  </div>
                )}
                {/* end::Menu item */}
                {/* begin::Menu item */}
                <div className="menu-item px-5">
                  <div
                    onClick={async () => {
                      try {
                        const res = await logout();
                        if (res) {
                          setFundingRequest(null);
                          setTradeRequest(null);
                          setLoggedOutState();
                          enqueueSnackbar('Logged out successfully', {
                            variant: 'success'
                          });
                          navigate('/login', { replace: true });
                        }
                      } catch (e: any) {
                        if (e.response.status === 401) {
                          navigate('/login', { replace: true });
                        }
                        // throwErrorMessage(e);
                      }
                      console.log('logged out');
                    }}
                    className="menu-link px-5"
                  >
                    Sign Out
                  </div>
                </div>
                {/* end::Menu item */}
              </div>
              {/* end::User account menu */}
              {/* end::Menu wrapper */}
            </div>
            {/* end::User menu */}
          </div>
          {/* end::Navbar */}
        </div>
        {/* end::Header wrapper */}
      </div>
      {/* end::Header container */}
    </div>
  );
};

export default Navbar;
