import React, { useState } from 'react';
import { Form, Field, useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useAppState, useAuthState } from 'state';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

function ChangeOldPaswwordForm() {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [error, setError] = useState('');
  const { throwErrorMessage } = useAppState();
  const { changePassword } = useAuthState();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleShowOldPassword = () => {
    setShowOldPassword((show) => !show);
  };

  const handleShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };

  const ChangePasswordSchema = Yup.object().shape({
    old_password: Yup.string().required('Old Password is required'),
    new_password: Yup.string()
      .required('New Password is required')
      .min(8, 'Password must be minimum 8 characters long')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
        'Must Contain atleast one Uppercase, one Lowercase, one Number and one special case Character'
      ),
    confirm_new_password: Yup.string()
      .oneOf([Yup.ref('new_password'), ''], 'Passwords must match')
      .required('Confirm New Password is required')
  });

  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      confirm_new_password: ''
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async (data, { resetForm }) => {
      // Handle form submission here
      try {
        setError('');
        console.log(data);
        await changePassword(data);
        enqueueSnackbar('Password changed successfully', { variant: 'success' });
        resetForm();
        navigate('/login', { replace: true });
      } catch (error) {
        console.log(error);
        const errorMessage = await throwErrorMessage(error);
        setError(errorMessage);
      }
    }
  });

  const { getFieldProps, handleSubmit, errors, touched } = formik;

  return (
    <div id="kt_app_content" className="mt-5 pt-5 app-content flex-column-fluid">
      {/*begin::Content container*/}
      <div id="kt_app_content_container" className="app-container container-fluid">
        <div className="container mt-20 my-5">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h2 className="text-uppercase fw-bold text-dark">Change Password</h2>
                  {error && <div className="alert alert-danger">{error}</div>}
                  <FormikProvider value={formik}>
                    <Form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="old_password" className="form-label">
                          Old Password
                        </label>
                        <div className="position-relative mb-3">
                          <input
                            type={showOldPassword ? 'text' : 'password'}
                            className="form-control"
                            id="old_password"
                            {...getFieldProps('old_password')}
                            placeholder="Enter Old Password"
                          />
                          <span
                            className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                            data-kt-password-meter-control="visibility"
                            onClick={handleShowOldPassword}
                          >
                            {!showOldPassword ? (
                              <i className="ki-duotone ki-eye-slash fs-1">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                              </i>
                            ) : (
                              <i className="ki-duotone ki-eye  fs-1">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                              </i>
                            )}
                          </span>
                        </div>
                        {formik.touched.old_password && formik.errors.old_password && (
                          <div className="text-danger">{formik.errors.old_password}</div>
                        )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="new_password" className="form-label">
                          New Password
                        </label>
                        <div className="position-relative mb-3">
                          <input
                            type={showNewPassword ? 'text' : 'password'}
                            className="form-control"
                            id="new_password"
                            {...getFieldProps('new_password')}
                            placeholder="Enter New Password"
                          />
                          <span
                            className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                            data-kt-password-meter-control="visibility"
                            onClick={handleShowNewPassword}
                          >
                            {!showNewPassword ? (
                              <i className="ki-duotone ki-eye-slash fs-1">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                              </i>
                            ) : (
                              <i className="ki-duotone ki-eye  fs-1">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                              </i>
                            )}
                          </span>
                        </div>
                        {formik.touched.new_password && formik.errors.new_password && (
                          <div className="text-danger">{formik.errors.new_password}</div>
                        )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="confirm_new_password" className="form-label">
                          Confirm New Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="confirm_new_password"
                          {...getFieldProps('confirm_new_password')}
                          placeholder="Confirm New Password"
                        />
                        {formik.touched.confirm_new_password &&
                          formik.errors.confirm_new_password && (
                            <div className="text-danger">{formik.errors.confirm_new_password}</div>
                          )}
                      </div>
                      <div className="text-end">
                        <button type="submit" className="btn btn-primary mt-3">
                          Change Password
                        </button>
                      </div>
                    </Form>
                  </FormikProvider>
                </div>
                <div className="col-md-6 text-center align-items-center d-flex justify-content-center flex-column">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="200"
                    height="200"
                    fill="#B2BEB5"
                    className="bi bi-shield-lock"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                    <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z" />
                  </svg>
                  <div className="fw-bold mt-4">
                    <p>In order to protect your account, make sure your password is:</p>
                    <ul className="text-start ms-5">
                      <li>At least 8 characters long</li>
                      <li>
                        Contains characters from 3 different groups (lowercase, uppercase, numbers,
                        special characters)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeOldPaswwordForm;
