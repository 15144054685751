import constate from 'constate';
import useResource from 'helpers/useResource';

import { useEffect, useState } from 'react';
import { useHttpApi } from 'state/useHttpApi';
import { EntityTypesResponse, IdentifierTypesResponse } from 'types/entity';
import { PaymentToken } from 'types/tradeTypes';

const useCoreTableState_ = () => {
  const {
    getAllCountries,
    getAllCurrencies,
    getAllCommodities,
    getEntityTypes,
    getIdentifierTypes,
    getAllPaymentTokens,
    paymentTokenByCurr
  } = useHttpApi();

  const [entityTypes, setEntityTypes] = useState<EntityTypesResponse[]>([
    {
      id: '',
      name: '',
      description: '',
      created_at: '',
      updated_at: ''
    }
  ]);
  const [identifierTypes, setIdentifierTypes] = useState<IdentifierTypesResponse[]>([
    {
      id: '',
      name: '',
      description: '',
      regex: '',
      created_at: '',
      updated_at: ''
    }
  ]);

  const [fetchCountries, countries] = useResource('countries', () => getAllCountries(), [], {
    noFetchIfCached: true
  });

  const [fetchCurrencies, currencies] = useResource('currencies', () => getAllCurrencies(), [], {
    noFetchIfCached: true
  });

  const [fetchCommodities, commodities] = useResource(
    'commodities',
    () => getAllCommodities(),
    [],
    {
      noFetchIfCached: true
    }
  );

  const [paymentTokenByCurrency, setPaymentTokenByCurrency] = useState<PaymentToken[]>([]);
  const fetchPaymentTokenByCurrency = async (id: string) => {
    const res = await paymentTokenByCurr(id);
    const filterCash = res.filter((item: any) => item.symbol !== 'CASH');
    setPaymentTokenByCurrency(filterCash);
  };

  const fetchCashPaymentTokenByCurrency = async () => {
    const res = await paymentTokenByCurr('USD');
    const cashToken = res.filter((item: any) => item.symbol === 'CASH');
    setPaymentTokenByCurrency(cashToken);
  };
  const fetchEntityTypes = async () => {
    const res = await getEntityTypes();
    setEntityTypes(res);
  };

  const fetchIdentifierTypes = async () => {
    const res = await getIdentifierTypes();
    setIdentifierTypes(res);
  };

  const [fetchAllPaymentTokens, allPaymentTokens] = useResource(
    'paymentTokens',
    () => getAllPaymentTokens(),
    [],
    {}
  );

  useEffect(() => {
    _loaddata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _loaddata = async () => {
    fetchCountries();
    fetchCurrencies();
    fetchCommodities();
    fetchAllPaymentTokens();
  };

  return {
    countries,
    currencies,
    commodities,
    entityTypes,
    identifierTypes,
    fetchEntityTypes,
    fetchIdentifierTypes,
    allPaymentTokens,
    fetchAllPaymentTokens,
    paymentTokenByCurrency,
    fetchPaymentTokenByCurrency,
    fetchCashPaymentTokenByCurrency
  };
};

export const [CoreTablesProvider, useCoreTableState] = constate(useCoreTableState_);
